import React from 'react';
import logoBG from '../../assets/logo-bg-light.png';
import NewDocIcon from '../../assets/new_document.svg';
import { useDropzone } from 'react-dropzone'
import FileDisplay from './FileDisplay';
import { FormattedMessage } from 'react-intl';

const Create = (props) => {
    const { filename, handleFilename, file, handleProgress, pageCount, validateUploadFile, updateStoredData } = props;


    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: 'application/pdf',
        validator: validateUploadFile,
    });
    return (
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 w-full my-8 text-gray-300 text-15">
            <div className="relative mx-5 pb-2 border-b border-gray-200">
                <div className="select-none px-3 font-bold text-30 ">
                    <FormattedMessage id='NEW_CONTRACT' />
                </div>
                <img src={logoBG} className="select-none absolute bottom-0 -left-5 -z-10" />
            </div>
            <div className="mx-5 px-3 mt-5 mb-10">
                {file && <FileDisplay filename={filename} handleFilename={handleFilename} pageCount={pageCount} updateStoredData={updateStoredData}/>}
            </div>
            <div>
                <div {...getRootProps()} className={`bg-gray-30 mx-10 md:mx-32 rounded-md focus:outline-none flex-grow border-2 border-gray-200 border-dashed my-5 py-10 flex flex-col justify-center ${isDragActive && 'bg-gray-400'} `}>
                    <input {...getInputProps()} />
                    <img className="select-none max-h-24" src={NewDocIcon} />
                    <div className="select-none mx-auto my-3 font-medium">
                        <FormattedMessage id='DROP_A_PDF_DOCUMENT_HERE'/>
                    </div>
                    <span className="mx-auto my-3 text-black-500">
                        <button id="start-btn" className="select-none focus:outline-none text-white px-3 sm:px-5 md:px-10 py-2 rounded-md bg-orange-500 hover:bg-orange-600 font-medium">
                            <FormattedMessage id='UPLOAD'/>
                        </button>

                    </span>

                </div>
            </div>

            <div className="flex justify-end">
                <button className="select-none focus:outline-none mx-10 w-full text-white sm:w-28 sm:mx-0 py-2 font-medium bg-orange-500 hover:bg-orange-600 rounded-sm" onClick={() => { handleProgress(1) }}>
                    <FormattedMessage id='NEXT' />
                </button>
            </div>
        </div>
    );
}
export default Create;