import React from 'react';
import logoBG from '../../assets/logo-bg-light.png';
import UserIcon from '../../assets/user.svg';
import SignerForm from '../Create/SignerForm';
import PdfIcon from '../../assets/pdf.png';
const ReviewSend = (props) => {
    const {files, handleFiles} = props;
    return(
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 w-full my-8 text-gray-300 text-15">
            <div className="relative mx-5 pb-2 border-b border-gray-200">
                <div className=" px-3 font-bold text-30 ">
                    Review and Send
                </div>
                <img src={logoBG} className="absolute bottom-0 -left-5 -z-10"/>
            </div>
            <div className="mx-5 px-3 mt-5 mb-10">
                <div className="font-bold mb-2">
                    Original Document
                </div>
                <div className="shadow-md p-3 flex">
                    <img src={PdfIcon} className="mr-3"></img>
                    <div className="flex flex-col justify-center">
                        <div className="my-1">
                            Ethsign Sample Agreement.pdf
                        </div>
                        <div className="font-medium text-gray-70 my-1">
                            3 pages
                        </div>
                    </div>
                </div>
            </div>
            <div className="mx-5 px-3 mt-5 mb-10">
                <div className="font-bold mb-2">
                    Revisions
                </div>
                <div className="shadow-md p-3 flex">
                    <img src={PdfIcon} className="mr-3"></img>
                    <div className="flex flex-col justify-center">
                        <div className="my-1">
                            Revised Ethsign Sample Agreement.pdf
                        </div>
                        <div className="font-medium text-gray-70 my-1">
                            3 pages
                        </div>
                    </div>
                </div>
            </div>
            <div className="mx-5 px-3 mt-5 mb-10">
                <div className=" font-bold mb-2">
                    Co Signers
                </div>
                <div className="flex w-full justify-between">
                    <SignerForm/>
                </div>
            </div>
            <div className="flex justify-end">
                <button className="text-white mr-5 w-28 py-2 font-medium bg-gray-300 hover:bg-gray-600 rounded-sm">
                    Back
                </button>
                <button className="text-white w-28 py-2 font-medium bg-orange-500 hover:bg-orange-600 rounded-sm">
                    Submit
                </button>
            </div>
        </div>
    );
}
export default ReviewSend;