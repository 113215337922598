import React, {useEffect, useState} from 'react';

import { Route, Redirect } from 'react-router-dom';
import DataDashboard from './dataVis/DataDashboard';
import BackendLogin from "./dataVis/BackendLogin";
import { useStateWithSessionStorage } from '../helpers/sessionStorage';
import { USERNAME, PASSWORD, INTERNAL_USERNAME_PASSWORD } from './dataVis/BackendLogin';

const DataVis = (props) => {

    const { handleActivePage } = props;

	const [authData, handleAuthData] = useStateWithSessionStorage(INTERNAL_USERNAME_PASSWORD,JSON.stringify({}));
	const [pass, handlePass] = useState(false);

    useEffect(() => {
        handleActivePage('datavis');
    }, []);
    useEffect(()=>{
        const {username, password} = JSON.parse(authData);
        if (username === USERNAME && password === PASSWORD) {
            handlePass(true);
        }
    },[authData])
    return(
        <>
            <Route exact path="/datavis/login" component={(props)=><BackendLogin authData={authData} handleAuthData={handleAuthData} pass={pass} handlePass={handlePass} {...props}/>}/>
            <Route exact path="/datavis">
                {
                    pass ? <DataDashboard /> : <Redirect to="/datavis/login"/>
                }
            </Route>
        </>
    )
}
export default DataVis