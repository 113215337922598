import React from 'react';
import IconArrowDown from './IconArrowDown'
import '../../styles/Slider/SlideButton.scss'

const SlideButton = ({ onClick, type }) => (
  <div className="my-auto">
    <button className={`my-auto slide-button slide-button--${type}`} onClick={onClick}>
      <span>
        <IconArrowDown />
      </span>
    </button>
  </div>
);

export default SlideButton;