/**
 * @pzhu
 */
import React, { useEffect, useState } from 'react';

export const useLocalStorage = (key, initailValue) => {
    const [value, handleValue] = useState(() =>JSON.parse(localStorage.getItem(key)) || initailValue);
    useEffect(() => {
        const localData = JSON.parse(localStorage.getItem(key));
        localStorage.setItem(key, JSON.stringify({...localData, ...value}));
    },[value]);

    return [value, handleValue];

}