import React, { useState, useEffect} from 'react';
import CheckIcon from '../../assets/check.svg';
import moment from 'moment';
import ContractActions from './ContractActions';
import { withRouter } from 'react-router';
import { getDocumentFormattedStatus } from '../../helpers/dashboard';
const ContractName = (props) => {
    const {handleArchiveData, doc, signers, initiator, contract, provider, selectedHandler, actualDate, selectAll, handleSelectAll, update, idx, filter, formatMessage} = props;
    const [selected, handleSelected] = useState(false);

    useEffect (()=>{
		let isSubscribed = true;
        handleSelected(selectAll);
		return () => isSubscribed = false;
	},[update]);

    if(!doc || !doc.creation || !doc.expiration) {
        return null;
    }

    return(
        <td className="px-3 cursor-pointer border-l border-transparent ">
            <div className="w-full py-4 flex"> 
                <div className="select-none w-5 h-5 mr-3 border border-gray-300 rounded-sm flex-none justify-center cursor-pointer"
                    onClick={(event)=>{
                        event.stopPropagation();
                        if(selected){
                            handleSelectAll(false);
                        }
                        selectedHandler(!selected);
                        handleSelected(!selected);
                    }}
                >
                    {selected === true && <img onDragStart={(event) => event.preventDefault()} src={CheckIcon}/>}
                </div>
                    
                <div className="w-full">
                    <div className="flex-grow">
                        <div>{doc?.name}</div>
                        <div className="text-10 font-medium mt-1">
                            {actualDate === true? 
                                <>{`${moment.unix(doc?.creation.date).format('lll')} 
                                ${doc?.expiration.date? `- ${moment.unix(doc?.expiration.date).format('lll')}`:''}
                                `}</>
                                : <>{`${doc?.creation.block} - ${doc?.expiration.block}`}</>
                            }
                        </div>
                    </div>
                    <div className="sm:hidden">
                        <div className="mt-1">
                            {getDocumentFormattedStatus(doc?.status, formatMessage)}
                        </div>
                        <div className="mt-1 flex">
                            <ContractActions doc={doc} signers={signers} initiator={initiator} filter={filter} contract={contract} provider={provider} idx={idx} handleArchiveData={(documentKey)=>handleArchiveData(documentKey)}/>
                        </div>
                    </div>


                </div>
                
                
            </div>
            
        </td>
    );
}
export default withRouter(ContractName);