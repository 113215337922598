import React, { useEffect, useState } from 'react';
import { getWeb3Credentials } from '../helpers/dashboard';
import { withRouter } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';
import Alert from './Alert';

const LogIn = (props) => {
	const { location, history, provider, handleWeb3Case, handleEthAccount, handleNetworkId, handleWeb3, handleContract, handleActivePage, handleFm, handleTorus } = props;
	const [error, handleError] = useState(false);
	useEffect(() => {
		let isSubscribed = true;
		(async () => {
			await getWeb3Credentials(null, null, location, history, provider).then((result) => {
				handleEthAccount(result.ethAccount);
				handleNetworkId(result.networkId);
				handleWeb3(result.web3);
				handleWeb3Case(result.web3Case);
				handleContract(result.contract);
				handleFm(result.fm);
				handleTorus(result.torus);
				props.history.push({
					pathname: '/contracts',
				})
			})
				.catch((e) => {
					console.log(e);
					handleError(true);
					// alert('The blockchain network you are connected to is unsupported at this time. You can try to switch to a supported network in MetaMask.')
					// handleActivePage(null);
					// handleWeb3Case(null);
					// props.history.push({
					// 	pathname: '/signin',
					// })
				})

		})();
		return () => isSubscribed = false
	}, [history, location]);

	return (
		<>
			<LoadingScreen location={location} />
			{error &&
				<Alert 
					type={1}
					message="You are currently using an unsupported network. Please switch to a supported network and log in again."
					closeOnOutsideClick={true}
					closeCallback={() => {
						handleActivePage(null);
						handleWeb3Case(null);
						props.history.push({
							pathname: '/signin',
						})
					}}
				/>
			}
		</>
	);
}
export default withRouter(LogIn);



