import React from 'react';
import logo from '../assets/logo-with-bg.png';
import Loader from './UI/loader';
import { FormattedMessage } from 'react-intl';

const LoadingScreen = (props) => {
	const { location } = props;
	return (
		<div className="flex-grow flex flex-col justify-center px-2 sm:px-10 mt-16">
			<div className="lg:max-w-6xl lg:mx-auto text-gray-70">
				<div className="flex flex-col mb-10 justify-center">
					<img src={logo} className="-z-10 w-48 m-auto" />
					<div className="text-15 font-normal mt-5 text-center">
						<FormattedMessage id="CONNECTING_TO_THE_BLOCKCHAIN_NETWORK"/>
					</div>
					{!location &&
						<div className="text-15 mt-4 font-normal text-center">
							<FormattedMessage id ="PLEASE_MAKE_SURE_YOUR_ACCOUNT" />
							<a href="https://metamask.io/">
								<FormattedMessage id="METAMASK_BROWSER_PLUGIN"/>	
							</a>.
						</div>
					}
					<div className="w-48 -z-10 mb-8 flex justify-center text-center mx-auto">
						<Loader />
					</div>

				</div>
			</div>
		</div>
	);
}
export default LoadingScreen