import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import PdfIcon from '../../assets/pdf.png';
import { storeNotif } from '../../helpers/dashboard';
import AutoResizeInput from '../UI/AutoResizeInput';

const FileDisplay = (props) => {
    const {filename, handleFilename, pageCount, updateStoredData} = props;
    const [rename, handleRename] = useState(false);
    const [changed, handleChanged] = useState(false)
    const { formatMessage } = useIntl();
    const [shouldShowLengthError, handleShouldShowLengthError] = useState(true);
  
    const handleChange = (evt) => {
        const name = evt.target.value
        handleChanged(true)
        handleFilename({
            name: name,
            ext: filename.ext
        })
    }
    useEffect (()=>{ 
      let isSubscribed = true;
       if(changed){
        updateStoredData();
       }
      return () => isSubscribed = false;
    },[filename]);
    const checkLength = () => {
        try {
            let inputValue = document.getElementById('file-rename-input')?.value;
            if(!inputValue) {
                if(!shouldShowLengthError) {
                    handleShouldShowLengthError(true);
                }
                return;
            }
            
            let byteSize = (new TextEncoder().encode(inputValue).length);
            if(byteSize >= 27) {
                if(shouldShowLengthError) {
                    storeNotif('Max Length Reached', 'You have reached the maximum name length.', 'warning');
                    handleShouldShowLengthError(false);
                }
                while(byteSize >= 27) {
                    inputValue = inputValue.substring(0, inputValue.length - 1);
                    byteSize = (new TextEncoder().encode(inputValue).length);
                }
                handleFilename({
                    name: inputValue,
                    ext: filename.ext
                });
            } else {
                if(!shouldShowLengthError) {
                    handleShouldShowLengthError(true);
                }
            }
        } catch(err) {
            console.log(err);
        }
    }

    return(
        <>
            <div className="select-none font-bold mb-4">
                <FormattedMessage id='UPLOADED_DOCUMENT'/>
            </div>
            <div className="shadow-md p-3 flex">
                <img src={PdfIcon} className="select-none mr-3 mb-auto"></img>
                <div className="flex flex-grow flex-col justify-center">
                    <div className="flex">
                        <div className={`cursor-pointer border rounded-sm hover:border-gray-70 ${rename ? 'border-gray-70' : 'border-transparent'}`} >
                            {
                                // the extension of file, customer can not modify
                                <form 
                                onChange={() => {checkLength();}}
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleRename(false);
                                    document.getElementById('file-rename-input')?.blur();
                                }}>
                                    <AutoResizeInput 
                                        id="file-rename-input"
                                        className="focus:outline-none rounded-sm mr-0 border-gray-200 px-1"
                                        value={filename?.name}
                                        onFocus={() => {
                                            handleRename(true);
                                        }}
                                        maxLength={27}
                                        onBlur={()=> {
                                            if(rename) {
                                                handleRename(false);
                                            }
                                        }}
                                        onChange={(evt)=>{
                                            handleChange(evt)
                                        }}
                                    />
                                </form>
                            }

                        </div>
                        <div className="flex-grow">{filename && filename.ext}</div>
                    </div>
                    {pageCount &&
                        <div className="font-medium text-gray-70 my-1 ml-1.5">
                            {pageCount} {pageCount==1? formatMessage({id: 'PAGE'}): formatMessage({id: 'PAGES'})}
                        </div>
                    }
                </div>
            </div>
            
        </>
    );
}
export default FileDisplay;