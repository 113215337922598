/**
 * @author
 */

import React, { useEffect, useState } from 'react';
import { getBrowserLanguage } from '../helpers/language';
import * as localData from '../message/language-export.js';
import DownArrow from '../assets/downarrow-gray-300.svg';
import Globe from '../assets/globe.svg';

const languageMap = {
    'en': 'English(US)',
    'jp': '日本語(JP)',
    'zh': '中文(ZH)'
}

export function languageSupported(lang) {
    switch(lang) {
        case 'en':
        case 'jp':
        case 'zh':
            return true;
        default:
            return false;
    }
}

export default function LanguageOption(props) {
    const {handleLanguage, language, inverse} = props;
    const [showLanguageSelect, handleShowLanguageSelect] = useState(false);

    const handleChange = (language) => {
        handleLanguage(language)
    }

    useEffect(() => {
		let languageDropdownListener = hideLanguageDropdown.bind(this);
		document.addEventListener('click', languageDropdownListener);

		return () => document.removeEventListener('click', languageDropdownListener);
	}, [showLanguageSelect]);

    const hideLanguageDropdown = (event) => {
		const languageDropdown = document.getElementById('language-selector');
		if( showLanguageSelect && (!languageDropdown || (event &&  !languageDropdown.contains(event.target) ) ) ) {
			handleShowLanguageSelect(false);
		}
	}

    return (
        <>
            <div className={`relative w-36 inline-block flex ${inverse ? 'justify-center sm:justify-end' : 'justify-start'}`}>
                <div onClick={() => handleShowLanguageSelect(!showLanguageSelect)} className={`flex w-max pl-2 rounded-md text-15 h-full my-auto text-gray-510 hover:text-gray-750 cursor-pointer select-none`}>
                    <img className="mx-1 w-4 h-4 my-auto" src={Globe} />
                    <div className="my-auto">{language ? languageMap[language] : languageMap['en']}</div>
                    <img className="mx-1 w-4 h-4 my-auto" src={DownArrow} />
                </div>
                {showLanguageSelect &&
                        <div id="language-selector" className={`z-999${inverse ? ' origin-bottom transform -translate-y-full pb-2' : ' mt-6'} w-full absolute flex flex-col justify-center`}>
                            
                            <div className={`text-15 mt-1 select-none w-full rounded-md shadow-lg bg-white ring-1 ring-orange-500 ring-opacity-50 focus:outline-none`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                                <div className="py-1" role="none">
                                    {
                                        Object.keys(localData)?.map((language, index) => {
                                            return (
                                                <div onClick={()=> handleChange(language)}
                                                value={language}
                                                key={index}
                                                className="hover:bg-gray-25 cursor-pointer block px-4 py-2 text-sm text-center" role="menuitem" tabIndex="-1" >{languageMap[language]}</div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    }
            </div>
            {/* <select defaultValue={language} onChange={(value)=> handleChange(value)} className='flex-shrink bg-transparent h-8 focus:outline-none text-gray-70 rounded-md px-3'>
                {
                    Object.keys(localData)?.map((language, index) => {
                        return (
                            <option label={languageMap[language]} value={language} key={index}/>
                        )
                    })
                }
            </select > */}
        </>
    )
}