import React from 'react';
import { withRouter } from 'react-router-dom'
import LanguageOption from './LanguageOption';
import PopupOnClick from './Dashboard/PopupOnClick';
import { useIntl } from 'react-intl';
import TermsOfUse from './Terms/TermsOfUse';
import PrivacyPolicy from './Terms/PrivacyPolicy';
const Footer = (props) => {
	const {handleLanguage, language, activePage} = props;
  const intl = useIntl()

  const nofooter = "create upload sign landing"
  const needsBackground = "landing"
	return (
		<>
      {!nofooter.includes(activePage) &&
        <div className={`w-full${needsBackground.includes(activePage) ? ' bg-white' : ''}`}>
          <div className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8`}>
            <div className="relative flex items-center justify-center h-16">
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-center">
                <LanguageOption handleLanguage={handleLanguage} language={language} inverse={true}/>
                <div className="hidden sm:block my-auto">
                  <PopupOnClick
                      trigger={<span className="ml-5 mr-3 text-gray-510 hover:text-gray-750 cursor-pointer select-none">
                          {intl.formatMessage({id: "TERMS_OF_USE"})}
                          </span>}
                      PopupComponent={TermsOfUse}
                      className="term-popup"
                  />
                  <PopupOnClick
                      trigger={<span className="ml-3 text-gray-510 hover:text-gray-750 cursor-pointer select-none">
                          {intl.formatMessage({id:"PRIVACY_POLICY"})}
                      </span>}
                      PopupComponent={PrivacyPolicy}
                      className="term-popup"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
		</>
	);
}

export default withRouter(Footer);
