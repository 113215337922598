
import _ from 'lodash';
export const chains = [
    {
        name: "Ethereum Mainnet",
        chainId: 1,
        shortName: "eth",
        chain: "ETH",
        network: "mainnet",
        networkId: 1,
        nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18
        },
        rpc: [
            "https://mainnet.infura.io/v3/59e38e7a0505462d810e0ac606665fd1",
            "wss://mainnet.infura.io/ws/v3/59e38e7a0505462d810e0ac606665fd1",
            "https://api.mycryptoapi.com/eth",
            "https://cloudflare-eth.com"
        ],
        faucets: [ ],
        explorers: [
            {
                name: "etherscan",
                url: "https://etherscan.io",
                standard: "EIP3091"
            }
        ],
        infoURL: "https://ethereum.org",
        fortmaticSupport: true,
        biconomySupport: false,
        torusSupport: true
    },
    {
        name: "Binance Smart Chain Mainnet",
        chainId: 56,
        shortName: "bnb",
        chain: "BSC",
        network: "mainnet",
        networkId: 56,
        nativeCurrency: {
            name: "Binance Chain Native Token",
            symbol: "BNB",
            decimals: 18
        },
        rpc: [
            'https://bsc-dataseed.binance.org/',
            "https://bsc-dataseed1.binance.org",
            "https://bsc-dataseed2.binance.org",
            "https://bsc-dataseed3.binance.org",
            "https://bsc-dataseed4.binance.org",
            "https://bsc-dataseed1.defibit.io",
            "https://bsc-dataseed2.defibit.io",
            "https://bsc-dataseed3.defibit.io",
            "https://bsc-dataseed4.defibit.io",
            "https://bsc-dataseed1.ninicoin.io",
            "https://bsc-dataseed2.ninicoin.io",
            "https://bsc-dataseed3.ninicoin.io",
            "https://bsc-dataseed4.ninicoin.io",
            "wss://bsc-ws-node.nariox.org"
        ],
        faucets: [ ],
        explorers: [
            {
                name: "bscscan",
                url: "https://bscscan.com",
                standard: "EIP3091"
            }
        ],
        infoURL: "https://www.binance.org",
        fortmaticSupport: true,
        biconomySupport: true,
        biconomyKey: 'TkNhgnRKe.570065b2-9510-4ecb-afe3-babe48ad6278',
        torusSupport: false
    },
    {
        name: "Avalanche Mainnet",
        chainId: 43114,
        shortName: "Avalanche",
        chain: "AVAX",
        network: "mainnet",
        networkId: 1,
        nativeCurrency: {
            name: "Avalanche",
            symbol: "AVAX",
            decimals: 18
        },
        rpc: [
            "https://api.avax.network/ext/bc/C/rpc"
        ],
        faucets: [ ],
        explorers: [ ],
        infoURL: "https://cchain.explorer.avax.network/",
        fortmaticSupport: false,
        biconomySupport: false,
        torusSupport: false
    },
    {
        name: "Matic Mainnet",
        chainId: 137,
        shortName: "matic",
        chain: "Matic",
        network: "mainnet",
        torusNetwork: "matic",
        networkId: 137,
        nativeCurrency: {
            name: "Matic",
            symbol: "MATIC",
            decimals: 18
        },
        rpc: [
            "https://polygon-mainnet.g.alchemy.com/v2/b8HxLm8ftPKUWbEUInT5yb2WI_meyqNe",
            "wss://polygon-mainnet.g.alchemy.com/v2/b8HxLm8ftPKUWbEUInT5yb2WI_meyqNe",
            "https://rpc-mainnet.matic.network",
            "wss://ws-mainnet.matic.network"
        ],
        faucets: [ ],
        explorers: [ ],
        infoURL: "https://matic.network/",
        fortmaticSupport: false,
        biconomySupport: true,
        biconomyKey: 'tpkapBz2J.18f4ffa5-a0d1-4a64-b65c-bc130b44cd98',
        torusSupport: true
    },
    {
        name: "Fantom Opera",
        chainId: 250,
        shortName: "ftm",
        chain: "FTM",
        network: "mainnet",
        networkId: 250,
        nativeCurrency: {
            name: "Fantom",
            symbol: "FTM",
            decimals: 18
        },
        rpc: [
            "https://rpcapi.fantom.network"
        ],
        faucets: [ ],
        explorers: [
            {
                name: "ftmscan",
                url: "https://ftmscan.com",
                icon: "ftmscan",
                standard: "EIP3091"
            }
        ],
        infoURL: "https://fantom.foundation",
        fortmaticSupport: false,
        biconomySupport: false,
        biconomyKey: '_Uzqx_1iE.f43ef108-1ff1-4647-a10e-6446ddd8ffb4',
        torusSupport: false
    },
    // {
    //     name: "Binance Smart Chain Testnet",
    //     chainId: 97,
    //     shortName: "bnbt",
    //     chain: "BSC",
    //     network: "Chapel",
    //     networkId: 97,
    //     nativeCurrency: {
    //         name: "Binance Chain Native Token",
    //         symbol: "tBNB",
    //         decimals: 18
    //     },
    //     rpc: [
    //         "https://data-seed-prebsc-1-s1.binance.org:8545",
    //         "https://data-seed-prebsc-2-s1.binance.org:8545",
    //         "https://data-seed-prebsc-1-s2.binance.org:8545",
    //         "https://data-seed-prebsc-2-s2.binance.org:8545",
    //         "https://data-seed-prebsc-1-s3.binance.org:8545",
    //         "https://data-seed-prebsc-2-s3.binance.org:8545"
    //     ],
    //     faucets: [
    //         "https://testnet.binance.org/faucet-smart"
    //     ],
    //     explorers: [
    //         {
    //             name: "bscscan-testnet",
    //             url: "https://testnet.bscscan.com",
    //             standard: "EIP3091"
    //         }
    //     ],
    //     infoURL: "https://testnet.binance.org/",
    //     fortmaticSupport: true,
    //     biconomySupport: true,
    //     biconomyKey: 'l-5gPgQYJ.0ae80638-cb0a-47d3-81b1-3c0e88eec347',
    //     torusSupport: false
    // },
    {
        name: "Ethereum Ropsten Testnet",
        chainId: 3,
        shortName: "rop",
        chain: "ETH",
        network: "ropsten",
        networkId: 3,
        nativeCurrency: {
            name: "Ropsten Ether",
            symbol: "ROP",
            decimals: 18
        },
        rpc: [
            "https://ropsten.infura.io/v3/59e38e7a0505462d810e0ac606665fd1",
            "wss://ropsten.infura.io/ws/v3/59e38e7a0505462d810e0ac606665fd1"
        ],
        faucets: [
            "https://faucet.ropsten.be?${ADDRESS}"
        ],
        explorers: [ ],
        infoURL: "https://github.com/ethereum/ropsten",
        fortmaticSupport: false,
        biconomySupport: false,
        torusSupport: true
    },
    // {
    //     name: "Moonbeam Testnet Moonbase Alpha",
    //     chainId: 1287,
    //     shortName: "mbase",
    //     chain: "MOON",
    //     network: "moonbase",
    //     networkId: 1287,
    //     nativeCurrency: {
    //         name: "DEV",
    //         symbol: "DEV",
    //         decimals: 18
    //     },
    //     rpc: [
    //         "https://rpc.testnet.moonbeam.network"
    //     ],
    //     faucets: [ ],
    //     explorers: ["https://docs.moonbeam.network/networks/testnet/"],
    //     infoURL: "https://rpc.testnet.moonbeam.network",
    //     fortmaticSupport: false,
    //     biconomySupport: false,
    //     torusSupport: false
    // },
    // {
    //     name: "Kovan Test Network",
    //     chainId: 42,
    //     networkId: 42,
    //     nativeCurrency: {
    //         name: "ETH",
    //         symbol: "ETH",
    //         decimals: 18
    //     },
    //     rpc: [
    //         "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
    //     ],
    //     faucets: [ ],
    //     explorers: ["https://kovan.etherscan.io"],
    //     infoURL: "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    //     fortmaticSupport: false,
    //     torusSupport: false
    // }
]

export const getChain = (networkId) => {
    const index = _.findIndex(chains, function(o) { return o.chainId == networkId; });
    return chains[index]
}

export const getFortmaticChainOptions = (networkId) => {
    const index = _.findIndex(chains, function(o) { return o.chainId == networkId; });
    return { rpcUrl: chains[index].rpc[0], chainId: networkId };
}

export const getTorusChainOptions = (networkId) => {
    const index = _.findIndex(chains, function(o) { return o.chainId == networkId; });
    return {
        network: {
            host: chains[index].torusNetwork ? chains[index].torusNetwork : chains[index].network,
            chainId: chains[index].chainId,
            networkName: chains[index].name,
            blockExplorer: chains[index].explorers.length > 0 && chains[index].explorers[0].url ? chains[index].explorers[0].url : chains[index].infoURL,
            ticker: chains[index].nativeCurrency.symbol,
            tickerName: chains[index].nativeCurrency.name,
        },
        showTorusButton: false,
    };
}

export const getTorusChainSwitchOptions = (networkId) => {
    const index = _.findIndex(chains, function(o) { return o.chainId == networkId; });
    return {
        host: chains[index].torusNetwork ? chains[index].torusNetwork : chains[index].network,
        chainId: chains[index].chainId,
        networkName: chains[index].name,
    };
}

export const mainnets = ['Matic Mainnet', 'Fantom Opera', 'Ethereum Mainnet', 'Avalanche Mainnet', 'Binance Smart Chain Mainnet'];

export const testnets = ['Ethereum Ropsten Testnet', 'Binance Smart Chain Testnet', 'Moonbeam Testnet Moonbase Alpha'];

export const getMainNetChain = () => {
    return chains.filter(chain => mainnets.includes(chain.name))
}

export const getTestNetChain = () => {
    return chains.filter(chain => testnets.includes(chain.name))
}

export const isTestnet = (chainId) => {
    return testnets.includes(getChain(chainId).name);
}