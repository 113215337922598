export const getMyName = (ensEnabled, signer, formatMessage) => {
    return `${formatMessage({id: 'ME'})} ${ensEnabled && signer?.alias ? `(${signer.alias})`: ''}`
}

export const getAlias = (ethAccount, signer, ensEnabled, formatMessage) => {
    if (ethAccount?.localeCompare(signer?.address, undefined, { sensitivity: 'accent' }) === 0){
        return getMyName(ensEnabled, signer, formatMessage) 
    }else if (ensEnabled && signer.alias){
        return signer.alias
    }
}

export const getAliasOrAddress = (ethAccount, signer, ensEnabled, formatMessage) => {
    if (ethAccount?.localeCompare(signer?.address, undefined, { sensitivity: 'accent' }) === 0){
        return getMyName(ensEnabled, signer, formatMessage) 
    }else if (ensEnabled && signer.alias){
        return signer.alias
    }else{
        return signer.address?.toLowerCase()
    }
}

const getSignerData = async (signer, provider) => {
    try {
        const alias = await provider.lookupAddress(signer?.address)
        let avatar = null;
        if (alias){
            const resolver = await provider.getResolver(alias)
            avatar = await resolver.getText("avatar") 
        }
        return Promise.resolve({
            address: signer?.address?.toLowerCase(),
            avatar: avatar,
            alias: alias,
            fullySigned: signer?.fullySigned
        })
    }catch{(err)=>{
        return Promise.resolve(
            signer
        )
    }}

}
  
export const getSignersWithAlias = async (signers, provider) => {
    return Promise.all(signers.map(signer => getSignerData(signer, provider)))
}