/**
 * @author pzhu
 * @description main class for TERMS
 */
import React from 'react';
import PopupOnClick from "../../components/Dashboard/PopupOnClick";
import TermsOfUse  from "./TermsOfUse";
import PrivacyPolicy from "./PrivacyPolicy";
import {useIntl} from 'react-intl';
import "../../styles/terms.scss"

export default function Terms() {
    const intl = useIntl()
    return (
        <div className="flex flex-wrap">
            <span className="mr-1">
                {
                    intl.formatMessage({id: "BY_LOGGING_IN"})
                }
            </span>
            <PopupOnClick
                trigger={<span className="style-6 mr-1">
                    {intl.formatMessage({id: "TERMS_OF_USE"})}
                    </span>}
                PopupComponent={TermsOfUse}
                className="term-popup"
            />
            <span className="mr-1"> {intl.formatMessage({id: "AND"})} </span>
            <PopupOnClick
                trigger={<span className="style-6 mr-1">
                    {intl.formatMessage({id:"PRIVACY_POLICY"})}
                </span>}
                PopupComponent={PrivacyPolicy}
                className="term-popup"
            />
        </div>
    )
}