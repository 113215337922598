import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Lottie, LottieInteractivity } from './lottie';

import Slider from '../components/Slider';
import '../styles/webflow.scss';
import '../styles/ethsign-webflow.scss';

import { useIntl } from 'react-intl';
import { loadAllContractHistoryData } from "../helpers/graphql";
import logo from '../assets/logo.svg';
import clear from '../assets/clear-x.svg';
import Group121 from '../assets/webflow-images/Group-121.svg';
import Frame25 from '../assets/webflow-images/Frame-25.svg';
import Frame24 from '../assets/webflow-images/Frame-24.svg';
import Medium from '../assets/webflow-images/medium.png';
import Youtube from '../assets/webflow-images/youtube.png';
import Discord from '../assets/webflow-images/discord-1.png';
import Linkedin from '../assets/webflow-images/linkedin.png';
import Twitter from '../assets/webflow-images/Twitter.png';
import SignPass from '../assets/signpass_polygon.png';
import ReactPlayer from 'react-player';
import Frame_65 from '../assets/webflow-images/Frame-65.png';
import Frame_66 from '../assets/webflow-images/Frame-66.png';
import FinalityBridge from '../assets/webflow-images/Finality_Bridge.svg';
import Map from '../assets/webflow-images/map.gif';
import signature from '../assets/webflow-images/Signature.png';
import X from "../assets/x.svg"
import ThickMenu from '../assets/thick-menu.svg';
import ThickMenuWhite from '../assets/thick-menu-white.svg';
import CarretDown from '../assets/carret_down_black.svg';

import LineBox from '../assets/lotties/line-box.json';
import EthSignTest from "../assets/lotties/ethsign.json";
import Circleanimation from "../assets/lotties/circleanimation.json";
import OneLine from "../assets/lotties/oneline.json";

import Ankr from '../assets/webflow-images/logos/ankr.svg'
import Avalanche from '../assets/webflow-images/logos/avalanche.svg'
import Blockchainusc from '../assets/webflow-images/logos/blockchainusc.svg'
import BSC from '../assets/webflow-images/logos/BSC.svg'
// import Convergence from '../assets/webflow-images/logos/convergence.svg'
import Dorahacks from '../assets/webflow-images/logos/dorahacks.svg'
import Draper from '../assets/webflow-images/logos/draper.svg'
import Ens from '../assets/webflow-images/logos/ens.svg'
import Fantom from '../assets/webflow-images/logos/fantom.svg'
import Fosun from '../assets/webflow-images/logos/fosun.svg'
import Imtoken from '../assets/webflow-images/logos/imtoken.svg'
import Magic from '../assets/webflow-images/logos/magic.svg'
import Mask from '../assets/webflow-images/logos/mask.svg'
import Moonbeam from '../assets/webflow-images/logos/moonbeam.svg'
import Polygon from '../assets/webflow-images/logos/polygon.svg'
import Thegraph from '../assets/webflow-images/logos/thegraph.svg';
import NaosFinance from '../assets/webflow-images/logos/naosfinance.png';
import Torus from '../assets/webflow-images/logos/torus.svg';
import Arcx from '../assets/webflow-images/logos/arcx.png';
import DeepDAO from '../assets/webflow-images/logos/deepdao.png';
import Biconomy from '../assets/webflow-images/logos/biconomy.svg';
import VerifyContract from './VerifyContract';
import LanguageOption from './LanguageOption';
import ContactUs from './Landing/ContactUs';

const Landing = (props) => {
	const { handleActivePage, handleLanguage, language } = props;
	const [card, handleCard] = useState(null);
	const [activeItem, handleActiveItem] = useState(0);
	const [totalSignaturesSigned, handleTotalSignaturesSigned] = useState('--');
	const [disable, handleDisable] = useState(false);
	const [shouldHaveListener, handleShouldHaveListener] = useState(false);
	const [showMobileNavbar, handleShowNavbar] = useState(false);
	const [showCommunity, handleShowCommunity] = useState(false);
	const { formatMessage } = useIntl();

	useEffect(() => {
		let isSubscribed = true
		handleActivePage('landing')
		handleCard(CARD_LIST[0])
		return () => isSubscribed = false;
	}, []);

	useEffect(() => {
		(async () => {
			try {
				const contractData = await loadAllContractHistoryData();
				let num = 0;
				for(let c of contractData) {
					if(c.data?.infos) {
						num += c.data.infos[0].totalSignaturesSigned;
					} else {
						handleTotalSignaturesSigned('--');
						return;
					}
				}
	
				handleTotalSignaturesSigned(num)
			} catch(err) {
				// Sometimes we get "failed to fetch" errors
				console.log(err);
				handleTotalSignaturesSigned('--');
			}
		})();
	},[]);

	useEffect(() => {
		let nextActiveItem = 0
		if (CARD_LIST.length > activeItem + 1) {
			nextActiveItem = activeItem + 1
		}
		const timer = setInterval(() => {
			handleCard(CARD_LIST[nextActiveItem]);
			handleActiveItem(nextActiveItem);
		}, 5000);

		return () => clearInterval(timer)
	}, [activeItem])

	const CARD_LIST = [
		{
			id: 1,
			content: formatMessage({id: 'TIM_DRAPER_CONTENT'}),
			img: Draper,
			personName: 'Tim Draper',
			color: '#2544d9',
			position: formatMessage({id: 'TIM_DRAPER_POSITION'})
		},
	
		{
			id: 2,
			content: formatMessage({id: 'XINJUN_LIANG_CONTENT'}),
			img: Fosun,
			personName: 'Xinjun Liang',
			color: '#525f90',
			position: formatMessage({id: 'XINJUN_LIANG_POSITION'})
		},
		{
			id: 3,
			content: formatMessage({id: 'CHANDLER_SONG_CONTENT'}),
			img: Ankr,
			personName: 'Chandler Song',
			color: '#2544d9',
			position: formatMessage({id: 'CHANDLER_SONG_POSITION'})
		},
		{
			id: 4,
			content: formatMessage({id: 'SUJI_YAN_CONTENT'}),
			img: Mask,
			personName: 'Suji Yan',
			color: '#2544d9',
			position: formatMessage({id: 'SUJI_YAN_POSITION'})
		},
	];
	
	const Card = (props) => {
		const { data: {content, img, color, personName, position} } = props;
		return (
			<div className="flex flex-col justify-between border-white bg-white rounded-2xl p-2 xs:p-4 lg:p-8 w-full lg:w-3/4 h-112 xs:h-96 lg:h-5/6">
				<div className="flex items-center justify-between">
					<span style={{color}} className={`text-4xl sm:text-6xl font-black text-center select-none`}>‘‘</span>
					<img src={img} loading="lazy" alt="" className="w-16 lg:w-32 h-8 lg:h-12 select-none"/>
				</div>
				
				<p className="p-1 lg:p-8 mt-0">{content}</p>
				<div className="p-1 lg:p-8">
					<p className="font-bold">{personName}</p>
					<p>{position}</p>
				</div>
	
			</div>
		)
	}

	const handleChange = (index) => {
		handleCard(CARD_LIST[index]);
		handleActiveItem(index);
	}

	const showMenu = show => {
		if(!show) {
			hideMenu();
			return;
		}
		
		handleShowNavbar(true);
		handleShouldHaveListener(true);

	}

	const showCommunityHandler = show => {
		if(!show) {
			hideMenu();
			return;
		}

		handleShowCommunity(true);
		handleShouldHaveListener(true);

	}

	const hideMenu = (event) => {
		if(showCommunity && !showMobileNavbar) {
			handleShowCommunity(false);
			handleShouldHaveListener(false);
			return;
		}

		const element = document.getElementById('nav');
		if(!element || (event && !element.contains(event.target))) {
			handleShowCommunity(false);
			handleShowNavbar(false);
			handleShouldHaveListener(false);
		}
	}

	useEffect(() => {
		let eventListener = null;
		if(shouldHaveListener) {
			eventListener = eventListener = hideMenu.bind(this);
			document.addEventListener('click', eventListener);
		}

		return () => {
			if(eventListener) {
				document.removeEventListener('click', eventListener);
			}
		}
	}, [showMobileNavbar, showCommunity, shouldHaveListener])

	const toggleCommunityNavbar = () => {
		handleShowCommunity(!showCommunity);
	}

	
	return (
		<>
			<div data-collapse="medium" data-animation="default" data-duration="400" role="banner" className="sticky top-0 w-nav z-1100">
				{/* {
					!disable && (
						<div className="relative w-full">

						<div className="flex flex-col xs:flex-row justify-center items-center py-4 bg-white relative text-xs sm:text-base">
							<div className="mr-2 sm:mr-4">
								<span>Limited Time Event: </span>
								<span className="font-black">SIGN WEEK!</span>
							</div>
							<a href="https://www.eventbrite.ca/e/ethsign-presents-sign-week-live-event-tickets-161745145083" target="_blank" className="p-1 px-4 border border-orange-100 text-orange-100 rounded-sm font-bold">JOIN EVENT</a>
							<img src={X} alt="" className="absolute cursor-pointer select-none right-2 sm:right-12" onClick={()=>{handleDisable(true)}}/>
						</div>
						</div>
					)
				} */}

					<div id="nav" className={`mx-auto w-full flex flex-col lg:flex-row justify-start lg:justify-center ${showMobileNavbar ? 'bg-white lg:bg-transparent' : 'bg-transparent'}`}>
						<div id="toppartofnav" className={`${showMobileNavbar ? 'bg-white lg:bg-transparent' : 'bg-transparent'}`}>
							<div className="h-16 flex flex-row">
								<div className={`block lg:hidden my-auto p-2 sm:p-4 bg-transparent ml-4`} onClick={() => {if(showMobileNavbar) {handleShowCommunity(false); handleShowNavbar(false); handleShouldHaveListener(false);} else {showMenu(!showMobileNavbar);}}}>
									<img src={showMobileNavbar ? clear : ThickMenu} className={`select-none h-6 w-6 sm:h-8 sm:w-8`} />
								</div>
								<Link to="/" aria-current="page" className="my-auto w-20 sm:w-24 w--current lg:ml-8 ml-3"><img src={logo} loading="lazy" alt="" className="select-none w-full mt-1.5 lg:mt-0" /></Link>
							</div>
						</div>
						<div className="flex flex-col lg:flex-row w-full max-w-7xl">
							<div className="w-full lg:w-auto ml-0 flex flex-col lg:flex-row lg:mr-auto mr-4 sm:mr-6 lg:ml-4" >
								<div className="w-11/12 lg:w-auto mx-auto block lg:flex">
									<div className={`${showMobileNavbar ? 'block lg:block' : 'hidden lg:block'} lg:ml-auto lg:my-auto bg-white lg:bg-transparent`}>
										<nav role="navigation" className="flex flex-col lg:flex-row w-full lg:w-auto pb-2 lg:pb-0">
											<div data-hover="" data-delay="0" className="nav-link w-dropdown ml-0 mr-0 align-top my-auto">
												<div id="community" className="flex cursor-pointer px-5 py-3 h-full w-full text-black hover:text-orange-500" onClick={() => {
													if(showCommunity) {
														toggleCommunityNavbar();
													} else {
														showCommunityHandler(!showCommunity)
													}
												}}>
													<div className="select-none text-15 mr-auto">{formatMessage({id: 'COMMUNITY_CAPS'})}</div>
													<img src={CarretDown} className="select-none my-auto ml-1 h-3 w-3" />
												</div>
												{showCommunity &&
													<nav id="community-dropdown" className="block select-none lg:absolute pl-2 pr-3 ml-2 mt-0 lg:mt-2 bg-white border-r-0 border-b-0 lg:border-r-2 lg:border-b-2 border-orange-500">
														<a href="https://discord.gg/Wvhp9dWdSg" target="_blank" className="w-dropdown-link text-15 text-black hover:text-orange-500">{formatMessage({id: 'DISCORD'})}</a>
														<a href="https://youtu.be/nl8odfo6d8c" target="_blank" className="w-dropdown-link text-15 text-black hover:text-orange-500">{formatMessage({id: 'YOUTUBE'})}</a>
														<a href="https://www.twitter.com/ethsign" target="_blank" className="w-dropdown-link text-15 text-black hover:text-orange-500">{formatMessage({id: 'TWITTER'})}</a>
														<a href="https://www.linkedin.com/company/ethsign" target="_blank" className="w-dropdown-link text-15 text-black hover:text-orange-500">{formatMessage({id: 'LINKEDIN'})}</a>
														<a href="https://medium.com/ethsign" target="_blank" className="w-dropdown-link text-15 text-black hover:text-orange-500">{formatMessage({id: 'MEDIUM'})}</a>
													</nav>
												}
											</div>
											<div onClick={() => props.history.push({
													pathname: '/verify'
												})} className={`select-none cursor-pointer px-5 py-3 ml-0 mr-0 nav-link dropdown-toggle w-nav-link text-15 my-auto ${props.location.pathname == '/verify' ? 'text-orange-500' : 'text-black'} hover:text-orange-500`}>{formatMessage({id: 'VERIFY_CONTRACT_CAPS'})}</div>
											<a href="https://ethsign-1.gitbook.io/ethsign/" target="_blank" className="select-none px-5 py-3 ml-0 mr-0 nav-link dropdown-toggle w-nav-link text-15 my-auto text-black hover:text-orange-500">{formatMessage({id: 'DOCS_CAPS'})}</a>
											<a href="https://ethsign-1.gitbook.io/ethsign/for-users/getting-started-with-ethsign" target="_blank" className="select-none px-5 py-3 ml-0 mr-0 nav-link dropdown-toggle w-nav-link text-15 my-auto text-black hover:text-orange-500">{formatMessage({id: 'TUTORIAL_CAPS'})}</a>
										</nav>
									</div>
								</div>
							</div>
							<div className={`w-11/12 lg:w-auto ml-auto mr-auto lg:ml-auto lg:mr-0 lg:flex ${showMobileNavbar ? 'block lg:block' : 'hidden lg:block'}`}>
								<div className={`flex flex-col`}>
									<div className={`w-full border-t border-gray-510 mt-3 pb-3 ${showMobileNavbar ? 'block lg:hidden' : 'hidden'}`}></div>
									<div className="py-3 my-auto">
										<LanguageOption handleLanguage={handleLanguage} language={language}/>
									</div>
								</div>

								<button className="select-none focus:outline-none w-full text-white lg:w-32 lg:mx-4 my-3 py-2 font-medium bg-orange-500 hover:bg-orange-600 rounded-sm"
									onClick={() => {
										props.history.push({
											pathname: '/signin'
										})
									}}>
										{formatMessage({id: 'SIGN_IN_CAPS'})}
								</button>
							</div>
						</div>
					</div>
				</div>
			{props.location?.pathname == '/verify' &&
				<VerifyContract />
			}
			{props.location?.pathname !== '/verify' &&
				<>
					<div className="max-w-7xl mx-auto">
						<div className="flex flex-col sm:flex-row m-8 lg:m-0">
							<div className="sm:w-1/2 sm:px-4 sm:pt-4  lg:px-24 lg:pt-24 lg:pb-1">
								<h1 className="leading-normal font-bold text-2xl md:text-3xl">{formatMessage({id: 'JOIN_FUTURE_OF'})}</h1>
								<p>{formatMessage({id: 'MAKE_COMMENTS_EDIT_ACCESS'})}</p>
								<div className="w-4/5 sm:w-3/4 relative">
									<div className="text-color text-10 xl:text-15 bg-white px-4 py-2 flex justify-around items-center absolute top-1/2 transform-y-center z-10 ml-4 xs:ml-8 sm:ml-0">
										<img src={signature} alt="" className="select-none h-6 w-6" />
										<p className="ml-2 mb-0">
											<span className="font-bold ">{totalSignaturesSigned} </span> 
											{formatMessage({id: 'SIGNATURES_COMPLETED'})}
										</p>
									</div>

									<div className="hidden sm:block">
										<Lottie 
											options={{
												loop:true,
												autoPlay:true,
												animationData:LineBox
											}}	
										/>
									</div>
									<div className="block sm:hidden">
										<Lottie 
												options={{
													loop:true,
													autoPlay:true,
													animationData:OneLine
												}}	
											/>
									</div>

								</div>
							</div>
							<div className="w-full sm:w-2/5">
								<Lottie 
									options={{
										loop:true,
										autoPlay:true,
										animationData:EthSignTest
									}}
									
								/>							
							</div>
						</div>
					</div>
					<div className="relative mx-8 mt-4 sm:-mt-32 sm:mb-12 xl:mb-64">
						<div className=' sm:mt-12 md:mt-0 sm:mx-auto block xl:absolute xl:top-0 xl:left-1/2 xl:transform xl:-translate-x-2/4 sm:w-2/5 lg:w-1/4 bg-white rounded-lg'>
							<div className="p-4">
								<h1 className="text-base my-0">{formatMessage({id: 'WHAT_IS_ETHSIGN'})}</h1>
								<ReactPlayer
										url={[
											'https://youtu.be/8QoX7C0iLII',
										]}
										controls
										className='w-full h-auto'
									/>
							</div>
						</div>	
					</div>
					<div className="relative section-2 m-8 max-w-7xl mx-auto">
						<div className="w-full">
							<img className="w-full rounded-lg" src={SignPass} />
							<div className="absolute bottom-8 left-8">
								<div className="text-white font-bold text-2xl md:text-3xl leading-tight">Buy your<br/>SignPass today!</div>
								<div className="text-white text-15 sm:text-20 mt-2 font-regular md:font-semibold">Sign anytime anywhere without<br/>paying gas with SignPass.</div>
								<button className="mt-6 select-none focus:outline-none text-white sm:mx-0 py-2 px-6 font-semibold bg-orange-500 hover:bg-orange-600 rounded-sm"
								onClick={() => {
									window.open('https://opensea.io/collection/signpass', '_blank').focus();
								}}>Buy SignPass</button>
							</div>
						</div>
					</div>
					<div className="flex flex-col-reverse sm:flex-row section-2 m-8 lg:my-0 max-w-7xl lg:mx-auto">
						<div className="w-full sm:w-1/2 sm:p-4 md:p-12 lg:p-24">
							<span className="text-orange-100 font-bold">{formatMessage({id: 'COST_EFFICIENT_ON_CHAIN_SIGNING'})}</span>
							<h1 className="font-bold font-bold text-2xl md:text-3xl leading-tight">{formatMessage({id: 'METALAYER_BETWEEN'})} <br/> {formatMessage({id: 'GLOBAL_USERS_AND_BLOCKCHAIN'})}</h1>
							<div className="my-8 w-full sm:w-4/5">
								<h4>{formatMessage({id: 'AGGREGATED_SIGNATURES'})}</h4>
								<p>{formatMessage({id: 'INSTEAD_OF_INITIATING_X'})}</p>
							</div>
							<div className="my-8 w-full sm:w-4/5">
								<h4>{formatMessage({id: 'FLEXIBLE_FOR_WEB2_USERS'})}</h4>
								<p>{formatMessage({id: 'NON_CRYPTO_USERS_CHARGED_FIAT'})}</p>
							</div>
							<button className="select-none focus:outline-none w-full text-white sm:w-32 sm:mx-0 py-2 font-medium bg-orange-500 hover:bg-orange-600 rounded-sm"
								onClick={() => {
									props.history.push({
										pathname: '/signin'
									})
								}}>
									{formatMessage({id: 'TRY_SIGNING_CAPS'})}
							</button>
							{/* <Link to="/signin" className="button-2 w-button">TRY SIGNING</Link> */}
						</div>
						<div className="flex w-full sm:w-1/2">
							<img src={Frame_65} alt="" className="select-none w-full my-auto"/>
						</div>
					</div>
					<div className="flex flex-col sm:flex-row m-8 lg:my-0 lg:mx-auto max-w-7xl">
						<div className="sm:w-1/2">
							<img src={FinalityBridge} alt="" className="select-none"/>
						</div>
						<div className="sm:w-1/2 sm:p-4 md:pr-12 lg:pr-24">
							<span className="text-orange-100  font-bold">{formatMessage({id: 'BUILDING_ON_TOP'})}</span>
							<h1 className="font-bold text-2xl md:text-3xl leading-tight">{formatMessage({id: 'ETHSIGN_FINALITY_BRIDGE'})}</h1>
							<div className="my-8">
								<h4>{formatMessage({id: 'NO_MORE_HIGH_GAS_FEES'})}</h4>
								<p>{formatMessage({id: 'ETHSIGN_FINALITY_BRIDGE_AIMS'})}</p>
							</div>
							<div className="my-8">
								<h4>{formatMessage({id: 'NEW_WAY_OF_REACHING'})}</h4>
								<p>{formatMessage({id: 'INSTEAD_OF_GOING_THROUGH'})}</p>
							</div>
							<button className="select-none focus:outline-none w-full text-white sm:w-32 sm:mx-0 py-2 font-medium bg-orange-500 hover:bg-orange-600 rounded-sm"
								onClick={() => {
									props.history.push({
										pathname: '/signin'
									})
								}}>
									{formatMessage({id: 'TRY_SIGNING_CAPS'})}
							</button>
							{/* <Link to="/signin" className="button-2 w-button">TRY SIGNING</Link> */}
						</div>
					</div>
					<div className="flex flex-col-reverse sm:flex-row m-8 lg:my-0 lg:mx-auto max-w-7xl">
						<div className="sm:w-1/2 sm:p-4 md:p-12 lg:p-24">
							<span className="text-orange-100 font-bold">{formatMessage({id: 'LIFECYCLE_MANAGEMENT'})}</span>
							<h1 className="font-bold text-2xl md:text-3xl leading-tight">{formatMessage({id: 'TRACEABLE_AND_TRANSPARENT'})}</h1>
							<p className="my-8">{formatMessage({id: 'EVERY_TIME_THE_USER_SIGNS'})}</p>
							<p className="my-8">{formatMessage({id: 'THESE_PROVIDE_POWERFUL_TRACEABLE'})}</p>
							<button className="select-none focus:outline-none w-full text-white sm:w-32 sm:mx-0 py-2 font-medium bg-orange-500 hover:bg-orange-600 rounded-sm"
								onClick={() => {
									props.history.push({
										pathname: '/signin'
									})
								}}>
									{formatMessage({id: 'TRY_SIGNING_CAPS'})}
							</button>
							{/* <Link to="/signin" className="button-2 w-button">TRY SIGNING</Link> */}
						</div>
						<div className="sm:w-1/2">
							<img src={Frame_66} alt="" className="select-none"/>
						</div>
					</div>
					<div className="flex flex-col">
						<div className=" self-center mt-24 text-2 sm:text-2xl">
							<div className="font-bold text-2xl md:text-3xl leading-tight">{formatMessage({id: 'PARTNERSHIPS'})}</div>
						</div>
						<div className="my-auto w-full py-4 lg:pt-32 lg:pb-32">
							<div className="mx-auto w-80 xs:w-100 sm:w-144 md:w-192 lg:w-240">
								<a href="https://www.ankr.com" target="_blank" className="inline-block"><img src={Ankr} loading="lazy"  alt="" className=" w-16 sm:w-40 h-8 sm:h-16  m-2 sm:m-4 text-center transform hover:scale-110 motion-reduce:transform-none"  /></a>
								<a href="https://www.avax.network" target="_blank" className="inline-block"><img src={Avalanche} loading="lazy" alt="" className=" w-16 sm:w-40 h-8 sm:h-16  m-2 sm:m-4 text-center transform hover:scale-110 motion-reduce:transform-none" /></a>
								<a href="https://blockchain.usc.edu" target="_blank" className="inline-block"><img src={Blockchainusc} loading="lazy" alt="" className=" w-16 sm:w-40 h-8 sm:h-16  m-2 sm:m-4 text-center transform hover:scale-110 motion-reduce:transform-none" /></a>
								<a href="https://www.binance.org/en/" target="_blank" className="inline-block"><img src={BSC} loading="lazy"  alt="" className=" w-16 sm:w-40 h-8 sm:h-16  m-2 sm:m-4 text-center transform hover:scale-110 motion-reduce:transform-none" /></a>
								{/* <a href="https://conv.finance" target="_blank" ><img src={Convergence} loading="lazy"  alt="" className=" w-16 sm:w-40 h-8 sm:h-16  m-2.5 sm:m-4 text-center" /></a> */}
								<a href="https://dorahacks.com" target="_blank" className="inline-block"><img src={Dorahacks} loading="lazy"  alt="" className=" w-16 sm:w-40 h-8 sm:h-16  m-2 sm:m-4 text-center transform hover:scale-110 motion-reduce:transform-none" /></a>
								<a href="https://draper.vc" target="_blank" className="inline-block"><img src={Draper} loading="lazy"  alt="" className=" w-16 sm:w-40 h-8 sm:h-16  m-2 sm:m-4 text-center transform hover:scale-110 motion-reduce:transform-none" /></a>
								<a href="https://ens.domains" target="_blank" className="inline-block"><img src={Ens} loading="lazy"  alt="" className=" w-16 sm:w-40 h-8 sm:h-16  m-2 sm:m-4 text-center transform hover:scale-110 motion-reduce:transform-none" /></a>
								<a href="https://fantom.foundation" target="_blank" className="inline-block"><img src={Fantom} loading="lazy"className=" w-16 sm:w-40 h-8 sm:h-16  m-2 sm:m-4 text-center transform hover:scale-110 motion-reduce:transform-none"/></a>
								{/* <a href="https://en.fosun.com" target="_blank" ><img src={Fosun} loading="lazy"  alt="" className=" w-16 sm:w-40 h-8 sm:h-16  m-2.5 sm:m-4 text-center" /></a> */}
								<a href="https://token.im/?locale=zh-cn" target="_blank" className="inline-block"><img src={Imtoken} loading="lazy"  alt="" className=" w-16 sm:w-40 h-8 sm:h-16  m-2 sm:m-4 text-center transform hover:scale-110 motion-reduce:transform-none" /></a>
								<a href="https://magic.link" className="inline-block"><img src={Magic} loading="lazy"  alt="" className=" w-16 sm:w-40 h-8 sm:h-16  m-2 sm:m-4 text-center transform hover:scale-110 motion-reduce:transform-none" /></a>
								{/* <a href="https://mask.io" target="_blank"  ><img src={Mask} loading="lazy"  alt="" className=" w-16 sm:w-40 h-8 sm:h-16  m-2.5 sm:m-4 text-center" /></a> */}
								<a href="https://moonbeam.network" target="_blank" className="inline-block"><img src={Moonbeam} loading="lazy"  alt="" className=" w-16 sm:w-40 h-8 sm:h-16  m-2 sm:m-4 text-center transform hover:scale-110 motion-reduce:transform-none" /></a>
								<a href="https://polygon.technology" target="_blank" className="inline-block"><img src={Polygon} loading="lazy" alt="" className=" w-16 sm:w-40 h-8 sm:h-16  m-2 sm:m-4 text-center transform hover:scale-110 motion-reduce:transform-none" /></a>
								<a href="https://thegraph.com" target="_blank" className="inline-block"><img src={Thegraph} loading="lazy"  alt="" className=" w-16 sm:w-40 h-8 sm:h-16  m-2 sm:m-4 text-center transform hover:scale-110 motion-reduce:transform-none" /></a>	
								<a href="https://naos.finance/" target="_blank" className="inline-block"><img src={NaosFinance} loading="lazy"  alt="" className=" w-16 sm:w-40 h-8 sm:h-16  m-2 sm:m-4 text-center transform hover:scale-110 motion-reduce:transform-none" /></a>	
								<a href="https://app.tor.us/" target="_blank" className="inline-block"><img src={Torus} loading="lazy"  alt="" className=" w-16 sm:w-40 h-8 sm:h-16  m-2 sm:m-4 text-center transform hover:scale-110 motion-reduce:transform-none" /></a>	
								<a href="https://arcx.game/" target="_blank" className="inline-block"><img src={Arcx} loading="lazy"  alt="" className=" w-16 sm:w-40 h-8 sm:h-16  m-2 sm:m-4 text-center transform hover:scale-110 motion-reduce:transform-none" /></a>	
								<a href="https://deepdao.io/#/deepdao/dashboard" target="_blank" className="inline-block"><img src={DeepDAO} loading="lazy"  alt="" className=" w-16 sm:w-40 h-8 sm:h-16  m-2 sm:m-4 text-center transform hover:scale-110 motion-reduce:transform-none" /></a>	
								<a href="https://www.biconomy.io/" target="_blank" className="inline-block"><img src={Biconomy} loading="lazy"  alt="" className=" w-16 sm:w-40 h-8 sm:h-16  m-2 sm:m-4 text-center transform hover:scale-110 motion-reduce:transform-none" /></a>	
							</div>


							<div className="curve_2 "></div>
							<div className="flex flex-col bg-white">
								<div className="self-center text-center font-bold text-2xl md:text-3xl leading-tight lg:w-3/4 mx-8 lg:mx-0">{formatMessage({id: 'ETHSIGN_IS_LEGALLY_COMPLIANT'})}</div>
								<div className="map max-w-7xl mx-auto"><img src={Map} loading="lazy" alt="" className="select-none image-13"/></div>
							</div>

									<div className="curve up map"></div>
							<div className="py-5">

							
							</div>
							<div className="flex flex-col justify-left m-8 lg:my-0 max-w-7xl lg:mx-auto">
								<div className="sm:w-3/4 sm:p-4 md:p-12 lg:pt-24 lg:pb-6 lg:pl-24 lg:pr-12">
									<h1 className="font-bold text-2xl md:text-3xl leading-tight">{formatMessage({id: 'PIONEERING_THE_FUTURE'})}</h1>
									<p>{formatMessage({id: 'ETHSIGN_EMPOWERS_USERS_EVERY_STEP'})}</p>
								</div>
							
								

							</div>
							<Slider>
								{CARD_LIST.map(item => (
									<Slider.Item item={item} key={item.id}>item1</Slider.Item>
								))}
							</Slider>
						</div>
					</div>
				</>
			}
			{/* <div className="section-8 h-24 lg:h-80"></div> */}

			<div className="flex flex-col justify-center items-center bg-white">
				<h1 className="mt-8 sm:mt-12 text-2xl md:text-3xl">{formatMessage({id: 'CONTACT_US'})}</h1>
				<ContactUs/>
				<div className="flex justify-between w-3/4 md:w-2/3 lg:w-1/2 mt-8 md:mt-16 flex-col md:flex-row">
					<div className="w-full md:w-1/2">
						<div className="flex flex-col w-auto">
							<div className="mt-3 mb-1">
								<img src={logo} className="h-12 select-none" onDragStart={(e) => e.preventDefault()} />
							</div>
							<div className="mb-3 flex-shrink-0 md:pr-5">
								{formatMessage({id: 'EMAIL_US_OR_JOIN_DIGITAL_COMMUNITY'})}
							</div>
							<div className="flex justify-between md:justify-start ">
								<a href="https://twitter.com/ethsign?s=11" target="_blank"><img  src={Twitter} className="w-8 cursor-pointer select-none"/></a>
								<a href="https://www.linkedin.com/company/ethsign" target="_blank"><img src={Linkedin} className="w-8 cursor-pointer select-none mx-4"/></a>
								<a href="https://discord.gg/Wvhp9dWdSg" target="_blank"><img  src={Discord} className="w-8 cursor-pointer select-none"/></a>
								<a href="https://youtu.be/uVvl5GzFThI" target="_blank"><img  src={Youtube} className="w-8 cursor-pointer select-none mx-4"/></a>
								<a href="https://medium.com/ethsign" target="_blank"><img  src={Medium} className="w-8 cursor-pointer select-none"/></a>
							</div>
						</div>
					</div>
					<div className="w-full sm:w-3/4 mx-auto md:w-1/2 flex flex-row justify-between mt-12 mb-12 md:mb-6 md:mt-0">
						<div className="flex flex-col">
							<div className="my-3 font-bold text-2xl md:text-3xl">{formatMessage({id: 'LEARN'})}</div>
							<a href="https://ethsign-1.gitbook.io/ethsign/" target="_blank" className="my-3">{formatMessage({id: 'DOCS'})}</a>
							<a href="https://ethsign-1.gitbook.io/ethsign/for-users/getting-started-with-ethsign" target="_blank" className="my-3">{formatMessage({id: 'TUTORIALS'})}</a>
							<a href="https://drive.google.com/file/d/1KKlieyDaLbFlgYjkHP6ur-fvTCKhnVej/view?usp=sharing" target="_blank" className="my-3">{formatMessage({id: 'BRAND_ASSETS'})}</a>
						</div>
						<div className="flex flex-col mb-3 md:mb-8">
							<div className="my-3 font-bold text-2xl md:text-3xl">{formatMessage({id: 'PRODUCT'})}</div>
							<a href="https://www.youtube.com/channel/UCuDQ_UavU8Tg8wQJ_XrXW2Q" target="_blank" className="my-3">{formatMessage({id: 'DEMO_VIDEO'})}</a>
							<a href="https://ethsign-1.gitbook.io/ethsign/" target="_blank" className="my-3">{formatMessage({id: 'FAQS'})}</a>
							<a href="https://dygdc65xyp5.typeform.com/to/lC3Sns44" target="_blank" className="my-3">{formatMessage({id: 'TROUBLESHOOTING'})}</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);

}

export default withRouter(Landing);
