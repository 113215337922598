import React, { useEffect, useState } from 'react';
import UserDisplay from './UserDisplay';
import Identicon from 'react-identicons';
import OpenWalletIcon from '../../assets/open_wallet.svg';
import DollarIcon from '../../assets/Dollar.svg';
import LogoutIcon from '../../assets/logout.svg';
import {chains} from '../../helpers/chains';
import { FormattedMessage } from 'react-intl';
const UserMenu = (props) => {
    const { fm, torus, handleShowProfile, showProfile, ethAccount, logout, changeNetwork, provider, ethAlias, ethAvatar, handleOpen } = props;
    const [activeChain, handleActiveChain] = useState(null);

    useEffect(() => {
		let isSubscribed = true;
		(async () => {
            // if (provider){
                try {
                    handleActiveChain((await provider.getNetwork()).chainId)
                } catch(err) {
                    console.log(err);
                }
            // }
        })();
		return () => isSubscribed = false
	}, [provider]);

    return (
        <div className="flex sm:flex-1 justify-center select-none">
            <div className="ml-auto  my-auto relative">
                <div>
                    <div type="button" className="identicon hover:bg-gray-30 cursor-pointer border border-orange-500 flex rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-orange-500 focus:ring-white" id="user-menu-button" aria-expanded="false" aria-haspopup="true" onClick={(e) => { 
                        e.stopPropagation();
                        handleShowProfile(e, !showProfile) 
                    }}>
                        <span className="sr-only">Open user menu</span>
                        {ethAvatar ? <img className="w-11 h-11 rounded-full object-cover" src={ethAvatar} />
                        :
                        <div className="p-2">
                            <Identicon string={ethAccount} size="25" palette={['#D7EEFF', '#eef2ff', '#991A1A', '#FFDECC', '#E98234', '#D98234','#EE9F63', '#464648']} />
                        </div>
                        }
                    </div>
                </div>
                <div id="nav" className={`${showProfile == true ? 'block' : 'hidden'} text-gray-300 text-12 w-72 sm:text-15 sm:w-80 font-normal z-10 origin-top-right absolute right-0 mt-2  rounded-md shadow-xl pt-3 sm:pt-4 bg-white ring-1 ring-orange-500 ring-opacity-40 focus:outline-none`} role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">

                    <div className="px-4">
                        <UserDisplay ethAccount={ethAccount} ethAlias={ethAlias} provider={provider} handleOpen={handleOpen} handleShowProfile={handleShowProfile} />
                    </div>
                    {fm !== null && 
                    <div className="flex justify-center my-4">
                        <div className="flex cursor-pointer mx-auto px-3 py-1 rounded-md bg-orange-200 hover:bg-orange-500 text-white text-10 block px-4" role="menuitem" tabIndex="-1" id="user-menu-item-2" onClick={() => { fm.user.deposit(); }}>
                            <div>
                                <img src={DollarIcon} className="mr-2"/>
                            </div>
                            <div>
                                <FormattedMessage id='DEPOSIT'/>
                            </div>
                        </div> 
                    </div>
                    }
                    {torus !== null &&
                    <div className="flex justify-center my-4">
                        <div className="flex cursor-pointer ml-auto mr-2 px-3 py-1 rounded-md bg-orange-200 hover:bg-orange-500 text-white text-10 block px-4" role="menuitem" tabIndex="-1" id="user-menu-item-2" onClick={() => {
                            torus.showWallet("home");
                        }}>
                            <div>
                                <img src={OpenWalletIcon} className="mr-2"/>
                            </div>
                            <div>
                                <FormattedMessage id='OPEN_WALLET'/>
                            </div>
                        </div> 
                        <div className="flex cursor-pointer mr-auto ml-2 px-3 py-1 rounded-md bg-orange-200 hover:bg-orange-500 text-white text-10 block px-4" role="menuitem" tabIndex="-1" id="user-menu-item-2" onClick={() => {
                            torus.initiateTopup("moonpay", {selectedAddress: ethAccount?.toLowerCase()});
                        }}>
                            <div>
                                <img src={DollarIcon} className="mr-2"/>
                            </div>
                            <div>
                                <FormattedMessage id='DEPOSIT'/>
                            </div>
                        </div> 
                    </div>
                    }

                    {/* See note below about fortmatic supporting network switching. We may need to remove this check for fm == null in the future */}
                    {fm == null &&
                        <>
                            <div className="h-px bg-gray-50 mt-2"></div>
                            <div className="mt-4 mb-3 flex font-bold cursor-pointer mx-auto block justify-center" role="menuitem" tabIndex="-1" id="user-menu-item-2" onClick={() => { }}>
                                <FormattedMessage id="NETWORK" />
                            </div>
                        </>
                    }
                    {/* NOTE: The following code is for supporting network switching with fortmatic.
                        Unfortunately, it is not working properly at the time of writing. So, note to
                        future self: see PR #68 for more details. Uncomment this code if we support
                        fortmatic network switching and comment out the default "return null;" above.

                        if(!chain.fortmaticSupport) {
                            return null;
                    } */}
                    {fm == null && chains.map((chain, key)=>{
                        if(torus !== null && !chain.torusSupport) {
                            return null;
                        }
                        return (
                        <div key={key} className={`justify-center flex cursor-pointer mx-auto py-3 bg-active-hover ${activeChain==chain.chainId && 'bg-active'}`} role="menuitem" tabIndex="-1" onClick={() => { 
                                console.log("chain::", chain);
                                changeNetwork (chain) 
                            }}>
                            {activeChain==chain.chainId && 
                                <div className="flex-col justify-center mr-2 my-auto">
                                    <div className="w-2 h-2 rounded full bg-orange-200">
                                    </div>
                                </div>
                            }
                            <div>
                                {chain.name}
                            </div>
                        </div> 
                        )
                    })}
                    <div className="h-px bg-gray-50"></div>
                    <div className={`justify-center flex cursor-pointer mx-auto py-4 bg-active-hover`} role="menuitem" tabIndex="-1" onClick={() => { logout() }} >
                        
                        <div className="flex-col justify-center mr-2 my-auto">
                            <img src={LogoutIcon}/>
                        </div>
                        <div>
                            <FormattedMessage id='LOG_OUT'/>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    );
}
export default UserMenu;