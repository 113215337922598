/**
 * @author pzhu
 */
import React,{useState, useEffect} from 'react';

export default function TermsNavbar(props) {
    const {elements} = props;
    const [highlightPosition, handleHighlightPosition] = useState(0);
    const [elePosition, handleElePosition] = useState({})

    useEffect(()=>{
        parseTheElePosition();
        // get the first ele position
        handleHighlightPosition(parseInt(document.getElementById(elements[0])?.getBoundingClientRect().top));
    },[]);

    useEffect(()=>{
        const modalContent = document.getElementById("modal-content");
        function cb () {
           parseTheElePosition();
        }
        modalContent?.addEventListener("scroll", cb, false);
        return () => modalContent.removeEventListener("scroll", cb);
    },[]);

    const parseTheElePosition = () => {
        elements.forEach((id,index)=>{
            const ele = document.getElementById(id);
            elePosition[id] = {position: ele.getBoundingClientRect().top, hight: ele.offsetHeight}
            handleElePosition({...elePosition})
        })
    }

    const getSidebarStyle = (title) => {       
        const top = parseInt(elePosition[title]?.position);
        const buttom = parseInt(elePosition[title]?.hight + top);
        if (buttom>=highlightPosition && top<=highlightPosition) {
            return 'text-orange-500'
        }
    }

    return (
        <div className="mt-8 sm:w-2/5 sm:px-4  hidden sm:block">
                <div className="term-nav-h overflow-y-visible overflow-hidden ">
                {
                    elements && elements.map((ele,index)=>{
                        return (
                            <h5 
                                onClick={()=>{
                                    document.getElementById(ele).scrollIntoView();
                                }}
                                className={`${getSidebarStyle(ele)} text-10 cursor-pointer`}
                                key={index}
                            >
                                {ele}
                            </h5>
                        )
                    })
                }
        </div> 
        </div>
 
    )
}