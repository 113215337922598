import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router';
import getContract from '../helpers/client';
import Sign from './Sign'

const CreateAndSign = (props) => {
  const {newDocument, provider, allFieldsRequiredBeforeSave, bar, initialSigners, callback, errorCallback} = props
  const [credentials, handleCredentials] = useState(null);


  useEffect(() => {
		let isSubscribed = true;
		(async () => {
			const temp_credentials = await getContract({provider: provider})
      handleCredentials(temp_credentials)

		})();
		return () => isSubscribed = false
	}, []);

  if(!credentials){
    return null;
  }

  return(
      <Sign
        provider={provider}
        newDocument={newDocument}
        ethAccount={credentials.ethAccount}
        ethAlias={credentials.ethAlias}
        ethAvatar={credentials.ethAvatar}
        contract={credentials.contract}
        allFieldsRequiredBeforeSave={allFieldsRequiredBeforeSave}
        bar={bar}
        initialSigners={initialSigners}
        callback={callback}
        errorCallback={errorCallback}
      />
  )
  
}
export default withRouter(CreateAndSign);