import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from '../assets/logo_white_circle.png'
import '../fonts/Montserrat-SemiBold-bold.js';
import '../fonts/Montserrat-Bold-bold.js';
import '../fonts/Montserrat-normal.js';
import check from '../assets/orange-gray-check.png';
import moment from "moment";
export const exportVerification = (res) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const pdf = new jsPDF(orientation, unit, size);
    // Total page size is 595.28 x 841.89
    pdf.setFontSize(16);


    pdf.setFillColor(251,251,251);
    pdf.rect(0, 0, 595.28, 180, 'F');
    var img = new Image()
    img.src = logo;
    pdf.addImage(img, 'png', 72.16, 40, 52, 52, undefined, 'FAST');

    pdf.setTextColor('#F1924D');
    
    pdf.setFont('Montserrat', 'bold');
    pdf.text(340,70, 'Agreement Validation');

    pdf.setFont('Montserrat', 'semibold');
    pdf.setTextColor('#676262');
    pdf.setFontSize(12);
    pdf.text(419,120, 'Verification Date:');

    pdf.setFont('Montserrat', 'normal');
    pdf.text(415,140, moment().format('lll'));


    pdf.setTextColor('#676262');
    pdf.setFont('Montserrat', 'semibold');
    pdf.setFontSize(15);
    pdf.text(72.16,235, 'Signature');
    pdf.setTextColor('#F1924D');
    pdf.text(152,235, 'Valid');

    var img2 = new Image()
    img2.src = check;
    pdf.addImage(img2, 'png', 197, 222, 15, 15, undefined, 'FAST');


    pdf.setFontSize(10);
    pdf.setTextColor('#676262');
    pdf.setFont('Montserrat', 'semibold');
    pdf.text(72.16,285, 'Document Name:');
    pdf.text(72.16,325, 'Document Key:');
    pdf.text(72.16,382, 'Digitally Signed by:');
    pdf.text(72.16,422, 'Signature Applied Date:');

    pdf.setFont('Montserrat', 'normal');

    pdf.text(230,285, res.doc.name);
    const key = res.doc.key
    pdf.text(230,325, key.substring(0,47));
    pdf.text(230,342, key.substring(47,key.length));
    pdf.text(230,382, res.signerDetails.address);
    pdf.text(230,422, (moment.unix(res?.signerDetails.timestamp).format('lll')) );
    pdf.save(`verified_${moment().format('X')}.pdf`)
}