import React from 'react';
import ReactDOM from 'react-dom';
import {AppContainer, App} from './lib/components/App'
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import { Integrations as TracingIntegrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://9c62eb3839e14aa3aa412764c7c158a5@o951102.ingest.sentry.io/5901356",
  integrations: [new TracingIntegrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV === 'production' ? true : false
});



ReactDOM.render(
	<React.StrictMode>
    <AppContainer Application={App}/>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
