import React from 'react';
import '../../styles/loader.scss'

const Loader = (props) => {


  return (
    <div className="loader">
      <span><section></section></span>
      <span><div></div></span>
      <span><div></div></span>
      <span><div></div></span>
      <span><div></div></span>
    </div>
  )
};

export default Loader;