// Each networkinfo entry contains:
// Key: chainId
// Value: { name: String, blockSeconds: Number }
export const networkinfo = new Map([
  [1337, {
    name: 'localhost',
    blockSeconds: 1
  }],
  [1, {
    name: 'Ethereum Mainnet',
    blockSeconds: 13
  }],
  [3, {
    name: 'Ethereum Ropsten Testnet',
    blockSeconds: 15
  }],
  [56, {
    name: 'Binance Smart Chain Mainnet',
    blockSeconds: 3
  }],
  [43114, {
    name: 'Avalanche Mainnet',
    blockSeconds: 11
  }],
  [137, {
    name: 'Polygon Matic Mainne',
    blockSeconds: 2.4
  }],
  [250, {
    name: 'Fantom Opera',
    blockSeconds: 1.5
  }],
  [1313161554, {
    name: 'Aurora MainNet',
    blockSeconds: 1
  }],
  [128, {
    name: 'Huobi ECO Chain Mainnet',
    blockSeconds: 3
  }],
  [1284, {
    name: 'Moonbeam Polkadot',
    blockSeconds: 6
  }],
  [97, {
    name: 'Binance Smart Chain Testnet',
    blockSeconds: 3
  }]
]);