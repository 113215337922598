import { getChain } from './chains';

import Ethsign from '../artifacts/EthSign.json';
import { Biconomy } from '@biconomy/mexa'
import { ethers } from 'ethers';


const fortmatic_key = 'pk_live_6D2FFF6E41301BF0';
const fortmatic_test_key = 'pk_test_F4BDDED1F885923E';


export default async function getContract({fortmatic, torus, provider}) {
    //TODO: if network is not supported, throw error
  if (!provider){
    return;
  }
  
    let biconomy = null;
    //set innerNetwork to chainId of provider
    let innerNetwork = provider.getNetwork().chainId;
    let _provider;
  
  //check first if network is compatible
  
      // Init biconomy/provider/other instance variables
      if (fortmatic) {
          if(getChain(innerNetwork).biconomySupport) {
              let originalProvider = new ethers.providers.Web3Provider(fortmatic.getProvider());
              biconomy = new Biconomy(originalProvider, { apiKey: getChain(innerNetwork).biconomyKey, strictMode: false });
              _provider = new ethers.providers.Web3Provider(biconomy);
          } else {
              _provider = new ethers.providers.Web3Provider(fortmatic.getProvider());
          }
      } else if(torus) {
          if(getChain(innerNetwork).biconomySupport) {
              let originalProvider = new ethers.providers.Web3Provider(torus.ethereum);
              biconomy = new Biconomy(originalProvider, { apiKey: getChain(innerNetwork).biconomyKey, strictMode: false });
              _provider = new ethers.providers.Web3Provider(biconomy);
          } else {
              _provider = new ethers.providers.Web3Provider(torus.ethereum);
          }
      } else if (window.ethereum) {
          if(getChain(await window.ethereum.request({ method: 'eth_chainId' })).biconomySupport) {
              let originalProvider = new ethers.providers.Web3Provider(window.ethereum);
              biconomy = new Biconomy(originalProvider, { apiKey: getChain(await window.ethereum.request({ method: 'eth_chainId' })).biconomyKey, strictMode: false });
              _provider = new ethers.providers.Web3Provider(biconomy);
          } else {
              _provider = new ethers.providers.Web3Provider(window.ethereum);
          }
      } 
      const signer =  _provider.getSigner(); // signer == current logged in user
      const account = await signer.getAddress();
      const networkId = (await _provider.getNetwork()).chainId;
      const deployedNetwork = Ethsign.networks[networkId];
      let contract = null;
      if (biconomy == null) {
          contract = new ethers.Contract(deployedNetwork.address, Ethsign.abi, _provider);
          console.log("EthSign: Initialized normally.")
      } else {
          await new Promise((resolve, reject) => {
              biconomy.onEvent(biconomy.READY, async () => {
                  contract = new ethers.Contract(
                      deployedNetwork.address,
                      Ethsign.abi,
                      biconomy.getSignerByAddress(account)
                  );
                  console.log("EthSign: Initialized with Mexa.")
                  resolve();
              }).onEvent(biconomy.ERROR, (error, message) => {
                  console.log("EthSign: Failed to initialize with Mexa.")
                  console.log(message);
                  console.log(error);
                  reject();
              });
          });
      }
      let alias, resolver, avatar;
      alias = await provider.lookupAddress(account);
      if(alias) {
        resolver = await provider.getResolver(alias);
        avatar = await resolver.getText("avatar");
      }



      return ({
        provider: _provider,
        fortmatic: fortmatic,
        torus: torus,
        ethAccount: account?.toLowerCase(),
        ethAlias: alias,
        ethAvatar: avatar,
        networkId: networkId,
        contract: contract,
      })
    
  }