import React from 'react';
import cx from 'classnames';
import SliderContext from './context'
import '../../styles/Slider/Item.scss'

const Item = ({ item }) => (
  <SliderContext.Consumer>
    {({ onSelectSlide, currentSlide, elementRef}) => {
      const {color, content, personName, id, position, img} = item

      const isActive = currentSlide && currentSlide.id === id;
      return (
        <div
          ref={elementRef}
          className={cx('item flex', {
            'item--open': isActive,
          })}
        >
          <div className="flex flex-col justify-between mx-5 bg-white rounded-2xl">
            <div className="flex p-5 lg:p-8 items-center justify-end ">
              <img src={img} loading="lazy" alt="" className="w-32 h-12 select-none"/>
            </div>
            
            <p className="p-5 lg:p-8 mt-0">{content}</p>
            <div className="p-5 lg:p-8">
              <p className="font-bold">{personName}</p>
              <p>{position}</p>
            </div>
      
          </div>
            
        </div>
      );
    }}
  </SliderContext.Consumer>
);

export default Item;
