import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import { useIntl } from 'react-intl';
const TitleBar = (props) => {

  const { formatMessage } = useIntl();
  const {newChanges, bar, newDocument, doc, saving, fieldChanges, allFieldsRequiredBeforeSave, allFieldsFilled } = props;
  return(
    <nav className={`${newDocument? 'box-shadow-nav-top':'box-shadow-nav'} z-10 bg-gray-40`}>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="flex w-full">
            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-between">
              {!newDocument &&
                <Link className="mr-auto flex-shrink-0 flex items-center" to="/">
                  <img className="h-12 mt-3 z-10 w-auto" src={logo} alt="Ethsign" />
                </Link>
              }
            </div>
            <div className="flex flex-grow justify-center items-center text-gray-80 text-20 font-medium" >
              { doc?.name }
            </div>
            <div className="flex flex-1 justify-end">
              <div className="w-auto h-auto flex my-auto text-white">
               
                  <button onClick={()=>{bar.button1Action();}} className=" font-bold mr-5 w-20 py-1 bg-gray-300 hover:bg-gray-600 rounded-md">
                    {bar.button1}
                  </button>
                  <button onClick={() => {bar.button2Action()}} className={`font-bold py-1 ${!saving && (newChanges || fieldChanges) && ( (allFieldsRequiredBeforeSave && allFieldsFilled) || !allFieldsRequiredBeforeSave) ? 'bg-orange-500 hover:bg-orange-600': 'bg-orange-300 cursor-not-allowed'} w-20 rounded-md`}>
                    { bar.button2 }
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
export default withRouter(TitleBar);