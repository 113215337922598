/**
 * @author pzhu
 * @description Privacy Policy content
 */
import React from "react";
import TermsHoc from "./TermsHoc";
import TermsNavbar from "./TermsNavbar";
import { Link } from 'react-router-dom';


const PARTIES = "1. PARTIES";
const OVERVIEW = "2. OVERVIEW";
const COLLECTION_OF_PERSONAL_DATA = "3. COLLECTION OF PERSONAL DATA";
const COLLECTION_OF_COOKIES = "4. COLLECTION OF COOKIES";
const USE_OF_PERSONAL_DATA = "5. USE OF PERSONAL DATA";
const DISCLOSURE_OF_PERSONAL_DATA = "6. DISCLOSURE OF PERSONAL DATA";
const ACCURACY_AND_UPDATING_OF_PERSONAL_DATA = "7. ACCURACY AND UPDATING OF PERSONAL DATA";
const ACCESS_TO_PERSONAL_DATA_AND_RESPECTING_INDIVIDUAL_CONSENT = "8. ACCESS TO PERSONAL DATA AND RESPECTING INDIVIDUAL’S CONSENT";
const SECURITY_OF_PERSONAL_DATA = "9. SECURITY OF PERSONAL DATA";
const RETENTION_OF_PERSONAL_DATA = "10. RETENTION OF PERSONAL DATA";
const DISCLOSURE_OF_PERSONAL_INFORMATION_POLICY_AND_PROCEDURE_OF_MAKING_A_COMPLAINT = "11.DISCLOSURE OF PERSONAL INFORMATION POLICY AND PROCEDURE OF MAKING A COMPLAINT";
const HOW_TO_CONTACT_US = "12. HOW TO CONTACT US";
const MODIFICATIONS_TO_THE_PRIVACY_POLICY = "13. MODIFICATIONS TO THE PRIVACY POLICY";
const GOVERNING_LAW = "14. GOVERNING LAW";

export const PRIVACY_POLICY_ELE = [PARTIES, OVERVIEW, COLLECTION_OF_PERSONAL_DATA, COLLECTION_OF_COOKIES, USE_OF_PERSONAL_DATA, DISCLOSURE_OF_PERSONAL_DATA, ACCURACY_AND_UPDATING_OF_PERSONAL_DATA, ACCESS_TO_PERSONAL_DATA_AND_RESPECTING_INDIVIDUAL_CONSENT, SECURITY_OF_PERSONAL_DATA, RETENTION_OF_PERSONAL_DATA, DISCLOSURE_OF_PERSONAL_INFORMATION_POLICY_AND_PROCEDURE_OF_MAKING_A_COMPLAINT, HOW_TO_CONTACT_US, MODIFICATIONS_TO_THE_PRIVACY_POLICY, GOVERNING_LAW];

const PrivacyPolicy = () => {
    return (
        <>
           <TermsNavbar elements={PRIVACY_POLICY_ELE} />
           <div id= "modal-content" className="text-8 mt-8 sm:w-3/5  px-4 modal-content sm:text-xs sm:pr-12" >
                <h4 className="m-0 text-13 sm:text-2xl">EthSign Privacy Policy</h4>
                <div id={PARTIES}>
                    <h4 className="text-10 sm:text-sm">{PARTIES}</h4>
                    <p>1.1	Buildblock Tech Pte. Ltd. (hereinafter referred to as the “<span className='font-bold'>Company</span>”, and references herein to “<span className='font-bold'>we</span>”, “<span className='font-bold'>our</span>” and “<span className='font-bold'>us</span>” are to the Company) is a company incorporated in Singapore, and operates the website <Link className='underline text-blue-200' to="/">ethsign.xyz</Link> (hereinafter referred to as “<span className='font-bold'>Website</span>” or “<span className='font-bold'>Platform</span>”), which is a platform dedicated to the decentralized, versioned and consensus-based electronic agreements signing application worldwide built on Web 3.0 technologies and the provision of related services (hereinafter referred to as the “<span className='font-bold'>Services</span>”'). </p>
                    <p>1.2	Although the content on this Website is available in multiple languages for the convenience of the Users, in the event of inconsistency, the English content shall prevail to the extent of the inconsistency only.</p>
                </div>
                <div id={OVERVIEW}>
                    <h4  className="text-10 sm:text-sm">{OVERVIEW}</h4>
                    <p>2.1	We are committed to ensuring the safety and security of personal data. The purpose of this Privacy Policy (“<span className='font-bold'>Privacy Policy</span>”) is to inform you as to how we manage, collect, use and disclose personal data. In Singapore, such business activities are subject to the Personal Data Protection Act (No. 26 of 2012) (the “<span className='font-bold'>PDPA</span>”). We conduct our business in compliance with the PDPA and have implemented various measures to ensure that any personal data (as defined below) remains safe and secure.</p>
                    <p>2.2	Unless otherwise defined, capitalised terms used in this Privacy Policy shall bear the same meanings ascribed to them in our Terms of Use.</p>
                </div>
                <div id={COLLECTION_OF_PERSONAL_DATA}>
                    <h4  className="text-10 sm:text-sm">{COLLECTION_OF_PERSONAL_DATA}</h4>
                    <p>3.1	In this Privacy Policy, “<span className='font-bold'>Personal Data</span>” refers to any data, whether true or not, about an individual who can be identified from that data, or from that data and other information to which we have or are likely to have access, including data in our records as may be updated from time to time.</p>
                    <p>3.2	The Personal Data that we collect may include the following:</p>
                    <p>(a)	an individual’s personal information (this includes but shall not be limited to your name, email address, Internet Protocol address, phone number, date of birth, residential address, identification number);</p>
                    <p>(b)	Personal Data necessary for us to provide our services (this shall include, but not be limited to your phone number, fax number, email address, mailing address, and information concerning your debit card and/or other accounts); and</p>
                    <p>(c)	any other Personal Data that may be necessary for us to comply with applicable legal, regulatory and other compliance requirements.</p>
                    <p>3.3	Typically, we will collect Personal Data from any user accessing the Website, business contacts, business partners, companies that we may invest in or any other third parties that we work with or from publicly available sources.</p>
                </div>
                <div id={COLLECTION_OF_COOKIES}>
                    <h4  className="text-10 sm:text-sm">{COLLECTION_OF_COOKIES}</h4>
                    <p>4.1	When you visit our Website, please note that we will use Google stats via cookies to record our performance and check the effectiveness of online advertising. Cookies are a small amount of data that is sent to your browser and stored on your computer hard drive. Only when you use your computer to access our Website can the cookies be sent to your computer hard drive.</p>
                    <p>4.2	Cookies are usually used to record the habits and preferences of visitors in browsing the items on our Website. The information collected by cookies is non-registered and collective statistical data and does not involve personal data.</p>
                    <p>4.3 	Cookies, which enable the Website or service provider system to recognize your browser and capture and recall information, cannot be used to obtain data on your hard drive, your email address, or your personal data. Most browsers are designed to accept cookies. You can opt to set your browser to reject cookies, or to notify you as soon as possible if you are loaded on cookies. However, please note that if you set your browser to disable cookies, it is possible that you may not be able to launch or use some functions of our Website.</p>
                </div>
                <div id={USE_OF_PERSONAL_DATA}>
                    <h4  className="text-10 sm:text-sm">{USE_OF_PERSONAL_DATA}</h4>
                    <p>5.1	We may use Personal Data for:</p>
                    <p>(a)	providing our services;</p>
                    <p>(b)	internal audits;</p>
                    <p>(c)	security and risk management;</p>
                    <p>(e)	legal, regulatory and other compliance requirements (including providing assistance to law enforcement, judicial, regulatory or other government agencies and statutory bodies); and</p>
                    <p>(f)	other work, business and marketing and promotion related activities.</p>
                    <p>5.2	We will not use Personal Data for purposes which we are not permitted to under applicable laws and regulations.</p>
                </div>
                <div id={DISCLOSURE_OF_PERSONAL_DATA}>
                    <h4  className="text-10 sm:text-sm" >{DISCLOSURE_OF_PERSONAL_DATA}</h4>
                    <p>6.1	We may share and disclose Personal Data with:</p>
                    <p>(a)	our partners and suppliers in connection with the provision of services to our customers;</p>
                    <p>(b)	partners, licensors, vendors, agents, contractors or third party service providers who provide services to us including but not limited to insurance, consultancy, courier services, telecommunications, IT, payment, printing, billing, payroll processing, technical services, training, market research, call centre, security or other such services to us;</p>
                    <p>(c)	in the event of an actual or prospective business asset transaction (such as any merger, acquisition or asset sale), any business partner, investor, assignee, or transferee for the purposes of facilitating such a transaction; and/or</p>
                    <p>(d)	any relevant government regulators, statutory boards or authorities or law enforcement agencies as required by any laws, rules, guidelines and regulations or schemes imposed by any government to bodies and authorities.</p>
                    <p>6.2	Personal Data is disclosed to the above only for relevant purposes (please refer to clause 5 of this Privacy Policy) or to protect the individual’s interests.</p>
                    <p>6.3	In exceptional circumstances, we may also be required to disclose Personal Data, where there are grounds to believe that disclosure is necessary to prevent a threat to life or health, or for law enforcement purposes.</p>
                    <p>6.4	In some cases, we shall encrypt, anonymize, and aggregate the information before sharing it. Anonymizing means stripping the information of personally identifiable features. Aggregating means presenting the information in groups or segments e.g. age groups.</p>
                    <p>6.5	We will also ensure that overseas organisations we work with observe strict confidentiality and data protection obligations, and shall ensure that any personal data transferred overseas will be made in accordance with the PDPA.</p>
                </div>
                <div id={ACCURACY_AND_UPDATING_OF_PERSONAL_DATA}>
                    <h4  className="text-10 sm:text-sm">{ACCURACY_AND_UPDATING_OF_PERSONAL_DATA}</h4>
                    <p>7.1	We will strive to keep your Personal Data accurate. You will be given the ability to review and update your Personal Data that we have in our possession.</p>
                    <p>7.2	You should ensure that all Personal Data submitted to us is complete, accurate, true and correct. Further, when you provide us with any Personal Data relating to a third party (including your spouse, children, parents and/or employees), you represent and warrant to us that you have obtained the consent of the third party to provide us with their Personal Data unless otherwise provided in the PDPA.</p>
                    <p>7.3	If there is any Personal Data relating to you that you are unable to update and which you wish to make corrections to, you may contact our Data Protection Officer (whose contact is set out below) and we will be happy to help you as best as we can.</p>
                </div>
                <div id={ACCESS_TO_PERSONAL_DATA_AND_RESPECTING_INDIVIDUAL_CONSENT}>
                    <h4  className="text-10 sm:text-sm">{ACCESS_TO_PERSONAL_DATA_AND_RESPECTING_INDIVIDUAL_CONSENT}</h4>
                    <p>8.1	If you wish to access the Personal Data that we have relating to you, inquire about the way in which Personal Data relating to you has been used or disclosed by us in the past year, or wish to withdraw your consent to our use of such Personal Data, you may contact our Data Protection Officer (whose contact is set out below) and we will seek to attend to your request as best as we reasonably can. Please note that:</p>
                    <p>(a)	In order for us to provide any personal data we will need to verify your identity and may request further information about your request.</p>
                    <p>(b)	We may refuse access to your Personal Data if it would affect the privacy rights of other persons or if it breaches any confidentiality that attaches to that information.</p>
                    <p>(c)	We may also refuse your request where we are legally permitted to do so and give you such reasons.</p>
                    <p>(d)	You should be aware that we may take a reasonable time to process your application for access as we may need to retrieve information from storage and review the information in order to determine what information may be provided.</p>
                    <p>(e)	Kindly note that we may have to charge you a reasonable administrative fee for retrieving Personal Data relating to you.</p>
                </div>
                <div id={SECURITY_OF_PERSONAL_DATA}>
                    <h4  className="text-10 sm:text-sm">{SECURITY_OF_PERSONAL_DATA}</h4>
                    <p>9.1	Safeguarding and respecting the confidentiality of Personal Data is important to Inside Works. We will use our best efforts to protect Personal Data.</p>
                    <p>9.2	The security measures that we may take to Personal Data include but are not limited to the following measures:</p>
                    <p>(a)	Physical measures: Physical records of your personal data will be stored in a properly locked place.</p>
                    <p>(b)	Electronic measures: Computer data which contain your personal information will be stored in computer systems and storage medias subject to strict login restrictions.</p>
                    <p>(c)	Management measures: only staff members duly authorized by us can access your personal data, and these staff members shall comply with our internal code concerning personal data confidentiality.</p>
                    <p>(d)	Technical measures: such encryption techniques as Secure Socket Layer Encryption may be used to convey your personal data.</p>
                    <p>(e)	Other measures: our network servers are protected by proper firewall(s).</p>
                    <p>9.3	Notwithstanding the above, please note that we will not be held liable or responsible for any loss, misuse or alteration of Personal Data that may be caused by third parties.  </p>
                </div>
                <div id={RETENTION_OF_PERSONAL_DATA}>
                    <h4  className="text-10 sm:text-sm">{RETENTION_OF_PERSONAL_DATA}</h4>
                    <p>We will only retain Personal Data for only as long as there is a business or legal need. In the event that retention of Personal Data is no longer necessary for any business or legal purposes or when the purpose for which the Personal Data was collected is no longer being served by the retention of the Personal Data, we will remove, destroy or anonymise the Personal Data.</p>
                </div>
                <div id={DISCLOSURE_OF_PERSONAL_INFORMATION_POLICY_AND_PROCEDURE_OF_MAKING_A_COMPLAINT}>
                    <h4  className="text-10 sm:text-sm">{DISCLOSURE_OF_PERSONAL_INFORMATION_POLICY_AND_PROCEDURE_OF_MAKING_A_COMPLAINT}</h4>
                    <p>11.1	If you believe that we have breached this Privacy Policy, or any other applicable privacy or data protection laws or regulations which may apply to us, you should make a complaint to us in the first instance. You should address your complaint in writing to our Data Protection Officer (whose contact is set out below), and you should include as much detail as you can about the Personal Data affected, and the circumstances that you believe amount to a breach of this Privacy Policy or the PDPA.</p>
                    <p>11.2	If you have any questions about our Privacy Policy or concerns about our commitment to your privacy, please feel free to email or write to the Data Protection Officer (whose contact details are set out below). </p>
                </div>
                <div id={HOW_TO_CONTACT_US}>
                    <h4  className="text-10 sm:text-sm">{HOW_TO_CONTACT_US}</h4>
                    <p>Please feel free to contact our Data Protection Officer at <span className='font-bold'>info@ethsign.xyz</span>.</p>
                </div>
                <div id={MODIFICATIONS_TO_THE_PRIVACY_POLICY}>
                    <h4  className="text-10 sm:text-sm">{MODIFICATIONS_TO_THE_PRIVACY_POLICY}</h4>
                    <p>We reserve the right to modify and update this Privacy Policy at any time to ensure it is consistent with industry trends and/or any changes in legal or regulatory requirements.</p>
                </div>
                <div id={GOVERNING_LAW}>
                    <h4  className="text-10 sm:text-sm">{GOVERNING_LAW}</h4>
                    <p>This Privacy Policy shall be governed in all respects by the laws of Singapore and the User hereby agrees to submit to the exclusive jurisdiction of the courts of Singapore to resolve any claims or disputes which may arise in connection with this Privacy Policy.</p>
                </div>
                <div style={{height:"40rem"}}/>

           </div>
        </>
    )
}

export default TermsHoc(PrivacyPolicy);