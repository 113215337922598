import React, { useEffect, useState } from 'react';
import PageNotFound from '../assets/page_not_found.svg';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

export default function NotFoundComponent(props) {
    const { handleActivePage } = props;
    const { formatMessage } = useIntl();
    const [shouldDisplay, handleShouldDisplay] = useState(false);

    const history = useHistory();

    const goBack = () => {
        history.goBack();
    }

    useEffect(() => {
        if(handleActivePage) {
            let listOfValidPathnames = "/home /create /contracts /history /verify /sign /signin /login /upload /manage-profile /analytics"; // /datavis /datavis/login
            if(!(_.includes(listOfValidPathnames, history.location.pathname))) {
                console.log(history.location.pathname)
                handleShouldDisplay(true);
            } else {
                handleShouldDisplay(false);
            }
        }
    }, [history.location.pathname])

    return (
        <>
            {shouldDisplay &&
                <div className="overflow-hidden fixed z-999 inset-0 bg-white">
                    <div className="h-screen flex  flex-col justify-center items-center">
                        <img src={PageNotFound} alt=""/>
                        <div className="flex flex-col items-center justify-center">
                            <span className="sm:text-30 font-bold text-orange-500"><FormattedMessage id='UMM_CANT_REACH_THIS_PAGE'/></span>
                            <span className="sm:text-25 text-center"><FormattedMessage id='REQUEST_URL_INVALID_CLICK_TO'/><button className="outline-none underline text-orange-500 hover:text-orange-600" onClick={() => goBack()}>{formatMessage({id: 'RETURN_TO_PREVIOUS_PAGE'})}</button></span>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}