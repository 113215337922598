import { fetchChainData, ENDPOINT_PREFIX, network, API_VERSION} from './graphql';
import { CrosshairMode } from 'lightweight-charts';

/**
 * GraphQL queries
 * NOTE: We can use id_gt to provide a starting id value.
 **/
export const getUsersPerDayQuery = (epoch, first = 1000) => {
  return `
    {
      usersPerDays(where: { id_lt: "${epoch}" }, first: ${first}) {
        id
        number
      }
    }
  `
}

export const getSignaturesPerDayQuery = (epoch, first = 1000) => {
  return `
    {
      signaturesPerDays(where: { id_lt: "${epoch}" }, first: ${first}) {
        id
        number
      }
    }
  `
}

/**
 *  Helper methods
 **/

export function getCSVHeaders() {
  return [
    { label: "Network", key: "network" },
    { label: "Signatures Signed", key: "signatureCount" },
    { label: "Address Count", key: "addressCount" },
    { label: "", key: "blank" },
    { label: "Date", key: "date" },
    { label: "", key: "blank" },
    { label: "Signature Count (ethereum)", key: "data.signaturesPerDay.ethereum.number" },
    { label: "Signature Count (ropsten)", key: "data.signaturesPerDay.ropsten.number" },
    { label: "Signature Count (bsc)", key: "data.signaturesPerDay.bsc.number" },
    { label: "Signature Count (avalanche)", key: "data.signaturesPerDay.avalanche.number" },
    { label: "Signature Count (polygon)", key: "data.signaturesPerDay.polygon.number" },
    { label: "Signature Count (fantom)", key: "data.signaturesPerDay.fantom.number" },
    { label: "", key: "blank" },
    { label: "Aggregate Signature Count (ethereum)", key: "data.signaturesPerDayAgg.ethereum.number" },
    { label: "Aggregate Signature Count (ropsten)", key: "data.signaturesPerDayAgg.ropsten.number" },
    { label: "Aggregate Signature Count (bsc)", key: "data.signaturesPerDayAgg.bsc.number" },
    { label: "Aggregate Signature Count (avalanche)", key: "data.signaturesPerDayAgg.avalanche.number" },
    { label: "Aggregate Signature Count (polygon)", key: "data.signaturesPerDayAgg.polygon.number" },
    { label: "Aggregate Signature Count (fantom)", key: "data.signaturesPerDayAgg.fantom.number" },
    { label: "", key: "blank" },
    { label: "Address Count (ethereum)", key: "data.usersPerDay.ethereum.number" },
    { label: "Address Count (ropsten)", key: "data.usersPerDay.ropsten.number" },
    { label: "Address Count (bsc)", key: "data.usersPerDay.bsc.number" },
    { label: "Address Count (avalanche)", key: "data.usersPerDay.avalanche.number" },
    { label: "Address Count (polygon)", key: "data.usersPerDay.polygon.number" },
    { label: "Address Count (fantom)", key: "data.usersPerDay.fantom.number" },
    { label: "", key: "blank" },
    { label: "Aggregate Address Count (ethereum)", key: "data.usersPerDayAgg.ethereum.number" },
    { label: "Aggregate Address Count (ropsten)", key: "data.usersPerDayAgg.ropsten.number" },
    { label: "Aggregate Address Count (bsc)", key: "data.usersPerDayAgg.bsc.number" },
    { label: "Aggregate Address Count (avalanche)", key: "data.usersPerDayAgg.avalanche.number" },
    { label: "Aggregate Address Count (polygon)", key: "data.usersPerDayAgg.polygon.number" },
    { label: "Aggregate Address Count (fantom)", key: "data.usersPerDayAgg.fantom.number" },
  ];
}

export function getCSVData(obj) {
  const csv = [];

  const totals = [];
  for(let idx in network) {
    totals[idx] = {
      network: network[idx],
      addressCount: obj.usersPerDayAgg[network[idx]][obj.usersPerDayAgg[network[idx]].length - 1].number,
      signatureCount: obj.signaturesPerDayAgg[network[idx]][obj.signaturesPerDayAgg[network[idx]].length - 1].number,
    }
  }

  // Loop through all of the epoch days
  for(let idx in obj.usersPerDay.ethereum) {
    csv.push({
      network: totals[idx] ? totals[idx].network : "",
      addressCount: totals[idx] ? totals[idx].addressCount : "",
      signatureCount: totals[idx] ? totals[idx].signatureCount : "",
      blank: "",
      date: unixEpochToDate(obj.usersPerDay.ethereum[idx].id),
      data: {
        signaturesPerDay: {
          ethereum: {
            number: obj.signaturesPerDay.ethereum[idx].number,
          },
          ropsten: {
            number: obj.signaturesPerDay.ropsten[idx].number,
          },
          bsc: {
            number: obj.signaturesPerDay.bsc[idx].number,
          },
          avalanche: {
            number: obj.signaturesPerDay.avalanche[idx].number,
          },
          polygon: {
            number: obj.signaturesPerDay.polygon[idx].number,
          },
          fantom: {
            number: obj.signaturesPerDay.fantom[idx].number,
          },
        },
        signaturesPerDayAgg: {
          ethereum: {
            number: obj.signaturesPerDayAgg.ethereum[idx].number,
          },
          ropsten: {
            number: obj.signaturesPerDayAgg.ropsten[idx].number,
          },
          bsc: {
            number: obj.signaturesPerDayAgg.bsc[idx].number,
          },
          avalanche: {
            number: obj.signaturesPerDayAgg.avalanche[idx].number,
          },
          polygon: {
            number: obj.signaturesPerDayAgg.polygon[idx].number,
          },
          fantom: {
            number: obj.signaturesPerDayAgg.fantom[idx].number,
          },
        },
        usersPerDay: {
          ethereum: {
            number: obj.usersPerDay.ethereum[idx].number,
          },
          ropsten: {
            number: obj.usersPerDay.ropsten[idx].number,
          },
          bsc: {
            number: obj.usersPerDay.bsc[idx].number,
          },
          avalanche: {
            number: obj.usersPerDay.avalanche[idx].number,
          },
          polygon: {
            number: obj.usersPerDay.polygon[idx].number,
          },
          fantom: {
            number: obj.usersPerDay.fantom[idx].number,
          },
        },
        usersPerDayAgg: {
          ethereum: {
            number: obj.usersPerDayAgg.ethereum[idx].number,
          },
          ropsten: {
            number: obj.usersPerDayAgg.ropsten[idx].number,
          },
          bsc: {
            number: obj.usersPerDayAgg.bsc[idx].number,
          },
          avalanche: {
            number: obj.usersPerDayAgg.avalanche[idx].number,
          },
          polygon: {
            number: obj.usersPerDayAgg.polygon[idx].number,
          },
          fantom: {
            number: obj.usersPerDayAgg.fantom[idx].number,
          },
        },
      },
    })
  }

  return csv;
}

export function getTotalsPerChain(obj) {
  return [
    {x: "Ethereum", key: "Total Users", "Total Users": obj.usersPerDayAgg.ethereum[obj.usersPerDayAgg.ethereum.length - 1].number, y: obj.usersPerDayAgg.ethereum[obj.usersPerDayAgg.ethereum.length - 1].number},
    {x: 'Ethereum', key: "Total Signatures", "Total Signatures": obj.signaturesPerDayAgg.ethereum[obj.signaturesPerDayAgg.ethereum.length - 1].number, y: obj.signaturesPerDayAgg.ethereum[obj.signaturesPerDayAgg.ethereum.length - 1].number},
    {x: 'Ropsten', key: 'Total Users', 'Total Users': obj.usersPerDayAgg.ropsten[obj.usersPerDayAgg.ropsten.length - 1].number, y: obj.usersPerDayAgg.ropsten[obj.usersPerDayAgg.ropsten.length - 1].number},
    {x: 'Ropsten', key: 'Total Signatures', 'Total Signatures': obj.signaturesPerDayAgg.ropsten[obj.signaturesPerDayAgg.ropsten.length - 1].number, y: obj.signaturesPerDayAgg.ropsten[obj.signaturesPerDayAgg.ropsten.length - 1].number},
    {x: 'BSC', key: 'Total Users', 'Total Users': obj.usersPerDayAgg.bsc[obj.usersPerDayAgg.bsc.length - 1].number, y: obj.usersPerDayAgg.bsc[obj.usersPerDayAgg.bsc.length - 1].number},
    {x: 'BSC', key: 'Total Signatures', 'Total Signatures': obj.signaturesPerDayAgg.bsc[obj.signaturesPerDayAgg.bsc.length - 1].number, y: obj.signaturesPerDayAgg.bsc[obj.signaturesPerDayAgg.bsc.length - 1].number},
    {x: 'Avalanche', key: 'Total Users', 'Total Users': obj.usersPerDayAgg.avalanche[obj.usersPerDayAgg.avalanche.length - 1].number, y: obj.usersPerDayAgg.avalanche[obj.usersPerDayAgg.avalanche.length - 1].number},
    {x: 'Avalanche', key: 'Total Signatures', 'Total Signatures': obj.signaturesPerDayAgg.avalanche[obj.signaturesPerDayAgg.avalanche.length - 1].number, y: obj.signaturesPerDayAgg.avalanche[obj.signaturesPerDayAgg.avalanche.length - 1].number},
    {x: 'Polygon', key: 'Total Users', 'Total Users': obj.usersPerDayAgg.polygon[obj.usersPerDayAgg.polygon.length - 1].number, y: obj.usersPerDayAgg.polygon[obj.usersPerDayAgg.polygon.length - 1].number},
    {x: 'Polygon', key: 'Total Signatures', 'Total Signatures': obj.signaturesPerDayAgg.polygon[obj.signaturesPerDayAgg.polygon.length - 1].number, y: obj.signaturesPerDayAgg.polygon[obj.signaturesPerDayAgg.polygon.length - 1].number},
    {x: 'Fantom', key: 'Total Users', 'Total Users': obj.usersPerDayAgg.fantom[obj.usersPerDayAgg.fantom.length - 1].number, y: obj.usersPerDayAgg.fantom[obj.usersPerDayAgg.fantom.length - 1].number},
    {x: 'Fantom', key: 'Total Signatures', 'Total Signatures': obj.signaturesPerDayAgg.fantom[obj.signaturesPerDayAgg.fantom.length - 1].number, y: obj.signaturesPerDayAgg.fantom[obj.signaturesPerDayAgg.fantom.length - 1].number},
  ];
}

export function getChartOptions(width, height) {
  return {
    width: width,
    height: height,
    layout: {
      backgroundColor: '#f4f4f4',
      borderColor: 'rgb(70, 70, 72)',
      textColor: 'rgb(70, 70, 72)'
    },
    grid: {
      vertLines: {
        color: '#C4C4C4'
      },
      horzLines: {
        color: '#C4C4C4'
      }
    },
    crosshair: {
      mode: CrosshairMode.Magnet
    },
    leftPriceScale: {
      visible: true,
      borderColor: 'rgb(70, 70, 72)'
    },
    rightPriceScale: {
      visible: false
    },
    timeScale: {
      borderColor: 'rgb(70, 70, 72)',
    },
  };
}

// This will return the number of days since the unix start time
export function getUnixEpochDays() {
  let now = new Date();
  let fullDaysSinceEpoch = Math.floor(now/8.64e7);
  return fullDaysSinceEpoch;
}

// Create a date prototype to return in format YYYY-MM-DD
Date.prototype.yyyymmdd = function() {
  var mm = (this.getMonth() + 1).toString(); // getMonth() is zero-based
  var dd = this.getDate().toString();

  return [this.getFullYear(), '-', mm.length===2 ? '' : '0', mm, '-', dd.length===2 ? '' : '0', dd].join(''); // padding
};

export function unixEpochToDate(epoch) {
  let date = new Date(epoch * 8.64e7);
  return date.yyyymmdd();
}

// Takes in an array of objects with an id and number.
// If a given id does not exist, it inserts the id with
// a number of 0. Starts at id 18795 (first day of data).
function fillInZeros(array) {
  let lastArrayIdx = 0;
  let ret = [];
  for(let i = 18793; i <= getUnixEpochDays(); i++) {
    if(array[lastArrayIdx]?.id == i) {
      ret.push(array[lastArrayIdx]);
      lastArrayIdx++;
    } else if(array[lastArrayIdx]?.id > i) {
      ret.push({id: `${i}`, number: 0});
    } else if(!array[lastArrayIdx]) {
      ret.push({id: `${i}`, number: 0});
    } else {
      console.log("ERROR: fillInZeros received an epoch before the start epoch");
    }
  }

  return ret;
}

// Takes in an array of objects and adds a time string property in the
// format 'YYYY-MM-DD' based on the epoch value of the id property.
// Modifies array in place. No return value.
export function fillInTime(array) {
  for(let obj of array) {
    obj.time = unixEpochToDate(obj.id);
  }
}

// Takes in an array of objects and adds a color string property. This
// styles the histogram for lightweight charts.
// Modifies array in place. No return value.
export function fillInColor(array) {
  for(let obj of array) {
    obj.color = '#E98234';
  }
}

// Takes in an array of objects and adds a value property which copies
// the number property returned from our query.
// Modifies array in place. No return value.
export function fillInValue(array) {
  for(let obj of array) {
    obj.value = obj.number;
  }
}

// Takes in an object of arrays. We aggregate the values in each array
// and return an object with the same structure as the parameter.
export function aggregateArrayObj(obj) {
  const ret = {};
  for(const property in obj) {
    const array = Array(obj[property].length);
    let runningTotal = 0;
    for(let num in obj[property]) {
      runningTotal += obj[property][num].number;
      array[num] = {id: obj[property][num].id, number: runningTotal, value: runningTotal, color: '#E98234', time: unixEpochToDate(obj[property][num].id)};
    }

    ret[property] = array;
  }

  return ret;
}

/**
 * Actual query methods
 **/
export async function loadUsersPerDay() {
  return loadPerDayAnalytics(getUsersPerDayQuery(getUnixEpochDays(), 1000));
}

export async function loadSignaturesPerDay() {
  return loadPerDayAnalytics(getSignaturesPerDayQuery(getUnixEpochDays(), 1000));
}

async function loadPerDayAnalytics(query) {
  let ret = {};
  for(let net of network) {
    const endpoint = `${ENDPOINT_PREFIX}${net}${API_VERSION}`;
    let data = [];

    await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query
      }),
    }).then(res => res.json()).then((res) => {
      if(res.data?.usersPerDays) {
        data = res.data.usersPerDays;
      } else if(res.data?.signaturesPerDays) {
        data = res.data.signaturesPerDays;
      }
      ret[net] = fillInZeros(data);
      fillInTime(ret[net]);
      fillInValue(ret[net]);
      fillInColor(ret[net]);
    })
  }

  return ret;
}