import React, { useState, useEffect } from 'react';
import { aggregateArrayObj, getCSVData, getCSVHeaders, getTotalsPerChain, loadSignaturesPerDay, loadUsersPerDay } from '../helpers/analytics';
import { CSVLink } from 'react-csv';
import { XYChart, GroupedBarSeries, CrossHair, XAxis, YAxis, WithTooltip, withParentSize } from '@data-ui/xy-chart';
import NetworkSpecificCharts from './NetworkSpecificCharts';

export default function Analytics(props) {

  const { handleActivePage } = props;
  const [error, handleError] = useState(false);
  const [loading, handleLoading] = useState(false);
  const [data, handleData] = useState(null);

  useEffect(() => {
    handleActivePage('analytics');
  }, []);

  useEffect(() => {
    (async () => {
      handleError(false);
      handleLoading(true);

      let obj = {};
      obj.usersPerDay = await loadUsersPerDay();
      obj.signaturesPerDay = await loadSignaturesPerDay();
      obj.usersPerDayAgg = aggregateArrayObj(obj['usersPerDay']);
      obj.signaturesPerDayAgg = aggregateArrayObj(obj['signaturesPerDay']);
      obj.totalsPerChain = getTotalsPerChain(obj);
      obj.csvData = getCSVData(obj);

      handleData(obj);
      
      handleLoading(false);
    })();
  }, []);


  const ResponsiveXYChart = withParentSize(({parentWidth, parentHeight, ...rest}) => (
    <XYChart width={parentWidth} height={parentHeight} {...rest} />
  ));

  if(error) {
    return (
      <div className="m-auto text-lg text-gray-60">
        Error loading data.
      </div>
    );
  }

  return (
    <>
      <div className={`${loading ? '' : 'hidden '}m-auto text-lg text-gray-60`}>
        Loading...
      </div>
      <div className={`${loading ? 'absolute hidden ' : 'block '} flex justify-center justify-around flex-wrap mt-16 px-2 sm:px-16 mb-auto w-full max-w-7xl mx-auto`}>
        <div className="w-full flex justify-between">
          <div className='text-xl my-auto font-semibold'>Analytics</div>
          <CSVLink className="cursor-pointer text-center my-auto w-32 font-bold flex-grow-0 bg-orange-500 focus:outline-none text-gray-40 py-2 rounded-sm hover:bg-orange-600" onClick={(event) => {
              // Returning false will not export the svg. Since we are still loading data, we don't want to give an incomplete csv file.
              if(loading) {
                return false;
              }
            }}
            headers={getCSVHeaders()}
            data={data ? data.csvData : ""}
            filename={`data-export-${(new Date().toISOString().replace(':', '-').substring(0, 16))}Z.csv`}>
              {loading ? 'Loading...' : 'Export to CSV'}
          </CSVLink>
        </div>

        {/* Totals Per Chain */}
        <div className='w-full p-4 rounded-lg bg-gray-25 flex flex-col m-4 md:m-2'>
          <div className="w-full">
            {data?.totalsPerChain &&
              <div className="w-full h-72">
                <WithTooltip className="w-full h-full">
                  <ResponsiveXYChart
                    ariaLabel="Totals By Chain"
                    margin={{top: 16, right: 32, bottom: 32, left: 32}}
                    xScale={{ type: 'band' }}
                    yScale={{ type: 'linear' }}
                    renderTooltip={({ event, datum, data, color }) => (
                      <div>
                        <strong style={{ color }}>{datum.label}</strong>
                        <div>
                          <strong>x </strong>
                          {`${datum.key}`}
                        </div>
                        <div>
                          <strong>y </strong>
                          {datum.y}
                        </div>
                      </div>
                    )}
                  >
                    <YAxis
                      orientation="left"
                    />
                    <GroupedBarSeries
                      data={data.totalsPerChain}
                      groupKeys={[ "Total Users", "Total Signatures" ]}
                      groupFills={["#E98234", "#464648"]}
                    />
                    <XAxis/>
                    <CrossHair stroke="#E98234" />
                  </ResponsiveXYChart>
                </WithTooltip>
              </div>
            }
          </div>
          <div className='mx-auto text-center text-sm text-gray-80 flex flex-row flex-wrap'><div className='mr-2'>{`Totals By Chain`}</div>(<div className='flex mr-2'><div className='h-4 w-4 bg-orange-500 my-auto mr-1'></div><div className='my-auto'> - Users</div></div> <div className='flex'><div className='h-4 w-4 bg-gray-80 my-auto mr-1'></div><div className='my-auto'> - Signatures</div></div>)</div>
        </div>
      </div>

      { /**
       * The following is being loaded in a different component because updating the state directly in
       * Analytics results in XYChart re-rendering. This re-render results in the chart flickering. The
       * only fix is to do any state changes in a sub-component, so the code that used to reside here
       * has been copied into NetworkSpecificCharts.js.
       */}
      <div className='w-full'>
        <NetworkSpecificCharts data={data} loading={loading} />
      </div>
    </>
  );
}