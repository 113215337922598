export const getFileName = (file) => {
   if(file){
    const lastIndex = file.name.lastIndexOf('.');
    const extension = file.name.slice(lastIndex);
    const fileNameWithoutExtention = file.name.slice(0, lastIndex);
    return {
        name: fileNameWithoutExtention,
        ext: extension
    }
   }else{
       return ''
   }    
}