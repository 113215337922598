import React from 'react';
import { withRouter } from 'react-router-dom'
import { useIntl } from 'react-intl';
import CarretLeftIcon from '../assets/carret_left.svg';
import VerifiedCheckIcon from '../assets/check_verified.svg';
import VerifiedPendingIcon from '../assets/pending_verified.svg';
import Loader from './UI/loader';
import { exportVerification } from '../helpers/verify';
const VerificationDetails = (props) => {
	const { loading, gqlResult, loadContractHistory, goBack } = props;
  const { formatMessage } = useIntl();

	return (
		<div className="flex">
      <div className="m-auto w-full flex justify-center">
        <div onClick={(event) => event.stopPropagation()} className="w-full bg-gray-45 sm:border-t-4 border-t-8 border-orange-500 sm:rounded-none rounded-lg shadow-md sm:shadow-none">
          {loading ?
            <div className="flex justify-center mb-8">
              <Loader />
            </div>
            :
            <div className="p-4 sm:p-6 flex flex-col justify-center w-full">
              <div onClick={() => goBack()} className="text-15 py-3 select-none cursor-pointer flex flex-row mb-2 mr-auto">
                <img src={CarretLeftIcon} className="h-4 w-4 my-auto" onDragStart={(e) => e.preventDefault()}/>
                <div className="text-left font-semibold text-gray my-auto">{formatMessage({id: 'BACK'})}</div>
              </div>

              <div className="flex flex-row mb-2">
                <div className="text-20 font-semibold text-gray-80 my-auto">{formatMessage({id: 'SIGNATURE'})}</div>
                <div className={`text-20 font-semibold mx-2 my-auto ${gqlResult.status == 1 ? 'text-orange-500' : 'text-blue-80'}`}>{gqlResult?.status == 1 ? formatMessage({id: 'VALID'}) : formatMessage({id: 'PENDING'})}</div>
                <img src={gqlResult?.status == 1 ? VerifiedCheckIcon : VerifiedPendingIcon} className="h-8 w-8" onDragStart={(e) => e.preventDefault()} />
              </div>

              <div className="flex flex-wrap sm:flex-nowrap justify-start flex-col w-full text-gray">
                <div className="flex flex-col md:flex-row justify-start pt-6 pb-4">
                    <div className="flex-shrink-0 w-full md:w-56 mb-1 md:mb-0">{formatMessage({id: 'DOCUMENT_NAME'}) + ":"}</div>
                    <div className="text-left text-gray-95 bg-gray-25 border-2 border-gray-90 px-2">{gqlResult?.doc.name}</div>
                </div>
                <div className="flex flex-col md:flex-row justify-start pt-4 pb-4">
                    <div className="flex-shrink-0 w-full md:w-56 mb-1 md:mb-0">{formatMessage({id: 'DOCUMENT_KEY'}) + ":"}</div>
                    <div className="text-left text-gray-95 bg-gray-25 border-2 border-gray-90 px-2">{gqlResult?.doc.key}</div>
                </div>
                <div className="flex flex-col md:flex-row justify-start pt-4 pb-4">
                    <div className="flex-shrink-0 w-full md:w-56 mb-1 md:mb-0">{formatMessage({id: 'DIGITALLY_SIGNED_BY'}) + ":"}</div>
                    <div className="text-left text-gray-95 bg-gray-25 border-2 border-gray-90 px-2">{gqlResult?.signerDetails.address}</div>
                </div>
                <div className="flex flex-col md:flex-row justify-start pt-4 pb-4">
                    <div className="flex-shrink-0 w-full md:w-56 mb-1 md:mb-0">{formatMessage({id: 'SIGNATURE_APPLIED_DATE'}) + ":"}</div>
                    <div className="text-left text-gray-95 bg-gray-25 border-2 border-gray-90 px-2">{gqlResult?.signerDetails.timestamp ? (new Date(gqlResult?.signerDetails.timestamp * 1000)).toLocaleString() : 'Pending'}</div>
                </div>
              </div>

              <div className="mt-4 flex flex-col sm:flex-row">
                {gqlResult?.status == 1 && <button onClick={()=>{exportVerification(gqlResult)}} className="select-none focus:outline-none w-full sm:w-auto text-white mt-2 sm:mx-0 py-2 font-semibold text-orange-500 hover:text-orange-600 rounded-sm mr-0 sm:mr-4 md:mr-8">{formatMessage({id: 'DOWNLOAD_VALIDATION'})}</button>}
                <button onClick={() => loadContractHistory(gqlResult?.networkId, gqlResult?.signerDetails?.address, gqlResult?.doc?.key, 1)} className="select-none focus:outline-none w-full sm:w-auto text-white mt-2 sm:mx-0 py-2 font-semibold text-orange-500 hover:text-orange-600 rounded-sm">{formatMessage({id: 'VIEW_CONTRACT_HISTORY'})}</button>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
	);
}

export default withRouter(VerificationDetails);
