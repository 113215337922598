import React, { useState } from 'react';
import Email from '../../assets/email.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { saveEmail } from '../../helpers/email';

export const Content = (props) => {
    const { web3, handleOpen, handleValue} = props;
    const [localEmail, handleLocalEmail] = useState();
    const {formatMessage} = useIntl();
    
    return (
        <div className="flex flex-col juestify-center items-center px-4 sm:px-16 pb-8 pt-8 bg-gray-45 border-t-4 border-orange-500 ">
            <h3 className="text-base sm:text-2xl my-6"><FormattedMessage id="WELCOME_TO_ETHSIGN"/>!</h3>
            <img src={Email} alt="" className=""/>
            <span className="text-xs sm:text-base mt-12 text-gray-500"><FormattedMessage id="SET_YOUR_EMAIL_ADDRESS"/>:</span>
            <div className="flex flex-col sm:flex-row juestify-center items-center my-4">
                <input 
                    className="w-full xs:w-80 p-2 border border-orange-400 rounded-md focus:outline-none focus:border-orange-500" 
                    placeholder={formatMessage({id: 'ENTER_YOUR_EMAIL'})} 
                    onChange={(evt) => {
                        handleLocalEmail(evt.target.value)
                    }}
                />
                <button 
                    className="w-full xs:w-32  font-bold cursor-pointer rounded-md text-white bg-orange-500 hover:bg-orange-600 justify-center px-1 py-2 mx-4 my-2 sm:my-0"
                    onClick={()=>{
                        saveEmail(web3, localEmail, formatMessage)
                        .then(({success}) => {
                            if (success) {
                                handleOpen(false);
                            };
                        })
                    }}
                >
                        <FormattedMessage id="COMFIRM"/>
                </button>
                <span 
                    className="underline cursor-pointer self-end text-gray-500 text-xs" 
                    onClick={()=>{
                        handleValue({skip: true});
                        handleOpen(false);
                        }}>
                    <FormattedMessage id="SKIP_FOR_NOW"
                />
                </span>
            </div>
        </div>
    )
}