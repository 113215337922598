import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import PopupOnClick from "../../components/Dashboard/PopupOnClick"; 
import {Content} from './content';
import { useLocalStorage } from '../../helpers/localStorage';
import { EMAIL_NOTIFICATION } from '../../helpers/email';

export default function EmailNotification (props) {
    const {web3, handleOpen, open} = props;
    const [value, handleValue] = useLocalStorage(EMAIL_NOTIFICATION, {skip: false});

    return (
        <div>
            {
                open && <div className="w-full h-screen opacity-30 bg-gray-50 absolute top-0 z-50"/>
            }
            <PopupOnClick
                open={open}
                position="center center"
                onClose={()=> {
                    handleOpen(false);
                }}
                PopupComponent={() => <Content web3={web3} handleOpen={handleOpen} handleValue={handleValue}/>} 
            />
        </div>
    )
}