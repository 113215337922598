import React, {useEffect, useState} from 'react';
import logoBG from '../../assets/logo-bg-light.png';
import AddIcon from '../../assets/add.svg';
import SignerForm from './SignerForm';
import { storeNotif } from '../../helpers/dashboard';
import { FormattedMessage, useIntl } from 'react-intl';


const Invite = (props) => {
    const {provider, signers, handleSigners, handleProgress, ensEnabled, ethAccount, addedSigners, updateStoredData} = props;
    const [max, handleMax] = useState(1);
    const { formatMessage } = useIntl();
    const isAdded = (signer) => {
        const index = _.indexOf(addedSigners, signer);
        if(index>-1){
            return true;
        }else{
            return false;
        }
    }
    const [forms, handleForms] = useState(signers.map((signer, index)=>{
        return(<SignerForm key={index} ensEnabled={ensEnabled} provider={provider} ethAccount={ethAccount} signers={signers} handleSigners={handleSigners} initialValue={signer} id={index} added={isAdded(signer)} updateStoredData={updateStoredData} />)
    }))
    // this variable just triggers display refresh
    const [count, handleCount] = useState(signers.length)

    useEffect(() => {
		let isSubscribed = true;
            addForm();
		return () => isSubscribed = false
	}, []);

    const addForm = () => {
        var tempForm = forms;
        tempForm.push(<SignerForm provider={provider} key={tempForm.length} ensEnabled={ensEnabled} ethAccount={ethAccount} signers={signers} handleSigners={handleSigners} initialValue={null} id={tempForm.length}  added={null} updateStoredData={updateStoredData}/>)
        handleForms(tempForm)
        handleCount(tempForm.length)
        handleMax(max+1);
    }

    const validateSigners = () => {
        // if(numOfSigners!=null && signers.length!=numOfSigners){
        //     let diff = numOfSigners - signers.length;
        //     storeNotif(formatMessage({id: 'ERROR'}), formatMessage({id: 'THIS_CONTRACT_HAS'}, {num:numOfSigners, len: signers.length, operation: (diff>0? 'add':'remove'), diff: (diff>0? diff:diff*-1)}), 'warning')
        //     return;
        // }


        let userInputs = []
        for (var i=-1; i<max; i++){
            if (document.getElementById(`signer-${i}`)){
                userInputs.push(document.getElementById(`signer-${i}`).value)
            }
        }
        let notAdded = _.pullAll(userInputs, signers);
        let valid = true
        notAdded.map((signer, idx)=>{
            if(signer!=""){
                valid=false;
                storeNotif(formatMessage({id: 'WARNING'}), formatMessage({id: 'SIGNER_WAS_ENTERED_BUT_NOT_ADDED'}, {signer: signer}), 'warning')
            }
        })
        if (valid){
            handleProgress(2)
        }
    }

    return(
        <div className="select-none max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 w-full my-8 text-gray-300 text-15">
            <div className="relative mx-5 pb-2 border-b border-gray-200">
                <div className="px-3 font-bold text-30 ">
                    <FormattedMessage id='INVITE_SIGNERS'/>
                </div>
                <img src={logoBG} className="absolute bottom-0 -left-5 -z-10"/>
            </div>
            <div className="mx-8 my-10 flex flex-col md:flex-row md:flex-wrap justify-between">
               {forms.map((form)=>{
                   return form;
               })}
            </div>
            <div className="mx-8 px-3 mt-5 mb-10">
                <div className="flex">
                    <img src={AddIcon} id="add-btn" onClick={()=>{addForm()}} className="cursor-pointer mr-3 hover:shadow-md rounded-full"/>
                    <div className="text-gray-80 my-auto">
                        <FormattedMessage id='ADD_SIGNER'/>
                    </div>
                </div>
                
            </div>
            <div className="flex justify-center xs:justify-end">
                <button className="focus:outline-none text-white mr-5 w-28 py-2 font-medium bg-gray-300 hover:bg-gray-600 rounded-sm" onClick={()=>{handleProgress(0)}}>
                    <FormattedMessage id='BACK'/>
                </button>
                <button id="invite-next-btn" className="focus:outline-none text-white w-28 py-2 font-medium bg-orange-500 hover:bg-orange-600 rounded-sm" onClick={()=>{validateSigners()}}>
                    <FormattedMessage id='NEXT'/>
                </button>
            </div>
        </div>
    );
}
export default Invite;