import React, {useState, useEffect} from 'react';
import ProgressBar from './Revision/ProgressBar';
import Revision from './Revision/Revision';
import ReviewSend from './Revision/ReviewSend';
import Prepare from './Revision/Prepare';
const NewRevision = (props) => {
    const {fm, ethAccount, web3, handleActivePage, contract, appLogout, handleOpen} = props;
    const [progress, handleProgress] = useState(0);
    const [files, handleFiles] = useState(null);
    
    useEffect (()=>{ 
		let isSubscribed = true;
        handleActivePage('revision');
		return () => isSubscribed = false;
	},[]);
    return(
        <>
            <ProgressBar fm={fm} progress={progress} handleProgress={handleProgress} appLogout={appLogout} ethAccount={ethAccount} handleOpen={handleOpen}/>
            {{
                0: <Revision files={files} handleFiles/>,
                1: <Prepare />,
                2: <ReviewSend files={files}/>

            }[progress]}
        </>
    );
}
export default NewRevision;