import React, { useState, useRef, useEffect } from 'react';

export default function AutoResizeInput(props) {
  const [content, setContent] = useState(props.value);
  const [width, setWidth] = useState(0);
  const span = useRef();

  useEffect(() => {
    setWidth(span.current.offsetWidth);
  }, [content]);

  useEffect(() => {
    setContent(props.value);
  }, [props.value])

  return (
    <wrapper>
      <input type="text" style={{ width }} {...props} />
      <span className="absolute opacity-0 px-1 whitespace-pre" ref={span}>{content}</span>
    </wrapper>
  );
};