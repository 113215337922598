import React, {useState, useEffect} from 'react';
import ProgressBar from './Create/ProgressBar';
import Create from './Create/Create';
import ReviewSend from './Create/ReviewSend';
import Invite from './Create/Invite';
import Prepare from './Create/Prepare';
import Sending from './Create/Sending';
import { storeNotif, getNewDocumentKey, findSignersForDocument } from '../helpers/dashboard';
import { validateUploadFile } from '../helpers/newdocument';
import { withRouter } from 'react-router';
import { useIntl } from 'react-intl';
import { Alert } from 'bootstrap';
import { getFileName } from '../helpers/file';

const ResubmitContract = (props) => {
  const {fm, torus, ethAccount, web3, handleActivePage, contract, appLogout, ensEnabled, handleData, changeNetwork, location, ethAlias, ethAvatar, clearStoredSessionData, storedData, handleStoredData, handleOpen} = props;
  const [doc, handleDoc] = useState(null);
  const [addedFields, handleAddedFields] = useState([]);
  const [fileHash, handleFileHash] = useState(null);  
  const [shouldShowRestoreDialog, handleShouldShowRestoreDialog] = useState(false);
  const [showRestoreDialog, handleShowRestoreDialog] = useState(false);
  const [progress, handleProgress] = useState(0);
  const [pageCount, handlePageCount] = useState(null);
  const [signers, handleSigners] = useState([
      {
          address: ethAccount,
          alias: ethAlias,
          avatar: ethAvatar
      }
  ])
  const [webviewer, handleWebviewer] = useState(null)
  const [file, handleFile] = useState(null);
  const [filename, handleFilename] = useState(null);
  const [withPassword, handleWithPassword] = useState(true);
  const [password, handlePassword] = useState("");
  const [withExpiry, handleWithExpiry] = useState(false);
  const [expiryBlock, handleExpiryBlock] = useState(0);
  const [sending, handleSending] = useState(false);
  const [documentKey, handleDocumentKey] = useState(null);
  const [selfSignOnSubmit, handleSelfSignOnSubmit] = useState(false);
  const [userIsSigning, handleUserIsSigning] = useState(false);
  const [storageProvider, handleStorageProvider] = useState('FL');
  const [originalFilename, handleOriginalFilename] = useState('');
  // newdocument.js states
  const [addedSigners, handleAddedSigners] = useState([]);
  const [createProgress, handleCreateProgress] = useState(0);
  const [indicatorProgress, handleIndicatorProgress] = useState(0);
  const [storageHash, handleStorageHash] = useState(null);
  const [annotations, handleAnnotations] = useState(null);
  const [sigFields, handleSigFields] = useState(null)
  const [numOfSigners, handleNumOfSigners] = useState(null);
  const [annotList, handleAnnotList] = useState([])
  const { formatMessage } = useIntl();
  
  const validateProgress = progress => {
      if(file) {
        if(file.name.length <= 4) {
          storeNotif(formatMessage({id: 'NO_FILENAME'}), formatMessage({id: 'PROVIDE_FILENAME'}),'danger')
        } else {
          handleProgress(progress);
          updateStoredSessionData(progress);
        }
        
      } else if(progress) {
          storeNotif(formatMessage({id: "NO_FILE_UPLOADED"}), formatMessage({id: "PDF_UPLOADED_FIRST_PLEASE_UPLOAD"}),'danger')
      }
  }

  useEffect (()=>{ 
		let isSubscribed = true;
        (async () => {
            handleActivePage('upload');
            if(location.state !== undefined && location.state.doc && location.state.filter != null && location.state.idx != null) {
              handleDoc(location.state.doc);
              handleDocumentKey(location.state.doc.documentKey);
              handleStoredDocumentKey(location.state.doc.documentKey);

              if(storedDocumentKey && storedOriginalFilename) {
                handleShouldShowRestoreDialog(true);
              }
            } else {
              props.history.push({
                pathname: '/contracts'
              })
            }
        })();
		return () => isSubscribed = false;
	},[]);

  useEffect (()=>{ 
		let isSubscribed = true;
        const temp = signers;
        const index =  _.findIndex(temp, function(o) { return o.address == ethAccount; });
        if (index>-1){
            temp[index].alias = ethAlias;
            temp[index].avatar = ethAvatar;
        }
        handleSigners(temp)
		return () => isSubscribed = false;
	},[ethAlias, ethAvatar]);

  useEffect (()=>{ 
		let isSubscribed = true;
        if(file!=null){
            handleFilename(getFileName(file))
            handleSigFields(null)
            handleAnnotations(null)
        }
		return () => isSubscribed = false;
	},[file]);

  useEffect(() => {
    if(shouldShowRestoreDialog && storedOriginalFilename !== '' && storedOriginalFilename === originalFilename) {
        handleShouldShowRestoreDialog(false);
        handleShowRestoreDialog(true);
    }
  }, [originalFilename])

  const recoverStoredSessionData = async () => {
    handleCreateProgress(storedData.createProgress);
    handleOriginalFilename(storedData.originalFilename);
    handleFilename(storedData.filename);
    handleSigners(storedData.signers);
    handlePageCount(storedData.pageCount)
    handleIndicatorProgress(storedData.indicatorProgress);
    handleStorageHash(storedData.storageHash);
    handleStorageProvider(storedData.storageProvider);
    handleAnnotations(storedData.annotations);
    handleSigFields(storedData.sigFields);
    handleDocumentKey(storedData.documentKey);
    handlePassword(storedData.password);
    handleExpiryBlock(storedData.expiryBlock);
    handleWithPassword(storedData.withPassword);
    handleWithExpiry(storedData.withExpiry);
    
    const annot_list = await checkAndUpdateAnnotList(file, storedData.filename, handleWebviewer, viewer, ethAlias, ethAccount, storedData.annotations, signers);
    handleAnnotList(annot_list)
    handleProgress(storedData.progress);
  }

  

  return(
      <>
          {sending && 
              <div className="fixed top-0 bottom-0 w-full h-full z-40 bg-black-1000 bg-opacity-25 flex flex-col justify-center">
                  <Sending
                      data = {{
                          file: file,
                          signers: signers,
                          password: password,
                          expiryBlock: expiryBlock
                      }}
                      contract={contract}
                      web3={web3}
                      handleData={handleData}
                      addedSigners={addedSigners}
                      handleAddedSigners={handleAddedSigners}
                      createProgress={createProgress}
                      handleCreateProgress={handleCreateProgress}
                      indicatorProgress={indicatorProgress}
                      handleIndicatorProgress={handleIndicatorProgress}
                      fileHash={fileHash}
                      handleFileHash={handleFileHash}
                      addedFields={addedFields}
                      handleAddedFields={handleAddedFields}
                      documentKey={documentKey}
                      handleNumOfSigners={handleNumOfSigners}
                      close={()=>{handleSending(false)}}
                      selfSignOnSubmit={selfSignOnSubmit && userIsSigning}
                      shouldResubmit={true}
                      idx={location.state.idx}
                      filter={location.state.filter}
                      storageProvider={storageProvider}
                      handleStorageProvider={handleStorageProvider}
                      updateStoredSessionData={updateStoredSessionData}
                  />
              </div>
          }
          <ProgressBar resubmit={true} fm={fm} torus={torus} ethAlias={ethAlias} ethAvatar={ethAvatar} changeNetwork={(chain)=>changeNetwork(chain)} provider={web3} progress={progress} handleProgress={validateProgress} appLogout={appLogout} ethAccount={ethAccount} handleOpen={handleOpen}/>
          {{
              0: <Create file={file} handleFile={handleFile} handleProgress={validateProgress} pageCount={pageCount} handlePageCount={handlePageCount}
                    validateUploadFile={(file) => validateUploadFile(file, handlePageCount, handleFile, handleOriginalFilename, formatMessage)}
                    filename={filename}
                    handleFilename={handleFilename}
                  />,
              1: <Prepare
                    file={file}
                    filename={filename}
                    handleWebviewer={handleWebviewer}
                    ethAccount={ethAccount}
                    ethAlias={ethAlias}
                    handleProgress={validateProgress}
                    signers={signers}
                    annotations={annotations}
                    sigFields={sigFields}
                    handleSigFields={handleSigFields}
                    handleAnnotations={handleAnnotations}
                    handleAnnotList={handleAnnotList}
                    resubmit={true}
                  />,
              2: <ReviewSend
                  web3={web3}
                  file={file}
                  handleFile={handleFile}
                  handleProgress={validateProgress}
                  signers={signers}
                  handleSigners={handleSigners}
                  pageCount={pageCount}
                  handleSending={handleSending}
                  expiryBlock={expiryBlock}
                  handleExpiryBlock={handleExpiryBlock}
                  password={password}
                  handlePassword={handlePassword}
                  ethAccount={ethAccount}
                  provider={web3}
                  addedSigners={addedSigners}
                  ensEnabled={ensEnabled}
                  selfSignOnSubmit={selfSignOnSubmit}
                  handleSelfSignOnSubmit={handleSelfSignOnSubmit}
                  userIsSigning={userIsSigning}
                  handleUserIsSigning={handleUserIsSigning}
                  validateUploadFile={(file) => validateUploadFile(file, handlePageCount, handleFile, handleFilename, handleOriginalFilename, formatMessage)}
                  withPassword={withPassword}
                  handleWithPassword={handleWithPassword}
                  withExpiry={withExpiry}
                  handleWithExpiry={handleWithExpiry}
                  updateStoredSessionData={updateStoredSessionData}
                  />,
          }[progress]}
          {showRestoreDialog &&
            <Alert 
              message={formatMessage({id: 'DETECTED_DATA_RESTORE'})}
              closeOnOutsideClick={true}
              closeButtonText={formatMessage({id: 'NO'})}
              closeCallback={() => {
                clearStoredSessionData();
                handleShowRestoreDialog(false);
              }}
              okButtonText={formatMessage({id: "YES"})}
              okCallback={() => {
                recoverStoredSessionData();
                handleShowRestoreDialog(false);
              }}
            />
          }
      </>
  );
}
export default withRouter(ResubmitContract);