import React, { useState } from 'react';
import { withRouter } from 'react-router';
import Loader from '../components/UI/loader';
import { useIntl } from 'react-intl';
import ClearIcon from '../assets/clear.svg';

const Alert = (props) => {
    const { title, type, message, closeCallback, closeButtonText, okButtonText, okCallback, customComponent, loading, loadingText, displayLoader, closeOnOutsideClick, replaceButtonsWithLoader, alwaysDisplayTitle, bigTitle } = props;
    const [open, handleOpen] = useState(true);
    const { formatMessage } = useIntl();
    if(!open) {
      return null;
    }

    else if(loading && displayLoader) {
      return (
        <div onClick={(event) => event.stopPropagation()} className="overflow-hidden fixed z-999 inset-0 flex justify-center bg-white bg-opacity-50">
          <Loader />
        </div>
      );
    }

    else if(type && type == "top-right") {
      return (
        <div onClick={(event) => { if(!loading && closeOnOutsideClick) { handleOpen(!open); if(closeCallback) { closeCallback(); } } event.stopPropagation(); }} className="overflow-hidden fixed z-999 inset-0 bg-gray-70 bg-opacity-30">
          <div className="top-0 right-0 m-auto">
            <div class="ml-auto mt-4 mr-4 sm:mr-16 w-8 overflow-hidden">
              <div class="h-4 w-4 bg-orange-500 rotate-45 transform origin-bottom-left"></div>
            </div>
            <div className="sm:ml-auto max-w-2xl">
              <div onClick={(event) => event.stopPropagation()} className="mx-auto sm:ml-auto sm:mr-12 bg-white w-11/12 sm:w-7/12 border-t-8 border-orange-500 rounded-lg shadow-md">
                <div className="py-5 px-5 flex flex-col justify-center">
                  {loading ? (
                    <div className="select-none pt-3 mb-5 mx-auto text-center">
                      {loadingText ? loadingText : formatMessage({id: 'LOADING'})}
                    </div>
                  ) : (
                    <>
                      {title &&
                        <div className="select-none font-bold pt-3 mb-2 mx-auto text-center block">
                          {title}
                        </div>
                      }

                      <div className={`select-none pt-3 mb-5 mx-auto text-center text-gray-60`}>
                        {message}
                      </div>

                      <div className="flex flex-wrap sm:flex-nowrap justify-start">
                        {customComponent}
                      </div>

                      <div className="select-none flex flex-wrap text-white justify-end mt-5">
                        <button onClick={()=>{handleOpen(!open); if(closeCallback) {closeCallback();}}} type="button" className="w-full mt-3 font-bold flex-grow-0 bg-orange-500 focus:outline-none text-gray-40  py-2 rounded-sm  hover:bg-orange-600">
                          {closeButtonText ? closeButtonText : formatMessage({id: 'CLOSE'})}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    else if (type && type == "bottom-right"){
      return(
        <div onClick={(event) => { if(closeOnOutsideClick) { handleOpen(!open); if(closeCallback) { closeCallback(); } } event.stopPropagation(); }} className="overflow-hidden flex  justify-center">
          <div className="m-auto max-w-7xl w-full flex justify-center">
            <div onClick={(event) => event.stopPropagation()} className="z-999 bottom-6 right-6 absolute bg-white w-96 sm:border-t-4 border-t-8 border-orange-500 sm:rounded-none rounded-lg shadow-lg">
              <div className="py-5 px-5 flex flex-col justify-center w-full">
                {loading ? (
                  <div className="select-none pt-3 mb-5 mx-auto text-left">
                    {loadingText ? loadingText : formatMessage({id: 'LOADING'})}
                  </div>
                ) : (
                  <>
                    {title &&
                      <div className="select-none font-bold  mb-2 mr-auto text-left block">
                        {title}
                      </div>
                    }

                    <div className={`select-none pt-2 mr-auto text-left text-gray-60 sm:text-gray-80`}>
                      {message}
                    </div>

                    <div className="flex flex-wrap sm:flex-nowrap justify-start">
                      {customComponent}
                    </div>

                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
    

    else if(type && type == 1) {
      return (
        <div onClick={(event) => { if(!loading && closeOnOutsideClick) { handleOpen(!open); if(closeCallback) { closeCallback(false); } } event.stopPropagation(); }} className="overflow-hidden fixed z-999 inset-0 flex justify-center bg-gray-70 bg-opacity-30">
          <div className="inset-0 m-auto max-w-7xl w-full flex justify-center">
            <div onClick={(event) => event.stopPropagation()} className="bg-white w-11/12 sm:w-7/12 sm:border-t-4 border-t-8 border-orange-500 sm:rounded-none rounded-lg shadow-md sm:shadow-none">
              <div className="py-5 px-5 flex flex-col justify-center w-full">
                {loading ? (
                  <div className="select-none pt-3 mb-5 mx-auto text-left">
                    {loadingText ? loadingText : formatMessage({id: 'LOADING'})}
                  </div>
                ) : (
                  <>
                    {title &&
                      <div className={`select-none font-bold pt-3 mb-2 mr-auto text-left ${alwaysDisplayTitle ? 'block' : 'block sm:hidden'}${bigTitle ? ' text-20' : ''}`}>
                        {title}
                      </div>
                    }

                    {message &&
                      <div className={`select-none pt-3 mb-5 mr-auto text-left text-gray-60 sm:text-gray-80`}>
                        {message}
                      </div>
                    }

                    <div className="flex flex-wrap sm:flex-nowrap justify-start">
                      {customComponent}
                    </div>

                    <div className="flex flex-wrap text-white justify-end">
                      <button className="select-none w-48/100 mt-3 sm:ml-3 sm:w-28 font-bold flex-grow-0 bg-orange-500 focus:outline-none text-gray-40  py-2 rounded-sm  hover:bg-orange-600" onClick={()=>{handleOpen(!open); if(closeCallback) {closeCallback(true);}}}>
                        {closeButtonText ? closeButtonText : formatMessage({id: 'CLOSE'})}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }

    else if(type && type == 2) {
      // Centered popup with an "X" on the top right and no text buttons (i.e. close/ok).
      // Only displays the custom component and a title.
      return (
        <div onClick={(event) => { if(!loading && closeOnOutsideClick) { handleOpen(!open); if(closeCallback) { closeCallback(); } } event.stopPropagation(); }} className="overflow-hidden fixed z-999 inset-0 flex justify-center bg-gray-70 bg-opacity-30">
          <div className="inset-0 m-auto cursor-default max-w-7xl w-full flex justify-center">
            <div onClick={(event) => event.stopPropagation()} className="bg-white w-11/12 sm:w-7/12 sm:border-t-4 border-t-8 border-orange-500 sm:rounded-none rounded-lg shadow-md sm:shadow-none">
              <div className="py-5 px-5 flex flex-col justify-center w-full">
                <div className="flex justify-end h-0">
                  <img src={ClearIcon} className="-mt-2 -mr-2 h-4 w-4 cursor-pointer select-none" onClick={() => {handleOpen(!open); if(closeCallback) {closeCallback();}}} />
                </div>
                {title &&
                  <div className="select-none font-bold pt-3 mb-2 mr-auto text-left block">
                    {title}
                  </div>
                }

                <div className="flex flex-wrap sm:flex-nowrap justify-start">
                  {customComponent}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return(
      <div onClick={(event) => { if(!loading && closeOnOutsideClick) { handleOpen(!open); if(closeCallback) { closeCallback(); } } event.stopPropagation(); }} className="overflow-hidden fixed z-999 inset-0 flex justify-center bg-gray-70 bg-opacity-30">
        <div className="inset-0 m-auto  max-w-7xl w-full flex justify-center">
          <div onClick={(event) => event.stopPropagation()} className="bg-white w-11/12 sm:w-7/12 sm:border-t-4 border-t-8 border-orange-500 sm:rounded-none rounded-lg shadow-md sm:shadow-none">
            <div className="py-5 px-5 flex flex-col justify-center w-full">
              {loading ? (
                <div className="select-none pt-3 mb-5 mx-auto text-left">
                  {loadingText ? loadingText : formatMessage({id: 'LOADING'})}
                </div>
              ) : (
                <>
                  {title &&
                    <div className="select-none font-bold pt-3 mb-2 mr-auto text-left block sm:hidden">
                      {title}
                    </div>
                  }

                  <div className={`select-none pt-3 mb-5 mr-auto text-left text-gray-60 sm:text-gray-80`}>
                    {message}
                  </div>

                  {customComponent &&
                    <div className="flex flex-wrap sm:flex-nowrap justify-start">
                      {customComponent}
                    </div>
                  }

                    <div className="select-none flex flex-wrap text-white justify-center sm:justify-end mt-5">
                      {replaceButtonsWithLoader ? 
                        <div className="-mt-8">
                          <Loader />
                        </div>
                        :
                        <>
                          <button onClick={()=>{handleOpen(!open); if(closeCallback) {closeCallback();}}} type="button" className="w-48/100 mr-auto mt-3 sm:mr-0 sm:mt-0 sm:w-28 flex-grow-0 font-bold bg-gray-60 focus:outline-none text-gray-40 py-2 rounded-sm  hover:bg-gray-60h">
                            {closeButtonText ? closeButtonText : formatMessage({id: 'CLOSE'})}
                          </button>
                          <button className="w-48/100 mt-3 sm:mt-0 sm:ml-3 sm:w-28 font-bold flex-grow-0 bg-orange-500 focus:outline-none text-gray-40  py-2 rounded-sm  hover:bg-orange-600" onClick={()=>{if(okCallback) {okCallback();}}}>
                            {okButtonText ? okButtonText : formatMessage({id: 'OK'})}
                          </button>
                        </>
                      }
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    
}
export default withRouter(Alert);