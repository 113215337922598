import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import checkbox from '../../assets/checkbox.svg';
import orange_checkbox from '../../assets/orange_checkbox.svg';
import checked_checkbox from '../../assets/checked_checkbox.svg';
import orange_checked_checkbox from '../../assets/orange_checked_checkbox.svg';
import { useIntl } from 'react-intl';

const SignatureFinder = (props) => {
  const {mySigFields, instance, docPage, fieldsByPage, numOfSigned} = props;
  const [active, handleActive] = useState(null)
  const { formatMessage } = useIntl();
// currently mySigFields - > not signed sig fields of logged in user

  useEffect(() => {
    let isSubscribed = true;
    handleActive(docPage)
		return () => isSubscribed = false;
  }, [docPage]);

  useEffect(() => {
    let isSubscribed = true;
    return () => isSubscribed = false;
  }, [mySigFields]);

  

  const findSignature = (idx, annot) => {
    instance?.Core.annotationManager.jumpToAnnotation(annot);
  }
  
  
  return(
    <>
    {mySigFields && <div className="w-64 bg-gray-40 text-gray-80 text-15 flex flex-col py-5 ">
      <div className="px-3 pb-4 border-b flex justify-between">
          <span>
            {formatMessage({id: 'SIGNATURES_REQUIRED'})}
          </span>
          <span>
            {numOfSigned}/{mySigFields ? mySigFields.length:''}
          </span>
      </div>
      <div className="py-2 px-3">
        
        {fieldsByPage && fieldsByPage.map((byPage, idx)=>{
          return(
            <div className={`${active==byPage.page? 'text-orange-500':''} flex justify-between my-5 cursor-pointer`} onClick={()=>{findSignature(idx, byPage.annots[0])}}>
              <div>
                {formatMessage({id: 'PAGE_CAP_P'})} {byPage.page}
              </div>
              {byPage.signed == true? 
                <img src={active==byPage.page? orange_checked_checkbox:checked_checkbox}  className=""/>
              :
                <img src={active==byPage.page? orange_checkbox:checkbox}  className=""/>
              }
              
            </div>
          )
        })}
          
        
        
      </div>



          {/* <div
              draggable
              onDragStart={e => dragStart(e)}
              onDragEnd={e => dragEnd(e, 'SIGNATURE')}
          >

              <button onClick={()=>{addField('SIGNATURE')}}>
                  Add a signature 
              </button>
          </div> */}
    </div>}
    </>
  );
}
export default SignatureFinder