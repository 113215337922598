import React from 'react'
import Popup from 'reactjs-popup'

const PopupOnClick = (props) => {
    const {trigger, PopupComponent, className, disabled, open, onClose} = props;
    return(
        <Popup
            trigger={trigger}
            modal
            closeOnDocumentClick
            className={className}
            disabled={disabled}
            open={open}
            onClose={onClose}
        >
            {close => 
                <PopupComponent
                    {...props}
                    close={()=>close()}
                />
            }
        </Popup>
    );
}
export default PopupOnClick;