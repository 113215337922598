import React, { useState } from 'react';
import ClearIcon from '../assets/clear.svg';
import { withRouter } from 'react-router-dom'
import Alert from './Alert';
const Announcement = (props) => {
	const { activePage, handleHideAnnouncement, icon, message, showAlertButtonText, alertTitle, alertCustomComponent, alertCloseButtonText, alertCloseCallback } = props;
  const [showAlert, handleShowAlert] = useState(false);

	const nonavbar = [];
	return (
		<>
		{!nonavbar.includes(activePage) &&
		<>
			<div className="w-full">
				<nav className={`select-none bg-gray-40`}>
					<div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
					  <div className="relative flex items-center justify-center py-4">
              {icon &&
                <img src={icon} className="h-5 w-5 mr-2" />
              }
              {message &&
                <div className="text-12 text-gray-70 flex-shrink">{message}</div>
              }
              {showAlertButtonText &&
                <div className="text-12 text-orange-500 hover:text-orange-600 cursor-pointer ml-2 flex-shrink-0 mr-6" onClick={() => handleShowAlert(true)}>{showAlertButtonText}</div>
              }
              <img src={ClearIcon} alt="" className="absolute cursor-pointer select-none right-0" onClick={()=>{handleHideAnnouncement(true)}}/>
						</div>
					</div>
				</nav>
			</div>

      { showAlert &&
        <Alert 
          type={1}
          title={alertTitle}
          alwaysDisplayTitle={true}
          bigTitle={true}
          customComponent={alertCustomComponent}
          closeButtonText={alertCloseButtonText}
          closeCallback={(b) => {
            handleShowAlert(false);
            if(alertCloseCallback) {
              alertCloseCallback(b);
            }
          }}
          closeOnOutsideClick={true}
        />
      }
		</>
		}
		</>
	);
}

export default withRouter(Announcement);
