import React, { useEffect } from 'react';
import SignerDetail from './SignerDetail';
import { FormattedMessage } from 'react-intl';

const HistorySidebar = (props) => {
  const { doc, contract, provider, mobileSidebar, handleMobileSidebar, signers, initiator, ethAccount, ensEnabled, fromVerify } = props;

  useEffect (()=>{
		let isSubscribed = true;
        if (mobileSidebar === true){
            handleMobileSidebar(false)
        }
		return () => isSubscribed = false;
	},[]);
  const Sidebar = 
    <>
      <div className={`text-15 font-normal px-7${fromVerify ? ' mt-5' : ''}`}>
        <div className="select-none font-bold flex pb-3">
          <FormattedMessage id='CREATER'/>
        </div>
        {doc !== null &&
          <div className="flex flex-wrap justify-between">
              <SignerDetail widen={true} key={"signer-detail-creator"} userNotSigning={!(_.includes(signers, ethAccount))} ethAccount={ethAccount} signer={initiator} doc={doc} ensEnabled={ensEnabled} contract={contract} provider={provider}/>
          </div>
        }
        
      </div>
      <div className="my-5"></div>
      <div className="text-15 font-normal px-7">
          <div className="select-none font-bold flex pb-3">
          <FormattedMessage id='SIGNERS'/>
          </div>
          <div className="flex flex-wrap justify-between">
              {signers && signers.map((signer,i)=>{
                  return(
                      <SignerDetail widen={true} key={"signer-detail" + i} ethAccount={ethAccount} signer={signer} doc={doc} ensEnabled={ensEnabled} contract={contract} provider={provider}/>
                  );
              })}
          </div>
      </div>
    </>

  return(
    <>
      {mobileSidebar == true? 
        <>
          <div className="mobileSidebarBG" onClick={()=>handleMobileSidebar(false)}></div>
          <div className="bg-white mobileSidebar hs-max-width overflow-y-auto">
            <div className={`relative md:flex flex-col md:w-96 border-${fromVerify ? 'l' : 'r'} border-gray-200 my-10 py-5`}>
              {Sidebar}
            </div>
          </div>
        </>
      :
        <div className={`hidden bg-white lg:flex flex-col sm:w-64 md:w-96 min-w-64 border-${fromVerify ? 'l py-10' : 'r py-15'} border-gray-200`}>
          {Sidebar}
        </div>
      }
    </>
  );
}

export default HistorySidebar;