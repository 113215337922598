import React, { useState } from 'react';
import logo from '../assets/logo.svg';
import logoWithBG from '../assets/logo-with-bg-nav.png';
import { Link, withRouter } from 'react-router-dom'
import NavbarLinks from './Navbar/NavbarLinks';
import UserMenu from './User/UserMenu';
import Announcement from './Announcement';
import { useIntl } from 'react-intl';
const Navigation = (props) => {
	const {fm, torus, loggedIn, activePage, ethAccount, appLogout, changeNetwork, provider, ethAlias, ethAvatar, handleOpen, announcementShowing, handleHideAnnouncement, showSunsetAnnouncement, handleShowSunsetAnnouncement, icon, numberOfBlocksBehind, message, showAlertButtonText, alertTitle, alertCustomComponent, alertCloseButtonText} = props;
	const [showProfile, handleShowProfile] =  useState(false);

	const { formatMessage } = useIntl();

	let eventListener = null;
	
	const logout = () => {
		appLogout();
		showMenu(false);
		props.history.push({
			pathname: '/signin',
		})
	}

	const showMenu = (event, show) => {
		if(!show) {
			hideMenu(event);
		}else{
			if(!eventListener) {
				eventListener = hideMenu.bind(event);
			}
			document.addEventListener('click', eventListener);
			handleShowProfile(true);
		}
	}

	const hideMenu = (event) => {
		const element = document.getElementById('nav');
		const manageProfileBt = document.getElementById('manage-profile-bt');
		// click the extra area beside #nav, trigger the hide the network menu 
		// sepecial: when click the #manage-profile-bt and new-email will triger the hide the network menu 
		if((!element && !manageProfileBt) || (event && !element.contains(event.target)) || (event && manageProfileBt.contains(event.target))) {
			document.removeEventListener('click', eventListener);
			handleShowProfile(false);
		}
	}

	const announcement = (
		<Announcement
			activePage={activePage}
			handleHideAnnouncement={handleHideAnnouncement}
			icon={icon}
			message={formatMessage({id: 'CONTRACT_INDEXING_IS_DELAYED'}, {number: numberOfBlocksBehind !== undefined && numberOfBlocksBehind !== null ? numberOfBlocksBehind : '--'})}
			showAlertButtonText={formatMessage({id: 'LEARN_MORE'})}
			alertTitle={formatMessage({id: 'TEMPORARY_BLOCKCHAIN_INDEXING_DELAY'})}
			alertCloseButtonText={formatMessage({id: 'OK'})}
			alertCustomComponent={
				<div className="flex flex-col">
					<div className="mt-2">{formatMessage({id: 'WE_HAVE_DETECTED_A_BLOCKCHAIN'})}</div>
					<div className="font-semibold ml-1">{formatMessage({id: 'NEWLY_CREATED_CONTRACTS_NOT'})}</div>
					<div className="font-semibold ml-1">{formatMessage({id: 'NEWLY_APPLIED_SIGNATURES_NOT'})}</div>
					<div className="font-semibold ml-1">{formatMessage({id: 'ARCHIVED_CONTRACTS_NOT'})}</div>
					<div className="pt-4">{formatMessage({id: 'WE_ARE_CONTINUOUSLY_WORKING'})}<span className="text-blue-50 cursor-pointer hover:text-blue-60" onClick={() => window.open('https://discord.gg/Wvhp9dWdSg', '_blank').focus()}>Discord</span>.</div>
				</div>
			}
		/>
	);

	const sunsettingAnnouncement = (
		<Announcement
			activePage={activePage}
			handleHideAnnouncement={hide => handleShowSunsetAnnouncement(!hide)}
			icon={icon}
			message={formatMessage({id: 'WE_ARE_SUNSETTING_DEVELOPMENT'})}
			showAlertButtonText={formatMessage({id: 'LEARN_MORE'})}
			alertTitle={formatMessage({id: "THE_FUTURE_OF_ELECTRONIC"})}
			alertCloseButtonText={formatMessage({id: 'GO'})}
			alertCloseCallback={(b) => {
				if(b) {
					window.open("https://ethsign.xyz", "_self");
				}
			}}
			alertCustomComponent={
				<div className="flex flex-col">
					<div className="mt-2">{formatMessage({id: 'WE_ARE_SUNSETTING_DEVELOPMENT_FULL'})}</div>
				</div>
			}
		/>
	)

	const nonavbar = ['create', 'upload', 'revision', 'sign', 'landing'];
	return (
		<>
		{!nonavbar.includes(activePage) ?
		<>
			<div className={`z-30 w-full`}>
				<nav className={`select-none bg-white ${loggedIn && 'box-shadow-nav z-30'}`}>
					{announcementShowing &&
						announcement
					}
					{showSunsetAnnouncement &&
						sunsettingAnnouncement
					}
					<div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
						<div className="relative flex items-center justify-between h-16">
							{loggedIn?
								<>					
								<div className="flex w-full">
									
									<div className="flex sm:flex-1 justify-center">
										<div className="my-auto mr-auto flex">
											<NavbarLinks activePage={activePage} activeClass={'active'}/>
										</div>
									</div>
									<Link className="flex-1 flex justify-center " to="/">
										<img className="h-16 z-10 w-auto select-none" src={logoWithBG} alt="Ethsign"/>
									</Link>
									<UserMenu ethAlias={ethAlias} ethAvatar={ethAvatar} provider={provider} logout={logout} changeNetwork={(chain)=>changeNetwork(chain)} fm={fm} torus={torus} ethAccount={ethAccount} handleShowProfile={showMenu} showProfile={showProfile} handleOpen={handleOpen}/>
								</div>
								
								</>
							:
								<div className="flex-1 flex items-center justify-between sm:items-stretch sm:justify-between">
									<Link className="flex-shrink flex items-center" to="/">
										<img className="h-12 z-10 w-auto" src={logo} alt="Ethsign"/>
									</Link>
								</div>
							}
						
						</div>
					</div>
				</nav>
			</div>

		</>
		:
		<>
			{announcementShowing &&
				announcement
			}
			{showSunsetAnnouncement &&
				sunsettingAnnouncement
			}
		</>
		}
		</>
	);
}

export default withRouter(Navigation);
