import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
const NavbarLinks = (props) => {
    const {activePage, activeClass} = props;
    
    return(
        <>
            <span className="relative">
                <Link to="/contracts" className={`select-none text-10 xs:text-15 font-semibold block sm:px-3 py-2 rounded-md font-medium  hover:bg-blue-10  text-gray-80 ${activePage == 'contracts' && activeClass == "mini-menu-active" && "mini-menu-active"}`}>
                <FormattedMessage id='MY_CONTRACTS'/>
                </Link>
                {activePage=='contracts' && activeClass=="active" && <div className="active"></div>}
            </span>
        </>
       );
}
export default withRouter(NavbarLinks);