import React from 'react';
import FallBack from '../assets/fall-back.svg';
import { FormattedMessage } from 'react-intl';

export default function FallbackComponent() {
    return (
        <div className="h-screen flex  flex-col justify-center items-center">
             <img src={FallBack} alt=""/>
            <div className="flex flex-col items-center justify-center">
                <span className="sm:text-30 font-bold text-orange-100"><FormattedMessage id='SOMETHING_WENT_WRONG'/></span>
                <span className="sm:text-25"><FormattedMessage id='ETHSIGN_TECH_TEAM_IS_WORKING_ON_THE_ERROR'/></span>
            </div>
        </div>
    )
}