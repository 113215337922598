import React, {useRef, useEffect} from 'react';
import WebViewer from '@pdftron/webviewer';
import { getFileArray, downloadBlob, getCommentArrs} from '../../helpers/download';
import { useIntl } from 'react-intl';
import {getStringFromDecryptedArr} from '../../helpers/pdf'
import { storeNotif } from '../../helpers/dashboard';

const DownloadPDF = (props) => {
  const {data, handleShowWebviewer, handleShowDownloadPopup } = props;
  const {doc, password} = data
  const viewer = useRef(null);

  const { formatMessage } = useIntl();


  useEffect(() => {
    (async () => {
      WebViewer(
          {
            licenseKey: 'Buildblock Tech Pte. Ltd.:OEM:EthSign::B+:AMS(20220926):60A5E4AD0457F80A7360B13AC982537860615F858748CDEA9BF51DE6240C48AE4AB4B6F5C7',
            path: '/webviewer2/lib',
            fullAPI: true,
            disabledElements: [
              'header',
              'toolsHeader',
            ]
          },
          viewer.current,
        ).then( async (instance) => {
          const {docViewer, annotManager, Annotations} = instance
          const fileArr = await getFileArray(password, doc.documentKey, doc.storageProvider, false, formatMessage)
          if(fileArr == false){
            handleShowWebviewer(null)
            document.getElementById('password_input').value='';
          }else{
            storeNotif(formatMessage({id: 'DOWNLOADING'},{name: doc.name}), formatMessage({id: 'DOWNLOAD_BEGIN'}), 'success')
            const blob = new Blob([fileArr], { type: 'application/pdf' });
            instance.loadDocument(blob, { filename: doc.name });
  
            // not show signatures in notesPanel
            // instance.setCustomNoteFilter(annot => (!(annot instanceof instance.Annotations.FreeHandAnnotation)  ))
  
            
            docViewer.on('documentLoaded', async () => {
              const commentArrs = await getCommentArrs(doc.commentData, password, formatMessage)
              await Promise.all(
                commentArrs.map(async (commentArr, id)=>{
                  const xfdf = await getStringFromDecryptedArr(commentArr, formatMessage)
                  await annotManager.importAnnotations(xfdf)
                  return Promise.resolve('success')
                })
              )

              const pdf = docViewer.getDocument();
              const xfdfString = await annotManager.exportAnnotations();
              const options = { xfdfString, flatten: true };
              const data = await pdf.getFileData(options);
              const arr = new Uint8Array(data);
              const blob = new Blob([arr], { type: 'application/pdf' });
              downloadBlob(blob, doc.name)

              handleShowWebviewer(null)
              handleShowDownloadPopup(false)
            });
          }
          

          
        });
      })();
    }, []);
  return(
    <div ref={viewer} className="absolute invisible">

    </div>
  );
}

export default DownloadPDF;