

import { networkinfo } from './networkinfo';
export const getTimestampOfFutureBlock = async (block, currentBlock, provider) => {
    let expectedDate;
    try {
        expectedDate = new Date(Date.now());
        await provider.getNetwork().then((network) => {
            let network_info = networkinfo.get(network.chainId)
            if (network_info){
                expectedDate.setSeconds(expectedDate.getSeconds() + (network_info.blockSeconds * (block - currentBlock)));
            }
            
        })
    } catch(error) {
        console.log("Error: Couldn't find network info for chainId " + network.chainId);
        return null;
    }
    return expectedDate;
}
export const changeEstimateExpiration = (useBlock, userInput, currentBlock, provider, handleEstimatedExpirationDate, handleEstimatedExpirationBlock ) => {
    if(useBlock) {
        // Calculate the estimated expiration date using the block in the expiry input
        const expectedDate = new Date(Date.now());
        provider.getNetwork().then((network) => {
            try {
                if(userInput < currentBlock) {
                    handleEstimatedExpirationDate(null);
                } else {
                    expectedDate.setSeconds(expectedDate.getSeconds() + (networkinfo.get(network.chainId).blockSeconds * (userInput - currentBlock)));
                    handleEstimatedExpirationDate(expectedDate);
                }
            } catch(error) {
                console.log("Error: Couldn't find network info for chainId " + network.chainId);
            }
        })
    } else {
        // Calculate the estimated expiration block using the date provided in the expiry input
        let inputDate = new Date(userInput);
        // The above date gets added in GMT-0, but when we print it, it switches to local timezone, altering the date.
        // Add back the timezone offset to make sure it interprets the date input as local time.
        // 1440 minutes in a day. We want this to expire at end of day, so add 1439 to expire at 11:59PM.
        inputDate.setMinutes(inputDate.getMinutes() + inputDate.getTimezoneOffset() + 1439);
        let expectedBlock = currentBlock;
        
        const seconds = (inputDate - new Date(Date.now())) / 1000;
        provider.getNetwork().then((network) => {
            try {
                if(seconds < 0) {
                    handleEstimatedExpirationBlock(null);
                } else {
                    expectedBlock += (seconds / networkinfo.get(network.chainId).blockSeconds);
                    handleEstimatedExpirationBlock(Math.ceil(expectedBlock));
                }
            } catch(error) {
                console.log("Error: Couldn't find network info for chainId " + network.chainId);
            }
        })
    }
}