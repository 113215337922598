import React from 'react';
import SignerDetail from './SignerDetail';
import Identicon from 'react-identicons';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import { getAliasOrAddress } from '../../helpers/signer';
import Loader from '../UI/loader';
const Details = (props) => {
    const {doc, ensEnabled, ethAccount, signers, initiator} = props;
    const { formatMessage }  = useIntl();

    
   

    return(
        <div className="px-5 flex flex-col text-gray-300">
            {initiator == null ?
                <div className="pb-5 flex justify-center"><Loader /></div>
            :
                <>
                    <div className="pb-5">
                        <div className="select-none font-bold pb-1">
                            <FormattedMessage id='CREATER'/>
                        </div>
                        <div className="flex py-2">
                            <div  className="identicon mr-3 border border-orange-500 rounded-full my-auto h-auto">
                                
                                {initiator.avatar ? 
                                    <img className="w-11 h-11 rounded-full object-cover" src={initiator.avatar} />
                                    :
                                    <div className="p-2">
                                        <Identicon string={initiator.address?.toLowerCase()} size="25" palette={['#D7EEFF', '#eef2ff', '#991A1A', '#FFDECC', '#E98234', '#D98234','#EE9F63', '#464648']} />
                                    </div>
                                }
                                
                            </div>
                            <div className="flex flex-col">
                                <div className="py-1">{initiator && getAliasOrAddress(ethAccount, initiator, ensEnabled, formatMessage)}</div>
                            </div>
                        </div>
                    </div>
                    {signers?.length>0 && 
                        <div>
                            <div className="select-none font-bold pb-1">
                                <FormattedMessage id='SIGNERS'/>
                            </div>
                            <div className="flex flex-wrap ">
                                {signers.map((signer,i)=>{
                                    return(
                                        <SignerDetail key={"signer-detail" + i} ethAccount={ethAccount} signer={signer}  ensEnabled={ensEnabled} />
                                    );
                                })}
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    );
}
export default Details;