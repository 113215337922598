import React, {useEffect} from 'react';
const Prepare = (props) => {

    // if using a class, equivalent of componentDidMount 
    useEffect(() => {

      
    }, []);
  
    return(
        <>
        <div className="bg-gray-400 flex-grow  flex flex-col">
            <div className="mx-auto flex-grow flex flex-col h-full w-full text-gray-300 text-15">
                {/* webviewer */}
            </div>
        </div>
        
        <nav className="box-shadow-footer z-10 sticky bottom-0">
            <div className="max-w-7xl mx-auto px-2  ">
                <div className="relative flex items-center justify-between h-16">
                    <div className="flex w-full">
                        <div className="flex-1"></div>
                        <div className="flex flex-grow justify-center items-center text-gray-80 font-medium" >Ethsign Sample Agreement.pdf</div>
                        <div className="flex flex-1 justify-end"> 
                            <button className="text-white   mr-5 w-28 py-2 font-medium bg-gray-300 hover:bg-gray-600 rounded-sm">
                                Back
                            </button>
                            <button className="text-white w-28 py-2 font-medium bg-orange-500 hover:bg-orange-600 rounded-sm">
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
            
        </>
    );
}
export default Prepare;