import React, {useState} from 'react'
import Identicon from 'react-identicons';
import { useIntl } from 'react-intl';
import { getAlias, getAliasOrAddress } from '../../helpers/signer';
const SignerDetail = (props) => {
    const {signer, ensEnabled, ethAccount, widen} = props;
    const [truncate, handleTruncate] = useState(true);
    const { formatMessage } = useIntl();

    if(!signer){
        return null
    }

    return(
        <div className="flex py-2 max-w-full flex-grow">
            {widen ? (
            <>
                <div className="flex flex-col w-80">
                    <div className="flex flex-row">
                        <div className="mr-3 border border-orange-500 rounded-full my-auto h-auto">
                            {signer.avatar ? <img className="rounded-full w-11 h-11 object-cover"  src={signer.avatar} />
                            :
                            <div className="p-2">
                                <Identicon string={signer.address?.toLowerCase()} size="25" palette={['#D7EEFF', '#eef2ff', '#991A1A', '#FFDECC', '#E98234', '#D98234','#EE9F63', '#464648']} />
                            </div>
                            }
                        </div>
                        <div className="py-1">{getAlias( ethAccount, signer, ensEnabled, formatMessage)}</div>
                        <div className={`${signer.fullySigned==true? "text-green-200":"text-orange-500"} ml-auto py-1 select-none`}>{signer.fullySigned==true? formatMessage({id: 'SIGNED'}):formatMessage({id: 'WAITING'})}</div>
                    </div>
                    <div className={`py-5 text-xs`} onClick={() => handleTruncate(!truncate)}>{signer.address}</div>
                </div>
            </>
            )
            :
            (
            <>
                <div className="identicon mr-3 border border-orange-500 rounded-full my-auto h-auto">
                    {signer.avatar ? <img className="rounded-full w-11 h-11 object-cover" src={signer.avatar} />
                    :
                    <div className="p-2">
                        <Identicon string={signer?.address?.toLowerCase()} size="25" palette={['#D7EEFF', '#eef2ff', '#991A1A', '#FFDECC', '#E98234', '#D98234','#EE9F63', '#464648']} />
                    </div>
                    }
                </div>
                <div className="flex flex-col">
                    <div className="py-1">{getAliasOrAddress(ethAccount, signer, ensEnabled, formatMessage)}</div>
                    <div className={`${signer.fullySigned==true? "text-green-200":"text-orange-500"} py-1 select-none`}>{signer.fullySigned==true? formatMessage({id: 'SIGNED'}):formatMessage({id: 'WAITING'})}</div>
                </div>
            </>
            )}
        </div>
    );
}
export default SignerDetail;