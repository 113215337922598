/**
 * @author pzhu
 */
import React from 'react';
import TermsNavbar from "./TermsNavbar";
import TermsHoc from "./TermsHoc";
import '../../styles/terms.scss';


const INTRODUCTION =  "1. Introduction";
const DEFINE_AND_INTERPRETATION = "2. Definitions and Interpretation";
const USERS = "3. Users";
const ACCOUNTS = "4. Accounts";
const SERVICES = "5. Services";
const ACCESS_AND_USE_POLICY = "6. Access and Use Policy";
const UPDATES_MODIFICATIONS_AND_AVAILABILITY = "7. Updates, Modifications and Availability";
const INTELLECTUAL_PROPERTY_RIGHTS = "8. Intellectual Property Rights";
const THIRD_PARTY_MATERALS = "9. Third Party Materials";
const LIMITATION_OF_LIABILITIES = "10. Limitation of Liabilities";
const INDEMNIFICATION = "11. Indemnification";
const TERM_AND_TERMINATION = "12. Term and Termination";
const REPORT_IP_INFRIGEMENT = "13. Report IP Infringement";
const NOTICES = "14. Notices";
const MISCELLANEOUS = "15. Miscellaneous";
const GOVERNING_LAW_AND_DISPUTE_RESOLUTION = "16. Governing Law and Dispute Resolution";

export const TERM_OF_USE_ELE = [INTRODUCTION, DEFINE_AND_INTERPRETATION, USERS, ACCOUNTS, SERVICES, ACCESS_AND_USE_POLICY, UPDATES_MODIFICATIONS_AND_AVAILABILITY, INTELLECTUAL_PROPERTY_RIGHTS, THIRD_PARTY_MATERALS, LIMITATION_OF_LIABILITIES, INDEMNIFICATION, TERM_AND_TERMINATION, REPORT_IP_INFRIGEMENT, NOTICES, MISCELLANEOUS, GOVERNING_LAW_AND_DISPUTE_RESOLUTION];
const TermsOfUse = () =>{                                                                                                    
    return (
        <>
            <TermsNavbar elements={TERM_OF_USE_ELE} />
            <div className="text-8 mt-8 sm:w-3/4  px-4 modal-content sm:text-xs sm:pr-12" id= "modal-content">
                <h4 className="m-0 text-13 sm:text-2xl">EthSign Term of Use</h4>
                <div id={INTRODUCTION}>
                    <h4 className="text-10 sm:text-sm" >{INTRODUCTION}</h4>
                    <p>
                        1.1 Thank you for visiting the EthSign website (the “Website”). The terms and conditions set out in this terms of use agreement (the “Terms”) govern the access to and use of this Website and any associated software (collectively, the “EthSign Platform”) and the services provided by Buildblock Tech Pte. Ltd. (“EthSign”, “we”, “us”, or “our”) via the EthSign Platform (collectively, the “Services”).
                    </p>
                    <p>
                        1.2 These Terms govern all users of the EthSign Platform and/or Services (collectively, the “Users” and each a “User”, “you”, “your” or “yours”). Please read the Terms. Your use and access of the EthSign Platform and/or Services shall constitute your acceptance and agreement to be legally bound by the Terms and that the Terms shall be enforceable like any written contract signed by you. If you do not agree to all of the Terms, please discontinue your access and use of the EthSign Platform and/or Services.
                    </p>
                    <p>
                        1.3 In particular, please note that in using the EthSign Platform and/or Services, you may be required to provide EthSign with certain personal data - our use, collection, storage, retention and/or disclosure of such personal data shall be subject to the Personal Data Protection Act (No. 26 of 2012) (the “PDPA”) of Singapore as well as EthSign’s Privacy Policy, incorporated herein by reference. Your use of the EthSign Platform signifies that you have read, understand and agree to the terms of the Privacy Policy. If you have any questions on the Privacy Policy, please contact us at  <span className='font-bold'>info@ethsign.xyz</span>.
                    </p>
                    <p>
                        1.4 EthSign reserves the right, in its sole and absolute discretion, to amend or vary the Terms any time without prior notice to Users. The amended Terms will be posted on this Website. Users’ continued use and access of the EthSign Platform and/or Services after any such changes constitute acceptance of the latest Terms. For the avoidance of doubt, references to “Terms” shall include such Terms as amended from time to time by EthSign and posted on this Website.
                    </p>
                </div>
                <div id={DEFINE_AND_INTERPRETATION}>
                    <h4 className="text-10 sm:text-sm" >{DEFINE_AND_INTERPRETATION}</h4>
                    <div>
                        <p>2.1 Unless inconsistent with the context or stated otherwise, the following words and expressions shall have the following meanings in these Terms:</p>
                        <p>
                            <span className='font-bold'>“Authorised User” </span> shall have the meaning ascribed to it in Clause 4.3;
                        </p>                        
                        <p>
                            <span className='font-bold'>“Content” </span> means any data, designs, text, software, music, sound, photographs, graphics, video, messages or other materials, including but without limitation to, company logos, trademarks, copyrighted materials and Third Party Materials, displayed, published or made available on or through the EthSign Platform;
                        </p>

                        <p>
                            <span className='font-bold'>“Intellectual Property Rights” </span> means all intellectual property rights (whether registered or not) including, but not limited to, copyright (including copyright in computer software), patents, logos, trademarks or business names, design rights, database rights, know-how, trade secrets and rights of confidence in connection with the EthSign Platform, Services and/or Content;
                        </p>
                        
                        <p>
                            <span className='font-bold'>“EthSign Technology”</span> means EthSign’s proprietary technology underlying the EthSign Platform and/or Services, including internet operations design, content, software tools, hardware designs, algorithms, software (in source and object forms), source codes, user interface designs, architecture, class libraries, objects and documentation (both printed and electronic), know-how, trade secrets and any related intellectual property rights throughout the world (whether owned by EthSign or its licensors) in connection with the EthSign Platform and/or Services, and also including any derivatives, improvements, enhancements or extensions of the technology conceived, reduced to practice, or developed by EthSign and/or its agents from time to time that are not uniquely applicable to Users or that have general applicability in the art;
                        </p>
                        <p>
                            <span className='font-bold'>“Parties”</span> means collectively EthSign and the relevant User, and a “Party” means any of them; 
                        </p>
                        <p>
                            <span className='font-bold'>“Refund”</span> shall have the meaning ascribed to it in Clause 5.5;
                        </p>
                        <p>
                            <span className='font-bold'>“Transaction” </span> shall have the meaning ascribed to it in Clause 5.1;
                        </p>
                        <p>
                            <span className='font-bold'>“Third Party Materials” </span> means any website, product, service, information, advertisement or other materials which are not owned or controlled by EthSign which may be accessible via or through the EthSign Platform;
                        </p>
                        <p>
                            <span className='font-bold'>“Third Parties”</span> means the independent parties the Users have interacted with via the EthSign Platform;
                        </p>
                        <p>
                            <span className='font-bold'>“User(s)”</span> refer to user(s) of the EthSign Platform and/or Services;
                        </p>
                        <p>
                            <span className='font-bold'>“User’s Content”</span> means any data, designs, text, software, music, sound, photographs, graphics, video, messages or other materials, including but without limitation to, company logos, trademarks and copyrighted materials submitted, displayed, published or made available on or through the EthSign Platform by any User;
                        </p>
                        <p>
                            <span className='font-bold'>“User Dispute”</span> shall have the meaning ascribed to it in Clause 5.4; and
                        </p>
                        <p>
                            <span className='font-bold'>“User Information”</span> shall have the meaning ascribed to it in Clause 5.1.
                        </p>
                    </div>
                    <p> 2.2	In these Terms:</p>
                    <p>(a)  references to Clauses are to the clauses herein;</p>
                    <p>(b)  the headings are for convenience only and shall not affect the interpretation of the Terms;</p>
                    <p>(c)  unless the context otherwise requires or permits, references to the singular number shall include references to the plural number and vice versa; and words denoting any gender shall include all genders;</p>
                    <p>(d)  the expression “person” means any individual, corporation, partnership, association, limited liability company, trust, governmental or quasi-governmental authority or body or other entity or organization; and</p>
                    <p>(e)  the expression “written”, unless otherwise stated, include written communication by electronic means.</p>       
                </div>
                <div id={USERS}>
                    <h4 className="text-10 sm:text-sm" >{USERS}</h4>
                    <p>
                        3.1 Users must be at least 18 years old and able to enter into legally binding contracts to access and use the EthSign Platform. By accessing or using the EthSign Platform and/or Services, Users represent and warrant that they are 18 years old or older and have full legal capacity and authority to enter into a binding contract. If a corporate entity (including corporations, limited liability partnerships or partnerships) is accessing or using the EthSign Platform and/or Services, the person using the Services for and on behalf of a corporate entity represents and warrants that he has the requisite power and authority to bind such corporate entity to the Terms and that the Terms constitute legally binding obligations of such corporate entity.
                    </p>
                    <p>
                    3.2 For know-your-customer, anti-money laundering and/or other compliance purposes, and to the extent permitted by law, we reserve the right, but have no obligation, to (a) request Users to provide a form of personal identification or other such information (for the avoidance of doubt, any personal data provided will be subject to our Privacy Policy), (b) undertake additional steps to verify the identity or background of any User, and/or (c) carry out checks on Users in or against third party databases or other sources.
                    </p>
                </div>
                <div id={ACCOUNTS}>
                    <h4 className="text-10 sm:text-sm">{ACCOUNTS}</h4>
                    <p>4.1 In order to use the EthSign Platform and the Services, you will have to provide details of your crypto wallet (MetaMask/Imtoken) account which may include some of your personal data, including your name, address, and contact details (for the avoidance of doubt, any personal data which we may collect, use and/or disclose will be subjected to our Privacy Policy). You confirm and warrant that the information provided by you is complete, accurate and up-to-date. If there are any changes to such information, you undertake to promptly update such information on the EthSign Platform via your crypto wallet account or otherwise. We reserve the right, in our sole and absolute discretion, to approve or refuse the usage of the EthSign Platform by any person at any time.</p>
                    <p>4.2 By using the EthSign Platform:</p>
                    <p>
                        (a)you hereby authorise EthSign to collect, store, retain, process, use and/or disclose your personal data, including your contact information which may be provided to EthSign during the course of your usage of the EthSign Platform (whether via MetaMask, Imtoken or otherwise) in accordance with our Privacy Policy; and
                    </p>
                    <p>
                        (b)you hereby expressly agree to receive communications by way of SMS, instant messaging services and/or e-mails from EthSign and/or other third parties relating to the products and/or services provided through the EthSign Platform. You may unsubscribe or opt-out from receiving communications through SMS and e-mail anytime by using the opt-out option provided in such communications or sending us an e-mail to opt-out at  <span className='font-bold'>info@ethsign.xyz</span>.
                    </p>
                    <p>4.3 Users shall be responsible for maintaining the confidentiality of their crypto wallet accounts, their passwords to access their crypto wallet accounts, any information contained therein and any and all activities that occur under their crypto wallet accounts. Users may designate any other individuals to access, use or administer their crypto wallet accounts for and on their behalf (“Authorised Users”), but Users shall remain fully liable under the Terms for all acts and omissions of their respective Authorised Users, whether or not such Authorised Users have acted in accordance with Users’ instructions. Users agree to (a) immediately notify EthSign of any unauthorised use of their crypto wallet accounts, password, or any other breach of security, and (b) ensure that Users or Authorised Users properly exit and log out from their the EthSign Platform at the end of each session. EthSign will not be liable for any loss or damage arising from Users’ failure to comply with this provision. Users may be held liable for any losses incurred by EthSign and/or Third Parties caused by or arising from their failure to comply with this provision. </p>
                    <p>4.4 You agree that we have shall not be liable for the deletion or failure to store any data, information or other Content submitted by you to the EthSign Platform.</p>
                </div>
                <div id={SERVICES}>
                    <h4 className="text-10 sm:text-sm">{SERVICES}</h4>
                    <p>5.1 The Services comprise of EthSign providing a software solution which provides a dencentralized, versioned electronic agreement signing services which facilitate a user-friendly process for Users securely via the EthSign Platform. </p>
                    <p>Where applicable, we shall procure that the information of the Users (“User Information”) will be passed directly to the Third Parties to initiate and process the service and documentation between Users and the Third Parties (“Transaction”) and we shall not collect nor retain the User Information. For the avoidance of doubt, we shall not be liable in any way to the Third Parties, the User or any other person arising from, in relation to or in connection with the Transaction.</p>
                    <p>5.2 The terms and conditions of the aforementioned shall be governed by a separate contract between the Users and the Third Parties. We have no control over, and shall not be responsible or liable for, any such terms and conditions in respect thereof, the performance by the Third Parties and/or any act or omission to act by the Third Parties. Where Users accept the provision of products or services by Third Parties, Users shall be deemed to have read and accepted all terms and conditions imposed by the Third Parties in respect thereof.</p>
                    <p>5.3 For the avoidance of doubt and where applicable, such Third Parties are solely responsible for their documentation or services offered or supplied to Users, and we shall not be responsible for and do not provide any representation, warranty or guarantee whatsoever with respect to (a) the quality, safety, suitability, or legality of any product or service of Third Parties, (b) the truth or accuracy of any product or service descriptions of Third Parties, or (c) the performance or conduct of Third Parties. For the avoidance of doubt, such Third Parties (and not EthSign) shall be responsible for rectifying, repairing and/or replacing any defective products or services supplied by such Third Parties to Users.</p>
                    <p>5.4 In the event of a dispute, claim or other complaint raised by any User in relation to the services or otherwise by Third Parties(“User Dispute”), such Third Parties shall be solely responsible for resolving such User Dispute and EthSign shall not be obliged to mediate or resolve any User Dispute and/or any dispute or disagreement between Users and Third Parties. In the event that we elect to investigate any such dispute, the Users shall fully indemnify and hold harmless against EthSign promptly upon written demand against any and all losses which may be incurred or suffered by EthSign in connection with investigating and/or handling such dispute.</p>
                    <p>5.5 In the event that any payment made by a User to the Third Parties pursuant to the Transaction is subject to a chargeback or refund for any reason whatsoever (including but not limited to pursuant to a product recall) (“Refund”), we shall not be responsible for procuring that the User receives such chargeback or Refund.</p>
                </div>
                <div id={ACCESS_AND_USE_POLICY}>
                    <h4 className="text-10 sm:text-sm">{ACCESS_AND_USE_POLICY}</h4>
                    <p>6.1 Subject to your full compliance with the Terms and upon the successful connection of your crypto wallet accounts with EthSign, EthSign grants you a non-exclusive, limited privilege to access and use the EthSign Platform. You agree to (a) access and use the EthSign Platform solely for legitimate purposes, and (b) transact with EthSign, the Third Parties and/or any other person arising from or in connection with the EthSign Platform and/or the Services, in accordance and compliance with the Terms and any applicable law (including any anti-bribery laws). A breach of this provision will result in immediate termination of your access to or use of the EthSign Platform and/or Services.</p>
                    <p>6.2 In accessing and using the EthSign Platform and/or Services, you agree to not engage in activities that may adversely affect the features, functions or use of the EthSign Platform by EthSign and/or other Users. In particular, you shall not reverse engineer, modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the EthSign Platform and/or the Services.</p>
                    <p>6.3 In accessing and using the EthSign Platform and/or Services, you hereby confirm that the electronic signature(s) that you have added, created and/or inserted and all associated electronic record(s) have the same effect as handwritten signature(s) or physical record(s), whether in the country in which such signature(s) have been added, created or otherwise inserted or elsewhere. </p>
                    <p>6.4 To the extent where the EthSign Platform allows you to post, submit, upload, transmit or make available any User’s Content, you shall be responsible for such User’s Content and agree that (a) you will ensure that such User’s Content is not offensive and is in accordance with applicable laws, (b) although all intellectual property rights subsisting in any User Content will be owned by you or your licensors, you hereby grant EthSign and/or its subsidiaries and affiliates a non-exclusive, perpetual, royalty-free, worldwide, transferrable and sub-licensable right to use, reproduce both electronically and otherwise, display, distribute, modify, adapt, publish, translate, and create derivative works from any and all such User Content (excluding any personal data) on any medium (such as the EthSign Platform and any social media platform) for any purpose including advertising and marketing the EthSign Platform or Services, and (c) we are under no obligation to store, retain, publish or make available any User Content uploaded by you and that you shall be responsible for creating backups of any such User’s Content if necessary. By posting, submitting or uploading any User’s Content to EthSign, you further represent and warrant to EthSign that you own or have the full unrestricted right to use and permit us to use and licence such User’s Content in the manner stated in these Terms and shall indemnify us against all losses incurred by EthSign in the event that any of the above representations and warranties do not hold true.</p>
                    <p>6.5 We cannot guarantee the identity of any other Users with whom you may interact with in the course of using the EthSign Platform. We also cannot guarantee the authenticity and accuracy of any User’s Content which other Users or Third Parties may provide on the EthSign Platform. All User’s Content accessed by you using the EthSign Platform is at your own risk and you will be solely responsible for any damage or loss to any party resulting therefrom.</p>
                    <p>6.6 Under no circumstances shall EthSign be responsible or liable in any way for any Content displayed on the EthSign Platform, including but not limited to any errors or omissions in any Content, or any loss or damage of any kind incurred in connection with the use of or exposure to any content posted, emailed, accessed, transmitted, or otherwise made available via the EthSign Platform.</p>
                    <p>6.7 You shall solely be responsible for maintaining the necessary computer equipment, internet connections and telecommunication charges that may be required to access, use and transact on the EthSign Platform.</p>
                </div>
                <div id={UPDATES_MODIFICATIONS_AND_AVAILABILITY}>
                    <h4 className="text-10 sm:text-sm">{UPDATES_MODIFICATIONS_AND_AVAILABILITY}</h4>
                    <p>7.1 EthSign reserves the right to modify, upgrade, suspend or discontinue (whether temporarily or permanently) any features and functions of the EthSign Platform from time to time, and reserve the right to make any upgrades, updates, modifications and changes to the same as it deems fit. Users understand that such upgrades or changes may result in interruption, modification, disruption, failure, delay or discontinuation of the EthSign Platform and/or Services or any function or feature thereof. </p>
                    <p>7.2 Users acknowledge that they access the EthSign Platform at their own risk and that EthSign shall have no liability, responsibility or obligation for any interruption, modification, disruption, failure, delay, suspension or discontinuation in respect of the EthSign Platform or any function or feature thereof (including any links to any Third Parties) for any reason whatsoever.</p>
                </div>
                <div id={INTELLECTUAL_PROPERTY_RIGHTS}>
                    <h4 className="text-10 sm:text-sm">{INTELLECTUAL_PROPERTY_RIGHTS}</h4>
                    <p>8.1 The EthSign Platform, text, graphics, user interfaces, visual interfaces, sounds, artwork, designs, and computer code (and any intellectual property rights relating thereto) on the EthSign Platform is owned and/or licensed by EthSign and the design, structure, selection, coordination, expression, look and feel and arrangement on the EthSign Platform is protected by copyright, patent and trademark laws, and various other intellectual property laws, as the case may be.</p>
                    <p>8.2 The EthSign Platform, Content and EthSign Technology underlying the EthSign Platform and Services are the property of EthSign and/or its licensors, and are protected by copyright and/or other intellectual property rights. Except as expressly authorised by EthSign, Users agree to not (whether directly or indirectly) modify, adapt, copy, frame, scrape, rent, lease, loan, sell, distribute, publish, upload, display, transmit or create derivative works based on the EthSign Platform and/or Content including any template design layouts provided by EthSign’s Platform (including any modifications and/or customizations made by the Users to such template design layout using the EthSign Platform), in whole or in part and in any manner or form. The word “EthSign” and associated logos are the trademarks, trade names and/or service marks of EthSign, and Users agree not to display or use in any manner such names and/or marks without EthSign’s prior written authorisation. In using the EthSign Platform, Users shall not (whether directly or indirectly) engage in or use any data mining, robots, scraping or similar data gathering or extraction methods. Users further acknowledge that they have no right to have access to any aspect of the EthSign Platform in source-code form, and agree not to copy, modify, adapt, create a derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover any source code, sell, assign, sublicense, or otherwise transfer any right in EthSign Technology. Any uses of the EthSign Platform and/or Content not expressly permitted herein are prohibited, and any rights thereof not expressly granted herein are reserved by EthSign.</p>
                    <p>8.3 By using the EthSign Platform and/or Services, Users will not acquire any right, title or interest in or to the Intellectual Property Rights except for the limited right to use the EthSign Platform and/or Services granted to Users pursuant to the Terms. All goodwill generated from the use of the EthSign Platform, Content and/or Services will inure to EthSign’s exclusive benefit.</p>
                    <p>8.4 To the extent where Users provide EthSign with any suggestions, comments, improvements, ideas or other feedback (“<span className="font-bold">Feedback</span>”), Users hereby assign ownership of all intellectual property rights subsisting in that Feedback to EthSign and agree that EthSign can use and share such Feedback for any purpose in its sole and absolute discretion.</p>
                    <p>8.5 Other company, product, and service names and logos used and displayed via the EthSign Platform may be trademarks or service marks of their respective owners who may or may not be endorsed by, or affiliated with, or connected to EthSign.</p>
                </div>
                <div id={THIRD_PARTY_MATERALS}>
                    <h4 className="text-10 sm:text-sm">{THIRD_PARTY_MATERALS}</h4>
                    <p>The EthSign Platform may contain links to Third Party Materials. References or links to Third Party Materials on the EthSign Platform do not constitute any endorsement or recommendation by EthSign of such Third Party Materials. When Users access Third Party Materials, Users do so entirely at their own risk. Users hereby represent and warrant that they have read and agree to be bound by all applicable policies of any Third Party Materials relating to the use of their services and act in accordance with those policies, in addition to Users’ obligations under the Terms. EthSign has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed in any Third Party Materials. In addition, EthSign will not and cannot monitor, verify, censor or edit the content of any Third Party Materials. Users expressly indemnify fully and hold harmless EthSign from any and all liability arising from their respective use of any Third Party Materials.</p>
                </div>
                <div id={LIMITATION_OF_LIABILITIES}>
                    <h4 className="text-10 sm:text-sm">{LIMITATION_OF_LIABILITIES}</h4>
                    <p>10.1 You expressly agree that, to the maximum extent permitted by applicable law, the EthSign Platform and/or Services are provided by EthSign on an “as is” and “as available” basis without warranty of any kind, express, implied, statutory or otherwise, including the implied warranties of title, non-infringement, merchantability or fitness for a particular purpose, unless specifically provided otherwise. Your interactions and transactions with organisations and/or individuals (including Third Partiess and/or other Users) found on or through the EthSign Platform, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such organisations and/or individuals. You should conduct whatever investigation you feel necessary or appropriate before proceeding with any online or offline transaction with any of these third parties. Without prejudice to and in addition to Clause 5.4, in the event that you have a dispute with one (1) or more other Users or third parties, EthSign, its shareholders, subsidiaries, officers, directors, employees, agents and successors shall have no obligations and/or liabilities in respect thereof, and you hereby expressly release and waive any rights you have or may have against EthSign, its shareholders, subsidiaries, officers, directors, employees, agents and successors in relation to any claims, demands, losses and/or damages (actual and consequential), costs and/or expenses of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such dispute.</p>
                    <p>10.2 Without limiting the foregoing, EthSign makes no guarantee, representation or warranty in any respect that (a) the EthSign Platform or Services will meet your requirements or expectations, (b) your use of the EthSign Platform or Services will be uninterrupted, timely, free of viruses or other harmful components, secure or error-free, (c) the information and/or results (including the Content) that may be obtained from the use of the EthSign Platform or Services will be effective, accurate, complete, error-free or reliable, (d) the use of the EthSign Platform or Services is compatible with any hardware or other software or equipment, (e) any errors or defects in the EthSign Platform will be corrected, and/or (f) without prejudice and in addition to Clause 5.3, the credentials or description of any products offered by Third Parties are accurate, complete or reliable. No advice or information, whether oral or written, obtained by you from EthSign or through use of the EthSign Platform shall create or constitute any warranty expressly excluded in the Terms unless otherwise specified.</p>
                    <p>10.3 Without prejudice to and in addition to Clause 5.1, EthSign will not be liable for any loss that you may incur arising from, in relation to or in connection with the Transaction.</p>
                    <p>10.4 Without prejudice to and in addition to Clause 5.5, EthSign will not be liable for any loss that you may incur as a consequence of not receiving any chargeback or Refund. You understand and agree that the Third Parties shall be responsible for any chargeback or Refund. </p>
                    <p>10.5 EthSign will not be liable for any loss that you may incur as a consequence of any unauthorized use of your crypto wallet account or information in connection with the EthSign Platform and/or the Services and/or products provided by Third Parties, whether such unauthorised use was with or without your knowledge/consent.</p>
                    <p>10.6 While EthSign has taken reasonable efforts to ensure that all the information on the EthSign Platform is correct, EthSign neither warrants nor makes any representations regarding the quality, correctness, accuracy or completeness of any data, information or service provided pursuant to the EthSign Platform. EthSign shall not be responsible nor liable for the delay or inability to use the EthSign Platform or related functionalities, the provision of or failure to provide functionalities, or for any information, software, functionalities and related graphics obtained through the EthSign Platform, or otherwise arising out of the use of the EthSign Platform, whether based on contract, tort, negligence, strict liability or otherwise.</p>
                    <p>10.7 Without prejudice and in addition to Clause 7.2, EthSign shall not be held responsible for non-availability of the EthSign Platform during periodic maintenance operations or any unplanned suspension of access to the EthSign Platform that may occur due to technical reasons or for any reason beyond our control. You understand and agree that any material or data downloaded or otherwise obtained through the EthSign Platform is done entirely at your own discretion and risk, and that you will be solely responsible for any damage to your computer systems or loss of data that results from the download of such material or data.</p>
                    <p>10.8 You may see advertising material submitted by third parties on the EthSign Platform. Each individual advertiser is solely responsible for the content of its advertising material and we accept no responsibility for the content of such advertising material, including, without limitation, any error, omission or inaccuracy therein.</p>
                    <p>10.9 Subject to these Terms, in no event shall EthSign, its shareholders, subsidiaries, officers, directors, employees, consultants, agents and/or successors, be liable to you or any third party for any (a) loss of profits, (b) loss of opportunity, or (c) special, incidental, indirect, consequential or punitive damages whatsoever, arising out of or in connection with your use of or access to the EthSign Platform, Services and/or products purchased through the EthSign Platform. Further, without prejudice to the generality of Clause 5, EthSign shall not be liable for any products, acts or omissions of the Third Parties and/or Third Party Payment Processor.</p>
                </div>
                <div id={INDEMNIFICATION}>
                    <h4 className="text-10 sm:text-sm">{INDEMNIFICATION}</h4>
                    <p>Without prejudice to anything contained herein, you hereby fully indemnify, defend and hold harmless EthSign, its shareholders, subsidiaries, officers, directors, employees, consultants, agents and/or successors promptly upon written demand against any claims, liabilities, losses, damages, costs and/or expenses which any of the indemnified parties may incur or suffer arising from or in connection with (a) your use of the EthSign Platform or Services, (b) any misrepresentation with respect to the data or information provided by you, (c) your omission, breach or default of the Terms, and/or (c) the breach of any rights of a third party arising from your act(s) or omission to act(s), including any intellectual property rights.</p>
                </div>
                <div id={TERM_AND_TERMINATION}>
                    <h4 className="text-10 sm:text-sm">{TERM_AND_TERMINATION}</h4>
                    <p>12.1 These Terms between EthSign and each User shall take effect upon the User’s access and/or commencement of the use of the EthSign Platform and will remain in full force and effect for as long as the relevant User continues to access and use the EthSign Platform and/or Services. If you object to the Terms or are dissatisfied with the EthSign Platform, please stop accessing or using the EthSign Platform or Services.</p>
                    <p>12.2 EthSign reserves the right, in its sole and absolute discretion, to suspend, discontinue, terminate or modify access that you may have to the EthSign Platform, with or without notice to you, at any time without incurring any liability of any kind whatsoever to you or any third party for any reason (including and not limited to you having infringed, breached, violated, abused, or unethically manipulated or exploited any term or condition of these Terms or anyway otherwise acted unethically). Notwithstanding anything in this Clause, these Terms will survive indefinitely unless and until EthSign chooses to terminate them. EthSign further reserves the right to remove and delete any data in and content provided by you but may preserve your transaction details for purposes of tax, legal or regulatory compliance to the extent permitted under the applicable laws. Any suspected fraudulent, abusive or illegal activity that may be grounds for such suspension, discontinuance, termination or modification may be referred by EthSign to the appropriate law enforcement authorities.</p>
                    <p>12.3 You agree that any breach by you of the Terms may cause irreparable harm to EthSign for which monetary damages would be inadequate, and you consent to EthSign obtaining any injunctive or equitable relief that it deems necessary or appropriate in such circumstances. Such remedies shall be in addition to any other remedies that EthSign may be entitled to under these Terms and/or at law or in equity. If EthSign takes any legal action against you, we will be entitled to recover from you, and you agree to pay, all reasonable attorneys’ fees and costs of such action (on a full indemnity basis), in addition to any other relief that may be granted.</p>
                    <p>12.4 Upon termination of the Terms, the User’s access and right to use the EthSign Platform, Services and other rights hereunder shall terminate. Save for any obligations which are expressed to survive, each Party’s further rights and obligations shall cease immediately, provided that such termination shall not affect a Party’s accrued rights and obligations as at the date of such termination.</p>
                    <p>12.5 Notwithstanding the suspension or termination of your access to the EthSign Platform, you shall remain liable to fulfil all transactions and pay for such transactions that you have already committed to prior to suspension of termination of your access to the EthSign Platform subject to the applicable terms and conditions governing such transactions.</p>
                </div>
                <div id={REPORT_IP_INFRIGEMENT}>
                    <h4 className="text-10 sm:text-sm">{REPORT_IP_INFRIGEMENT}</h4>
                    <p>If you believe the EthSign Platform or any products offered through the EthSign Platform violates your intellectual property, please promptly notify EthSign by emailing us at info@ethsign.xyz. Such notifications should only be submitted by the owner of the intellectual property or an agent authorized to act on his/her behalf. However, any false claim by you may result in the termination of your access to and use of the EthSign Platform or Services. Please provide the following details in the relevant notice: </p>
                    <p>(a)  your contact details, such as your address, telephone number, and/or email;</p>
                    <p>(b)  identify the intellectual property that is being infringed;</p>
                    <p>(c)  identify the infringing item and include sufficient information about where the material is located on the EthSign Platform;</p>
                    <p>(d)  a statement that the information you provided in your notice is accurate, and that you are the intellectual property owner or an agent authorized to act on behalf of the intellectual property owner whose intellectual property is being infringed; and</p>
                    <p>(e)  your physical or electronic signature.</p>
                </div>
                <div id={NOTICES}>
                    <h4 className="text-10 sm:text-sm">{NOTICES}</h4>
                    <p>14.1  All notices from EthSign will be served by email to your registered email by  <span className='font-bold'>info@ethsign.xyz</span> or by general notification on the EthSign Platform. </p>
                    <p>14.2  Any notice shall be deemed to have been received on the day that it is sent (if sent on a business day in Singapore before 5 p.m. local time in the city of the recipient) or otherwise on the next business day. In proving service of such notice it shall be sufficient to show that the electronic mail was correctly addressed and sent and an automatically generated notification of delivery was requested by the sender when sending the electronic mail, even if no such notification was received by the sender.  </p>
                </div>
                <div id={MISCELLANEOUS}>
                    <h4 className="text-10 sm:text-sm">{MISCELLANEOUS}</h4>
                    <p>15.1  Save for the Privacy Policy and the Legal Statement, the Terms constitute the entire agreement between the Parties concerning the EthSign Platform and/or the Services and supersedes all previous terms and conditions, understanding, representations and warranties relating to the same.</p>
                    <p>15.2  You shall not assign, transfer, charge or otherwise deal with all or any of your rights and obligations under the Terms nor grant, declare or dispose of any right or interest in it to any third party. EthSign’s rights and obligations under the Terms may be assigned or transferred by EthSign to any affiliate or third party without your consent. For the avoidance of doubt, these Terms shall be binding on the legal successors and permitted assigns of each Party.</p>
                    <p>15.3  Each provision of the Terms is intended to be severable from the others so that if any provision or term hereof is held to be illegal or invalid for any reason whatever, such illegality or invalidity shall not affect the validity of the remaining provisions and terms of the Terms.</p>
                    <p>15.4  No failure on the part of EthSign to exercise, and no delay on its part in exercising, any right or remedy under these Terms will operate as a waiver thereof, nor will any single or partial exercise of any right or remedy preclude any other or further exercise thereof or the exercise of any other right or remedy. The rights provided in the Terms are cumulative and not exclusive of any rights or remedies provided by law.</p>
                    <p>15.5  Save as otherwise specifically provided in the Terms, EthSign hereunder shall not be liable for any failures or delays in performing its obligations hereunder arising from any Force Majeure Event (defined below), and in the event of any such delay, the time for EthSign’s performance shall be extended by the same duration as the duration of the period during which the performance is prevented or delayed by the Force Majeure Event. “Force Majeure Event” means any circumstance or event beyond the reasonable control of a Party, including any act of God, outbreak, or epidemic or pandemic of any kind, communicable and virulent disease, strike, flood, fire, embargo, boycott, act of terrorism, insurrection, war and hostilities (whether war be declared or not), invasion, act of foreign enemies, rebellion, revolution, insurrection, usurped power of civil war, explosion, civil disturbance (including riots, commotions or disorder), shortage of gas, fuel or electricity, electrical outage, internet service disruptions, electronic system failure, interruption of transportation, governmental order, unavoidable accident, or shortage of labour or raw materials.</p>
                </div>
                <div id={GOVERNING_LAW_AND_DISPUTE_RESOLUTION}>
                    <h4 className="text-10 sm:text-sm">{GOVERNING_LAW_AND_DISPUTE_RESOLUTION}</h4>
                    <p>Irrespective of the country from which Users access or use the EthSign Platform and/or Services, to the extent permitted by any applicable law, the Terms shall be governed and construed in accordance with the laws of Singapore without regard to choice or conflicts of law principles, and Users hereby agree to submit to the exclusive jurisdiction of the courts of Singapore to resolve any claims or disputes which may arise in connection with the Terms.</p>
                </div>
                <p style={{height:"38rem"}}/>
            </div>
        </>
    )
}

export default TermsHoc(TermsOfUse)