import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { withRouter } from 'react-router';
import HistorySidebar from './Dashboard/HistorySidebar';
import HistoryDetail from './Dashboard/HistoryDetail';
import CarretLeftIcon from '../assets/carret_left.svg';
import HistoryVerifyIcon from '../assets/history_verify.svg';
import menu from '../assets/menu.png'
import { loadData } from '../helpers/graphql';
import Loader from './UI/loader';
import { FormattedMessage, useIntl } from 'react-intl';
import { storeNotif } from '../helpers/dashboard';

const History = (props) => {
  const { ethAccount, contract, web3, handleActivePage, location, ensEnabled, fromVerify, verifyData, goBack, fromSave, shouldSave, save, handleSave, savedCards, handleSavedCards } = props;
  const [mobileSidebar, handleMobileSidebar] = useState(false);
  const [doc, handleDoc] = useState(null);
  const [signers, handleSigners] = useState(null);
  const [initiator, handleInitiator] = useState(null)
  const [data, handleData] = useState(null);
  const [loading, handleLoading] = useState(true);
  const [networkId, handleNetworkId] = useState(null);
  const [error, handleError] = useState(false);

  const { formatMessage } = useIntl();

  useEffect(() => {
    (async () => {
      if(fromVerify) {
        if(verifyData !== null && verifyData.networkId !== null && verifyData.doc !== null && verifyData.initiator !== null) {
          handleDoc(verifyData.doc);
          handleSigners(verifyData.doc.signers)
          handleNetworkId(verifyData.networkId);
          handleInitiator(verifyData.initiator);
        } else {
          storeNotif("test", "test", "warning");
        }
      } else {
        handleActivePage('history');
        if(location.state !== undefined && location.state.doc && location.state.initiator && location.state.initiator && location.state.idx != null) {
          handleDoc(location.state.doc);
          handleSigners(location.state.signers)
          handleInitiator(location.state.initiator)
        } else {
          props.history.push({
            pathname: '/contracts'
          })
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if(doc !== null) {
          handleLoading(true);
          // Grab our history data

          if(fromSave && save) {
            handleData(save);
          } else if(networkId) {
            let data = await loadData(networkId, doc.documentKey, formatMessage);
            handleData(data);
            if(shouldSave && handleSave) {
              handleSave(data);
            }
          }
          handleLoading(false);
          handleError(false);
        }
      } catch(err) {
        // Prevents crash when the user changes networks while in the history page
        // console.log(err);
        handleError(true);
        handleLoading(false);
        // props.history.push({
        //   pathname: '/contracts'
        // })
      }
    })();
  }, [web3, contract, ethAccount, doc, networkId]);

  useEffect(() => {
    (async () => {
      if(web3) {
        web3.getNetwork().then((network) => {
          handleNetworkId(network.chainId);
        });
      }
    })();
  }, [web3]);

  if(fromVerify) {
    return (
      <div className="flex-grow flex flex-col relative text-gray-80">
        <div className={`w-full flex-grow mx-auto flex relative${fromVerify ? '' : ' max-w-7xl'}`}>
          {contract &&
            <Fragment>
              <div className={`flex-grow bg-gray-46 ${fromVerify ? 'p-4 sm:p-6' : 'pt-4 px-3 md:px-8'}`}>
                <div className="flex flex-wrap justify-between">
                    <div className="flex flex-col justify-center">
                      <div onClick={() => goBack()} className="select-none py-3 text-15 cursor-pointer flex flex-row mb-2 mr-auto">
                        <img src={CarretLeftIcon} className="h-4 w-4 my-auto" />
                        <div className="text-left font-semibold text-gray my-auto">{formatMessage({id: 'BACK'})}</div>
                      </div>
                      <div className="select-none flex flex-wrap">
                          <img src={menu} className="lg:hidden mr-3 h-12" onClick={() => { handleMobileSidebar(true) }} />
                          <div className={`${fromVerify ? 'text-20 font-semibold text-gray-80' : 'text-25 font-normal'} flex flex-col justify-center`}><FormattedMessage id='DOCUMENT_HISTORY'/></div>
                          {fromVerify && <img src={HistoryVerifyIcon} className="ml-2 h-8 w-8 my-auto" onDragStart={(e) => e.preventDefault()} />}
                      </div>
                    </div>
                </div>

                <div className="table-container w-full flex justify-center">
                  {!loading ? 
                  <HistoryDetail
                    error={error}
                    doc={doc}
                    data={data}
                    ethAccount={ethAccount}
                    provider={web3}
                    ensEnabled={ensEnabled}
                    fromSave={fromSave}
                    shouldSave={shouldSave}
                    savedCards={savedCards}
                    handleSavedCards={handleSavedCards} />
                  :
                  <Loader />
                  }
                </div>
              </div>
            </Fragment>
          }
          <HistorySidebar fromVerify={fromVerify} ethAccount={ethAccount} doc={doc} provider={web3} contract={contract} ensEnabled={ensEnabled} signers={signers} initiator={initiator} mobileSidebar={mobileSidebar} handleMobileSidebar={handleMobileSidebar} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex-grow flex flex-col relative text-gray-80 mt-16">
      <div className={`w-full flex-grow mx-auto flex relative${fromVerify ? '' : ' max-w-7xl'}`}>
        <HistorySidebar fromVerify={fromVerify} ethAccount={ethAccount} doc={doc} provider={web3} contract={contract} ensEnabled={ensEnabled} signers={signers} initiator={initiator} mobileSidebar={mobileSidebar} handleMobileSidebar={handleMobileSidebar} />
        {contract &&
          <Fragment>
            <div className={`flex-grow px-3 md:px-8 pt-10 bg-gray-46${fromVerify ? ' pb-10' : ''}`}>
              <div className="my-5 flex flex-wrap justify-between">
                  <div className="flex flex-col justify-center">
                      <div className="select-none flex flex-wrap">
                          <img src={menu} className="lg:hidden mr-3 h-12" onClick={() => { handleMobileSidebar(true) }} />
                          <div className="text-25 font-normal flex flex-col justify-center"><FormattedMessage id='DOCUMENT_HISTORY'/></div>
                      </div>
                  </div>
              </div>

              <div className="table-container w-full flex justify-center">
                {!loading ? 
                <HistoryDetail
                  error={error}
                  doc={doc}
                  data={data}
                  ethAccount={ethAccount}
                  provider={web3}
                  ensEnabled={ensEnabled}
                  fromSave={fromSave}
                  shouldSave={shouldSave}
                  savedCards={savedCards}
                  handleSavedCards={handleSavedCards}  />
                :
                <Loader />
                }
              </div>
            </div>
          </Fragment>
        }
      </div>
    </div>
  );
}

export default withRouter(History);