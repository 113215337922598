import React from "react";
import { useIntl } from 'react-intl';
import { useForm } from "react-hook-form";


const ContactUs = (props) => {
	const { formatMessage } = useIntl();
    const { register, handleSubmit } = useForm();
    const onSubmit = (data) => {
        window.open(`mailto:xin@ethsign.xyz?subject=${data.subject}`);
    }
    return(
        <>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} className="mt-8 sm:mt-16 w-11/12 sm:w-3/4 md:w-2/3 lg:w-1/2 flex justify-center flex-col sm:flex-row">
            <input
                id="subject_of_email"
                type="text" 
                {...register("subject")}
                placeholder={formatMessage({id: 'CONTACT_ABOUT'})}
                className="select-none w-full sm:w-1/2 my-2 sm:my-0 p-2 sm:mx-4 text-field-2 xl:text-base"
            />
            <button className="select-none focus:outline-none w-full text-white sm:w-32 sm:mx-0 py-2 font-medium bg-orange-500 hover:bg-orange-600 rounded-sm font-semibold">{formatMessage({id: 'REACH_OUT_CAPS'})}</button>
            </form>
           
        </>
    );
}

export default ContactUs;