import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getEmail, useSignNow } from '../../../helpers/email';
import SignMessage from './sign-message';
import Profile from './profile';

export default function ManageProfile(props) {
    const { web3 } = props;
    const [signNow, handleSignNow] = useSignNow(web3);
    const { formatMessage } = useIntl();

    const getEmailData = async () => {
        let {email, rejectSign} = await getEmail(web3, formatMessage);
        if(!rejectSign) {
            handleSignNow(true);
        }
    }
    
    return (
        <div className="flex flex-col items-center jutify-center mt-20">
            <div className="mx-4 flex flex-col items-center justify-center">
                <h3 ><FormattedMessage id='MANAGE_PROFILE' /></h3>
                <span className="mt-4 mx-auto text-center"><FormattedMessage id='BASIC_INFO_SUCH_AS_YOUR_ADDRESS_AND_NETWORK' /></span>
            </div>
            {
                signNow ? <Profile {...props}/> : <SignMessage getEmail={() => getEmailData()}/>
            }
        </div>
    )
}
