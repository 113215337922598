
import CryptoJS from 'crypto-js';
import fleekStorage from '@fleekhq/fleek-storage-js'
import { storeNotif } from './dashboard';
import CID from "cids"

export async function isFileWithPassword(fileHash) {
    // TODO: integrate storageProvider
    if (!fileHash) return false;

    // const myFile = await fleekStorage.getFileFromHash({
    //     hash: cidV1,
    // })
    const myFile = await fetch(`https://legacy-ipfs.ethsign.xyz/boyuanx-team-bucket/${fileHash}`);

    let decryptedDataArray;
    try {
        decryptedDataArray = decryptDataArrayFromStringAES(
            await myFile.text(),
            ""
        );
    } catch (error) {
        //doc has password
        return true;
    }
    //doc has no password
    return false;
}
async function getFileArrayFL(password, fileHash, returnPromise, formatMessage) {
   
    // const myFile = await fleekStorage.getFileFromHash({
    //     hash: cidV1,
    // })
    const myFile = await fetch(`https://legacy-ipfs.ethsign.xyz/boyuanx-team-bucket/${fileHash}`);
    let decryptedDataArray;
    try {
        decryptedDataArray = decryptDataArrayFromStringAES(
            await myFile.text(),
            password
        );
    } catch (error) {
        if(password != '') {
            // There was an issue with PDFTron upgrades where signatures were encrypted with '' for a short period
            // of time. To still be able to view those signatures, we will attempt a password decrypt of '' if the
            // password given to us is not equal to ''. If this fails, we will show an error to the user and return
            // false.
            try {
                decryptedDataArray = decryptDataArrayFromStringAES(
                    myFile,
                    ''
                );
            } catch(error) {
                storeNotif("Error Loading Metadata",  "Visual signatures are now unavailable, please refer to History for a detailed signing report.", 'warning')
                return false;
            }
        }else{
            storeNotif("Error Loading Metadata",  "Visual signatures are now unavailable, please refer to History for a detailed signing report.", 'warning')
            return false;
        }

        
    }
    if(returnPromise){
        return Promise.resolve(decryptedDataArray)
    }else{
        return decryptedDataArray
    }
}

export async function getFileArray(password, fileHash, storageProvider, returnPromise, formatMessage) {

    if (!fileHash) return false;

    if (storageProvider='FL'){
        const decryptedDataArray = await getFileArrayFL(password, fileHash, returnPromise, formatMessage);
        return(decryptedDataArray);
    }else if (storageProvider == 'IP'){
        // TODO: add getFileArrayIP code and add chinese transalations
    }else{
        storeNotif(formatMessage({id: 'ERROR'}), formatMessage({id: 'NO_DOCUMENT_FOUND'}), 'danger')
        return false;
    }
}

export async function getCommentArrs(commentData, password, formatMessage) {
    return Promise.all(commentData.map(comment => getFileArray(password, comment.commentHash, comment.provider, true, formatMessage)))
}

export async function downloadFile(password, fileHash, name, close, storageProvider, formatMessage) {
    const decryptedDataArray = await getFileArray(password, fileHash, storageProvider, false, formatMessage)
    if (decryptedDataArray != false) {
        createAndDownloadBlobFile(
            decryptedDataArray,
            name
        );
        storeNotif(formatMessage({id: 'DOWNLOAD'}), formatMessage({id: 'DOWNLOAD_STARTING'}), 'info')


        close();
    } else {
        return false;
    }
}

function decryptDataArrayFromStringAES(encryptedString, password) {
    if (password == undefined) password = "";
    const bytes = CryptoJS.AES.decrypt(encryptedString, password);
    const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    const decryptedDataArray = new Uint8Array(decrypted.data);
    return decryptedDataArray;
}

export function createAndDownloadBlobFile(body, fileName) {
    const blob = new Blob([body]);
    downloadBlob(blob, fileName);
}

export function downloadBlob(blob, fileName) {
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, fileName);
    } else {
        const link = document.createElement('a');
        // Browsers that support HTML5 download attribute
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            // link.setAttribute('download', fileName);
            link.setAttribute('target', '_blank');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
