import React, { useState, useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import DownArrow from '../assets/downarrow.svg';
import { getChartOptions } from '../helpers/analytics';

export default function NetworkSpecificCharts(props) {

  const { data, loading } = props;

  const [showNetworkSelect, handleShowNetworkSelect] = useState(false);
  const [networkDisplaying, handleNetworkDisplaying] = useState('ethereum');
  const [graphs, handleGraphs] = useState(null);
  // Refs
  const usersPerDayRefChart = useRef();
  const usersPerDayRef = useRef();
  const sigPerDayRefChart = useRef();
  const sigPerDayRef = useRef();
  // Aggregate Refs
  const usersPerDayAggRefChart = useRef();
  const usersPerDayAggRef = useRef();
  const sigPerDayAggRefChart = useRef();
  const sigPerDayAggRef = useRef();

  useEffect(() => {
    if(!data) {
      return;
    }
    
    let obj = {};

    // Users Per Day
    usersPerDayRefChart.current = createChart(usersPerDayRef.current, getChartOptions(usersPerDayRef.current.clientWidth, usersPerDayRef.current.clientHeight));

    const histogramSeries = usersPerDayRefChart.current.addHistogramSeries();
    obj.histogramSeries = histogramSeries;
    new ResizeObserver(entries => {
      if (entries.length === 0 || entries[0].target !== usersPerDayRef.current) { return; }
      const newRect = entries[0].contentRect;
      usersPerDayRefChart.current.applyOptions({ height: newRect.height, width: newRect.width });
    }).observe(usersPerDayRef.current);

    // Signatures Per Day
    sigPerDayRefChart.current = createChart(sigPerDayRef.current, getChartOptions(sigPerDayRef.current.clientWidth, sigPerDayRef.current.clientHeight));

    const histogramSeriesSig = sigPerDayRefChart.current.addHistogramSeries();
    obj.histogramSeriesSig = histogramSeriesSig;
    new ResizeObserver(entries => {
      if (entries.length === 0 || entries[0].target !== sigPerDayRef.current) { return; }
      const newRect = entries[0].contentRect;
      sigPerDayRefChart.current.applyOptions({ height: newRect.height, width: newRect.width });
    }).observe(sigPerDayRef.current);
    
    // Users Per Day Agg
    usersPerDayAggRefChart.current = createChart(usersPerDayAggRef.current, getChartOptions(usersPerDayAggRef.current.clientWidth, usersPerDayAggRef.current.clientHeight));

    const lineSeriesAgg = usersPerDayAggRefChart.current.addAreaSeries({lineColor: '#E98234', topColor: '#E98234', bottomColor: 'rgba(233, 130, 52, 0)'});
    obj.lineSeriesAgg = lineSeriesAgg;
    new ResizeObserver(entries => {
      if (entries.length === 0 || entries[0].target !== usersPerDayAggRef.current) { return; }
      const newRect = entries[0].contentRect;
      usersPerDayAggRefChart.current.applyOptions({ height: newRect.height, width: newRect.width });
    }).observe(usersPerDayAggRef.current);

    // Signatures Per Day Agg
    sigPerDayAggRefChart.current = createChart(sigPerDayAggRef.current, getChartOptions(sigPerDayAggRef.current.clientWidth, sigPerDayAggRef.current.clientHeight));

    const lineSeriesSigAgg = sigPerDayAggRefChart.current.addAreaSeries({lineColor: '#E98234', topColor: '#E98234', bottomColor: 'rgba(233, 130, 52, 0)'});
    obj.lineSeriesSigAgg = lineSeriesSigAgg;
    new ResizeObserver(entries => {
      if (entries.length === 0 || entries[0].target !== sigPerDayAggRef.current) { return; }
      const newRect = entries[0].contentRect;
      sigPerDayAggRefChart.current.applyOptions({ height: newRect.height, width: newRect.width });
    }).observe(sigPerDayAggRef.current);

    handleGraphs(obj);
  }, [data]);

  useEffect(() => {
    if(!graphs) {
      return;
    }

    graphs.histogramSeries.setData(data.usersPerDay[networkDisplaying]);
    graphs.histogramSeriesSig.setData(data.signaturesPerDay[networkDisplaying]);
    graphs.lineSeriesAgg.setData(data.usersPerDayAgg[networkDisplaying]);
    graphs.lineSeriesSigAgg.setData(data.signaturesPerDayAgg[networkDisplaying]);

  }, [graphs, networkDisplaying]);

  useEffect(() => {
		let listener = hideNetworkDropdown.bind(this);
		document.addEventListener('click', listener);

		return () => document.removeEventListener('click', listener);
	}, [showNetworkSelect]);

  function convertRemToPixels(rem) {    
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

  const hideNetworkDropdown = (event) => {
		const networkSelect = document.getElementById('analytic-network-select');
		if( showNetworkSelect && (!networkSelect || (event &&  !networkSelect.contains(event.target) ) ) ) {
			handleShowNetworkSelect(false);
		}
	}

  const networkToDisplayName = (network) => {
    switch(network) {
      case "ethereum":
        return "Ethereum Mainnet";
      case "ropsten":
        return "Ethereum Ropsten Testnet";
      case "bsc":
        return "Binance Smart Chain Mainnet";
      case "avalanche":
        return "Avalance Mainnet";
      case "polygon":
        return "Matic Mainnet";
      case "fantom":
        return "Fantom Opera";
      default:
        return "";
    }
  }

  return (
    <>
      <div className={`${loading ? 'absolute hidden ' : 'block '} flex flex-wrap mt-16 px-2 sm:px-16 mb-auto w-full max-w-7xl mx-auto`}>
          <div className='text-lg mr-2'>Select Network:</div>
          <div className="relative w-32 sm:w-48 inline-block">
            <div id="analytic-network-select" onClick={() => handleShowNetworkSelect(!showNetworkSelect)} className={`cursor-pointer select-none flex w-max pl-2 rounded-md text-15 sm:text-20 h-full my-auto ${showNetworkSelect ? 'bg-gray-32':''}`}>
              <div className="my-auto ">{networkToDisplayName(networkDisplaying)}</div>
              <img className="mx-1 w-4 h-4 my-auto" src={DownArrow} />
            </div>
            {showNetworkSelect &&
              <div id="status-selector" className="origin-top-right w-full absolute right-0 flex flex-col justify-center">
                <div className={`z-50 text-15 mt-1 select-none w-full rounded-md shadow-lg bg-white ring-1 ring-gray-150 ring-opacity-100 focus:outline-none`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                  <div className="py-1" role="none">
                    <div
                      onClick={() => {handleNetworkDisplaying("ethereum")}}
                      className="hover:bg-gray-25 cursor-pointer block px-4 py-2 text-15 text-gray-80 text-left" role="menuitem" tabIndex="-1" >{`Ethereum Mainnet`}</div>
                    <div
                      onClick={() => {handleNetworkDisplaying("ropsten")}}
                      className="hover:bg-gray-25 cursor-pointer block px-4 py-2 text-15 text-gray-80 text-left" role="menuitem" tabIndex="-1" >{`Ethereum Ropsten Testnet`}</div>
                    <div
                      onClick={() => {handleNetworkDisplaying("bsc")}}
                      className="hover:bg-gray-25 cursor-pointer block px-4 py-2 text-15 text-gray-80 text-left" role="menuitem" tabIndex="-1" >{`Binance Smart Chain Mainnet`}</div>
                    <div
                      onClick={() => {handleNetworkDisplaying("avalanche")}}
                      className="hover:bg-gray-25 cursor-pointer block px-4 py-2 text-15 text-gray-80 text-left" role="menuitem" tabIndex="-1" >{`Avalanche Mainnet`}</div>
                    <div
                      onClick={() => {handleNetworkDisplaying("polygon")}}
                      className="hover:bg-gray-25 cursor-pointer block px-4 py-2 text-15 text-gray-80 text-left" role="menuitem" tabIndex="-1" >{`Matic Mainnet`}</div>
                      <div
                      onClick={() => {handleNetworkDisplaying("fantom")}}
                      className="hover:bg-gray-25 cursor-pointer block px-4 py-2 text-15 text-gray-80 text-left" role="menuitem" tabIndex="-1" >{`Fantom Opera`}</div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>

        <div className={`${loading ? 'absolute hidden ' : 'block '} flex justify-center justify-around flex-wrap px-2 sm:px-16 mb-auto w-full max-w-7xl mx-auto`}>
          {/* Users Per Day */}
          <div className='w-full md:w-48/100 p-4 rounded-lg bg-gray-25 flex flex-col m-4 md:my-3 md:mx-0'>
            <div className="w-full h-64" ref={usersPerDayRef}>

            </div>
            <div className='text-center text-sm text-gray-80'>{`New Users Per Day (${networkDisplaying})`}</div>
          </div>

          {/* Signatures Per Day */}
          <div className='w-full md:w-48/100 p-4 rounded-lg bg-gray-25 flex flex-col m-4 md:my-3 md:mx-0'>
            <div className="w-full h-64" ref={sigPerDayRef}></div>
            <div className='text-center text-sm text-gray-80'>{`New Signatures Per Day (${networkDisplaying})`}</div>
          </div>
        </div>

        <div className={`${loading ? 'absolute hidden ' : 'block '} flex justify-center justify-around flex-wrap px-2 sm:px-16 mb-auto w-full max-w-7xl mx-auto`}>
          {/* Users Per Day Agg */}
          <div className='w-full md:w-48/100 p-4 rounded-lg bg-gray-25 flex flex-col m-4 md:my-3 md:mx-0'>
            <div className="w-full h-64" ref={usersPerDayAggRef}>

            </div>
            <div className='text-center text-sm text-gray-80'>{`Total Users (${networkDisplaying})`}</div>
          </div>

          {/* Signatures Per Day Agg*/}
          <div className='w-full md:w-48/100 p-4 rounded-lg bg-gray-25 flex flex-col m-4 md:my-3 md:mx-0'>
            <div className="w-full h-64" ref={sigPerDayAggRef}></div>
            <div className='text-center text-sm text-gray-80'>{`Total Signatures (${networkDisplaying})`}</div>
          </div>
        </div>
      </>
  );
}