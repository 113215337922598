import React from 'react';
import Switch from "react-switch";

const ToggleSwitch = (props) => {
    const {value, onChange, label, note} = props;
    return(
        <div className="flex cursor-pointer">
            <Switch
                checked={value}
                onChange={()=>{onChange()}}
                onColor="#E98234"
                onHandleColor="#E98234"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                height={20}
                width={40}
                className="mr-3"
            />
            <div className="flex flex-col" onClick={()=>{onChange()}}>
                <div className="font-semibold ">{label}</div>
                <div className="text-13 text-gray-60 font-normal">{note}</div>
            </div>
        </div>
    );
}
export default ToggleSwitch;