/**
 * @author pzhu
 * @description for simple lottie
 */
import { useLottie } from 'lottie-react';

export default function Lottie(props) {
    const {options, style={}} = props;
    const { View } = useLottie(options, style);

    return View;
}