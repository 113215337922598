/**
 * @author pzhu
 */
import { fetchChainData, ENDPOINT_PREFIX, network,API_VERSION} from '../../helpers/graphql';


export const parseContractHistoryData = (historyData = []) => {
    const documentsSignedNumOnEachChain = [];
    const signaturesSignedNumOnEachChain = [];
    historyData.forEach(({networkName, data}) => {
      if(!data) {
        return {documentsSignedNumOnEachChain: 0, signaturesSignedNumOnEachChain: 0}
      }
      const {totalDocumentsSigned, totalSignaturesSigned} = data.infos[0];
      documentsSignedNumOnEachChain.push({networkName, number: totalDocumentsSigned});
      signaturesSignedNumOnEachChain.push({networkName, number: totalSignaturesSigned});
    });
    return {documentsSignedNumOnEachChain, signaturesSignedNumOnEachChain};
};

export const getChainData = async (endpointPrefix, param, query) => {
    const promises = await network.map(item => {
      return {
        endpoint: `${endpointPrefix}${item}${API_VERSION}`,// /?query=${param}
        networkName: item
      };
    }).map( async item => {
      const {endpoint, networkName} = item;
      return await fetchChainData(endpoint, networkName, query);
    });
  
    return await Promise.all(promises);
  };

export const getChainDataWithSigNum = async (signedNumOnEachChain = [], getQuery, param) => {
 
    const promises = [];
    for (const {networkName, number} of signedNumOnEachChain) {
      const endpoint = `${ENDPOINT_PREFIX}${networkName}${API_VERSION}`;// /?query=${param}
      if (number >= 1000) {
        const num = Math.ceil(number/1000);
        let first = 1000;
        let skip = 0;
        for (let i = 0; i < num; i++) {
          if (i !== 0) {
            skip = first * i;
          }
          const query = getQuery(first, skip);
          promises.push(await fetchChainData(endpoint, networkName, query));
        }
  
      } else {
        const query = getQuery();
        promises.push(await fetchChainData(endpoint, networkName, query));
      }
    }
    return await Promise.all(promises);
};


export const getChainDataWithoutSigNum = async (network, getQuery, param) => {
    let result  = [];
    for (const networkName of network) {
      result = result.concat(await getSingleChainDataWithouNum(networkName, getQuery, param));
    }

    return result;
}

export const getSingleChainDataWithouNum = async (networkName, getQuery, param) => {
  const promises = [];
  const endpoint = `${ENDPOINT_PREFIX}${networkName}${API_VERSION}`;// /?query=${param}
  let flag = true;
  let first = 1000;
  let skip = 0;
  let count = 0;
  
  while (flag) {
    skip = first * count;
    const res = await fetchChainData(endpoint, networkName, getQuery(first, skip));
    if (res.data === undefined) break;
    promises.push(res);
    count++;
    if (res?.data?.users?.length < 1000 || res?.data?.contracts?.length < 1000 || res?.data?.events < 1000) flag = false;
  }
  
  return promises;
}



export const mergeDocumentsData = (chainData = []) => {
    const map = {};
    const mergeData = [];
    chainData.forEach(({networkName, data}) => {
        if (map[networkName]) {
            map[networkName] = {networkName, data:{contracts: map[networkName].data.contracts.concat(data.contracts)}}
        } else {
            map[networkName] = {networkName, data};
        }
    });

    for (const item in map) {
        mergeData.push(map[item]);
    }
    return mergeData;
};

export const mergeSignatureData = (chainData = []) => {
    const map = {};
    const mergeData = [];
    chainData.forEach(({networkName, data}) => {
      if (data === undefined) return;
        if (map[networkName]) {
            map[networkName] = {networkName, data:{events: map[networkName].data.events.concat(data.events)}}
        } else {
            map[networkName] = {networkName, data};
        }
    });

    for (const item in map) {
        mergeData.push(map[item]);
    }
    return mergeData;
}

export const merAddressData = (chainData = []) => {
    const map = {};
    const mergeData = [];
    chainData.forEach(({networkName, data}) => {
        if (map[networkName]) {
            map[networkName] = {networkName, data:{users: map[networkName].data.users.concat(data.users)}}
        } else {
            map[networkName] = {networkName, data};
        }
    });

    for (const item in map) {
        mergeData.push(map[item]);
    }
    return mergeData;
}

export const mergeSingleChainDocumentData = (chainData = []) => {
  let networkName;
  const contracts = chainData.reduce((pre,cur) => {
    networkName = cur?.networkName;
    return pre.concat(cur?.data?.contracts);
  }, []);
  const map = {};
  map['data'] = {contracts};
  return {networkName, ...map}
}

export const mergeSingleChainSignatureData = (chainData = []) => {
  let networkName;
  const events = chainData.reduce((pre,cur) => {
    networkName = cur?.networkName;
    return pre.concat(cur?.data?.events);
  }, []);
  const map = {};
  map['data'] = {events};
  return {networkName, ...map}
}
