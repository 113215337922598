import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl';
import Loader from './UI/loader';
import { getChain, getMainNetChain, getTestNetChain } from '../helpers/chains';
import CarretDown from '../assets/carret_down_black.svg';
import { storeNotif } from '../helpers/dashboard';
const VerificationForm = (props) => {
	const { loading, verifySignature, loadContractHistory, startingDocKey, startingSignersAddress, startingNetworkId } = props;
  const [network, handleNetwork] = useState(getChain(startingNetworkId ? startingNetworkId : 1));
  const [docKey, handleDocKey] = useState(startingDocKey ? startingDocKey : '');
  const [signersAddress, handleSignersAddress] = useState(startingSignersAddress ? startingSignersAddress : '');
  const [showNetworks, handleShowNetworks] = useState(false);
  const { formatMessage } = useIntl()
  const [shouldUpdateNetworkId, handleShouldUpdateNetworkId] = useState(true);

  useEffect(() => {
    // Only update this once. We don't want the user to select a network and then have it get changed
    // after the fact.
    if(shouldUpdateNetworkId && startingNetworkId) {
      handleNetwork(getChain(startingNetworkId));
      handleShouldUpdateNetworkId(false);
    }
  }, [startingNetworkId])

  useEffect(() => {
		let networkDropdownListener = hideNetworkDropdown.bind(this);
		document.addEventListener('click', networkDropdownListener);

		return () => document.removeEventListener('click', networkDropdownListener);
	}, [showNetworks]);

  const hideNetworkDropdown = (event) => {
		if(showNetworks) {
				const networkDropdown = document.getElementById('network-dropdown');
				if(!networkDropdown || (event &&  !networkDropdown.contains(event.target))) {
						handleShowNetworks(false);
				}
		}
	}

  const handleNetworkChange = (chain) => {
    handleNetwork(chain);
    handleShowNetworks(false);
  }

  const handleSubmission = (e) => {
    e.preventDefault();
  }

  const verifyContract = () => {
    if(docKey?.length > 0 &&
      signersAddress?.length > 0) {
    
      verifySignature(network.chainId, docKey, signersAddress);
    } else {
      storeNotif(formatMessage({id: 'FORM_ERROR'}), formatMessage({id: 'PLEASE_ENTER_DOC_KEY_SIGNER_ADDRESS'}), "danger");
    }
  }

	return (
		<div className="flex">
      <div className="m-auto w-full flex justify-center">
        <div onClick={(event) => event.stopPropagation()} className="w-full bg-gray-45 sm:border-t-4 border-t-8 border-orange-500 sm:rounded-none rounded-lg shadow-md sm:shadow-none">
          
          {loading ?
            <div className="flex justify-center mb-8">
              <Loader />
            </div>
            :
            <div className="p-4 sm:p-6 flex flex-col justify-center w-full">
              <form onSubmit={(e) => handleSubmission(e)}>
                <div className="select-none text-gray-80 text-12 font-semibold my-3">{formatMessage({id: 'DOCUMENT_KEY'})}</div>
                <input
                    placeholder={''}
                    name="docKey"
                    type="text"
                    autoComplete="off"
                    autoFocus
                    maxLength={72}
                    value={docKey}
                    id="docKeyInput"
                    onChange={e => handleDocKey(e.target.value)}
                    className={`w-full rounded-sm sm:rounded-none focus:outline-none px-4 py-1 border mr-0 border-gray-90 focus:border-gray-200 mb-4`}
                >
                </input>

                <div className="select-none text-gray-80 text-12 font-semibold my-3">{formatMessage({id: 'SIGNERS_ADDRESS'})}</div>
                <input
                    placeholder={''}
                    name="signerAddress"
                    type="text"
                    autoComplete="off"
                    maxLength={72}
                    id="signerAddressInput"
                    value={signersAddress}
                    onChange={e => handleSignersAddress(e.target.value)}
                    className={`w-full rounded-sm sm:rounded-none focus:outline-none px-4 py-1 border mr-0 border-gray-90 focus:border-gray-200 mb-4`}
                >
                </input>

                <div className="select-none flex text-15 my-3 flex-wrap xs:flex-nowrap w-full sm:w-96"> 
                  <div className="relative inline-block text-center flex-grow mb-2 xs:mb-0 mr-0 xs:mr-3 ">
                    <div 
                      onClick={()=>{
                        handleShowNetworks(!showNetworks)
                      }}
                    >
                      <button type="button" className={`cursor-pointer w-full bg-white inline-flex justify-center w-full rounded-none border shadow-sm px-2 py-1.5  focus:outline-none`} id="menu-button" aria-expanded="true" aria-haspopup="true">
                      <div className="h-2 w-2 my-auto"></div>
                      <div className="mx-auto">{network ? network.name: <span className="invisible"><FormattedMessage id='NO_NETWORK'/></span>}</div>
                      <img src={CarretDown} className="h-2 w-2 my-auto" />
                      </button>
                    </div>

                    {/* <!--
                      Dropdown menu, show/hide based on menu state.

                      Entering: "transition ease-out duration-100"
                      From: "transform opacity-0 scale-95"
                      To: "transform opacity-100 scale-100"
                      Leaving: "transition ease-in duration-75"
                      From: "transform opacity-100 scale-100"
                      To: "transform opacity-0 scale-95"
                    --> */}
                    {showNetworks &&
                      <div id="network-dropdown" className={`origin-top-right absolute right-0 w-full rounded-sm shadow-lg bg-white ring-1 ring-gray-200 focus:outline-none`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                        <div className="py-1" role="none">
                          <div>
                            {
                              getMainNetChain()?.map((chain, key) => {
                                return (
                                  <div key={key} 
                                  onClick={()=>{
                                    handleNetworkChange(chain)
                                  }}
                                  className="hover:bg-gray-25 cursor-pointer block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" >{chain.name}</div>
                                )
                              })
                              
                            }
                          </div>
                          <div className="border-t mx-4"/>
                          <div >
                            {
                              getTestNetChain()?.map((chain, key) => {
                                return (
                                  <div key={key} 
                                  onClick={()=>{
                                    handleNetworkChange(chain)
                                  }}
                                  className="hover:bg-gray-25 cursor-pointer block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" >{chain.name}</div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div>
                  <button onClick={() => verifyContract()}className="select-none focus:outline-none w-full text-white sm:w-36 mt-2 sm:mx-0 py-2 font-semibold bg-orange-500 hover:bg-orange-600 rounded-sm">{formatMessage({id: 'VERIFY_CONTRACT'})}</button>
                  <button onClick={() => loadContractHistory(network ? network.chainId : null, signersAddress, docKey, 0)} className="select-none focus:outline-none w-full text-white sm:w-48 mt-2 sm:mx-0 py-2 font-semibold bg-blue-50 hover:bg-blue-60 rounded-sm ml-0 sm:ml-4 md:ml-8">{formatMessage({id: 'VIEW_CONTRACT_HISTORY'})}</button>
                </div>
              </form>
            </div>
          }
        </div>
      </div>
    </div>
	);
}

export default withRouter(VerificationForm);
