import React, { useEffect, useState } from 'react';

const ProgressBarIndicator = (props) => {
  const { value, max, signStyle } = props;
  const [steps, setSteps] = useState(new Map());

  useEffect(()=> {
    let tempSteps = [];

    for(let i = 0; i < max; i++) {
      tempSteps.push(
        <li
          key={i}
          className={`progress-bar-step ${value > i ? `completed` : ''}${value == i ? `current` : ''}`}>
          <span className='step-circle'>
            {value > i && (
              <svg
                width=".5rem"
                viewBox="0 0 13 13"
                fill="none"
                style={{verticalAlign: 'top', marginTop: '12.5%'}}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 3.5L4.5 7.5L12 1" stroke="white" strokeWidth="1.5" />
              </svg>
            )}
          </span>
        </li>
      )
    }
    setSteps(tempSteps);
  }, [value, max])

  if(max==1){
    return null;
  }

  return (
    <div className={`relative pt-3 pb-3 w-full${signStyle ? ' overflow-hidden' : ''}`}>
      {max <= 15 && 
        <ul className={`progress-step-bar`}>
          {steps}
        </ul>
      }
      {max > 15 &&
        <div className="overflow-hidden h-6 text-xs flex rounded bg-gray-200">
          <div style={{width:`${(value/max)*100}%`}} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-orange-500 progress-active">
            <div className="progress-active-text">{value + '/' + max}</div>
          </div>
        </div>
      }
    </div>
  )
}

export default ProgressBarIndicator;