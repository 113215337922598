import React from 'react';
import { FormattedMessage } from 'react-intl';
import fly from '../../../assets/fly.svg';
import fly_1 from '../../../assets/fly-1.svg';

 export default function SignMessage (props) {
    const {getEmail} = props;
    return (
        <div>
            <div className='flex flex-col justify-center items-center bg-white'>
                <img className="select-none" src={fly_1}/>
                <span className='mx-auto text-center'><FormattedMessage id='PLEASE_SIGN_MESSAGE_TO_CONTINUE_WITH_ETHSIGN'/></span>
                <button
                    onClick={() => {
                        getEmail();
                    }}
                    className='select-none focus:outline-none text-white  lg:mx-4 my-3 py-2 font-medium bg-orange-500 hover:bg-orange-600 rounded-sm w-32'
                >
                    <FormattedMessage id='SIGN_NOW'/>
                </button>
                <img src={fly} className='w-screen select-none'/>
            </div>

        </div>
    )
}