
/**
 * @author pzhu
 */

import * as localData  from '../message/language-export.js';


/**
 * @returns browser language
 */
export const getBrowserLanguage =  () => {
     return (navigator.language || navigator.browserLanguage).substring(0, 2).toLocaleLowerCase();
}

/**
 * 
 * @param {*} local browser language
 * @returns diffirence translation language
 */
export const loadData = (local) => {
    const result =  localData[local];
    return result;    
}
