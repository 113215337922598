import React, {useRef, useEffect, useState} from 'react';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import { useIntl, FormattedMessage } from 'react-intl';
import {storeNotif} from '../../helpers/dashboard';
import FeedbackIcon from '../../assets/feedback.svg'
import { withRouter } from 'react-router-dom';
import X from '../../assets/cross.svg'
const disableFeedbackConfig = require('./DisableFeedbackConfig');

const Feedback = (props) => {
    const [disable, handleDisable] = useState(false);
    const [showClose, handleShowClose] = useState(false);
    const typeform = useRef(null);
    const { formatMessage } = useIntl();
    const { path } = disableFeedbackConfig;
    const {activePage, location: {pathname}} = props;

    useEffect(()=> {
        if (path.includes(pathname)) {
            handleDisable(true);
        }
    },[pathname]);

    const nonfeedback = "create upload datavis analytics";
    if(nonfeedback.includes(activePage)) {
        return null;
    }

    return (
        <div className={`fixed right-4 lg:right-8 bottom-4 lg:bottom-1 ${disable ? 'hidden' : 'block'} `} onMouseEnter={()=> handleShowClose(true)} onMouseLeave={() => handleShowClose(false)}>
            
            <div className="text-xs lg:text-base font-bold cursor-pointer rounded-full lg:rounded-lg px-1 lg:px-4 text-white bg-orange-500 hover:bg-orange-600 py-1 relative">
                <div className="-z-10 relative">
                    <ReactTypeformEmbed url="https://dygdc65xyp5.typeform.com/to/vAAV1qPr" 
                            popup={true}
                            ref={typeform}
                            autoOpen={false}
                            autoClose={0}
                            onSubmit={()=>{
                                storeNotif("", formatMessage({id: 'WE_APPRECITE_AND_VALUE_YOU_FEEDBACK'}), "info");
                            }}
                        />
                </div>
                <div className="flex items-center justify-center" onClick={()=>{typeform.current.typeform.open()}}>
                    <div className="flex items-center justify-center m-2">
                        <img src={FeedbackIcon} alt="" className="transform rotate-90 lg:mr-2 select-none" onDragStart={(e) => e.preventDefault()}/>
                    </div>
                    <span className="hidden lg:block select-none"><FormattedMessage id="PROVIDE_FEEDBACK"/></span>
                </div>

                {
                    showClose && <img src={X} alt="close" className="absolute top-0 right-0 transform -translate-y-1/2 translate-x-1/2 hidden lg:block select-none" onClick={() => handleDisable(true)}/>
                }
                
            </div> 
        </div>
    )

}

export default withRouter(Feedback)