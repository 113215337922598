/**
 * @author pzhu
 */
import React from "react";

export default function TermsHoc(Component) {

    return ()=> {
        return (
            <div id="modal-section bg-gray-40 flex">
                <div className="bg-gray-40">
                    <div className="flex">
                        <Component />
                    </div>
                </div>
            </div>
        )
    }
}