import React, {useEffect} from 'react';
import ActionRequiredIcon from '../../assets/contracts_sidebar/action_required.svg';
import AllSignedIcon from '../../assets/contracts_sidebar/all_signed.svg';
import CreatedByMeIcon from '../../assets/contracts_sidebar/created_by_me.svg';
import AllContractsIcon from '../../assets/contracts_sidebar/all_contracts.svg';
import ArchivedIcon from '../../assets/contracts_actions/archive.svg';
import ExpiringSoonIcon from '../../assets/contracts_sidebar/expiring_soon.svg';
import SharedWithMeIcon from '../../assets/contracts_sidebar/shared_with_me.svg';
import WaitingForOthersIcon from '../../assets/contracts_sidebar/waiting_for_others.svg';
import PlusIcon from '../../assets/plus_icon.svg';
import Logo from '../../assets/logo.svg';
import X from '../../assets/x-cross.svg';
import {Link} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const DocumentSidebar = (props) => {
    const { filter, handleFilter, mobileSidebar, handleMobileSidebar, handleActivePage, handleShowUpgradeNotification } = props;
   
    useEffect (()=>{
		let isSubscribed = true;
        if (mobileSidebar === true){
            handleMobileSidebar(false)
        }
		return () => isSubscribed = false;
	},[filter]);
    
    const Sidebar = 
        <>
            <div className={`block flex items-center py-4 border-b border-gray-200 ${!mobileSidebar && 'hidden'}`}>
                <div className="flex justify-center absolute mx-4">
                    <img 
                        src={X} 
                        className="" 
                        onClick={() => {
                            handleActivePage("contracts");
                            handleMobileSidebar(false);
                        }}
                    />
                </div>
                <div className="flex mt-1 flex-1 justify-center">
                    <img src={Logo} className="w-20"/>
                </div>
            </div>
            <div className="select-none text-15 font-normal mt-6">             
                {/* TODO: add trigger for new contract */}
                <Link className="focus:outline-none cta-action bg-orange-500 hover:bg-orange-600 text-white px-5 py-3 mb-5 rounded-sm flex justify-center mx-3" to="/create">
                    <div className="flex flex-col justify-center my-auto"><img src={PlusIcon} className="mr-2 mb-0.5"/></div>
                    <div className="font-bold">
                        <FormattedMessage id='NEW_CONTRACT'/>
                    </div>
                </Link>
                <div className="w-full px-7 py-1 my-2 flex font-semibold">
                    <FormattedMessage id='MANAGE_CONTRACTS'/>
                </div>
                <div className={`w-full bg-active-hover px-7 py-1  flex cursor-pointer ${filter==="pending" && 'bg-active'}`} onClick={()=>{handleFilter("pending")}}>
                    <img src={ActionRequiredIcon} alt="Contracts That Need Signing" className="mr-3"/>
                    <div className="leading-9">
                        <FormattedMessage id="ACTION_REQUIRED" />
                    </div>
                </div>
                <div className={`w-full bg-active-hover px-7 py-1 flex cursor-pointer ${filter==="voted" && 'bg-active'}`} onClick={()=>{handleFilter("voted")}}>
                    <img src={WaitingForOthersIcon} alt="Contracts Waiting For Others" className="mr-3"/>
                    <div className="leading-9"><FormattedMessage id="WAITING_FOR_OTHERS" /></div>
                </div>
                {/* <div className={`w-full bg-active-hover px-7 py-1 flex cursor-pointer ${filter==="expiring" && 'bg-active'}`} onClick={()=>{handleFilter("expiring")}}>
                    <img src={ExpiringSoonIcon} alt="Contracts Expiring Soon" className="mr-3"/>
                    <div className="leading-9">
                        <FormattedMessage id='EXPIRING_SOON' />
                    </div>
                </div> */}
                <div className={`w-full bg-active-hover px-7 py-1 flex cursor-pointer ${filter==="consensus" && 'bg-active'}`} onClick={()=>{handleFilter("consensus")}}>
                    <img src={AllSignedIcon} alt="Signed Contracts" className="mr-3"/>
                    <div className="leading-9">
                        <FormattedMessage id='ALL_SIGNED'/>
                    </div>
                </div>
                
            </div>
            <div className="my-5"></div>
            <div className="select-none text-15 font-normal">
                <div className="w-full px-7 py-1 my-2 flex font-semibold">
                    <FormattedMessage id='QUICK_ACCESS' />
                </div>
                <div className={`w-full bg-active-hover px-7 py-1  flex cursor-pointer ${filter==="all" && 'bg-active'}`} onClick={()=>{handleFilter("all")}}>
                    <img src={AllContractsIcon} alt="All Contracts" className="mr-3"/>
                    <div className="leading-9">
                        <FormattedMessage id='ALL_CONTRACTS' />
                    </div>
                </div>
                <div className={`w-full bg-active-hover px-7 py-1 flex cursor-pointer ${filter==="original" && 'bg-active'}`} onClick={()=>{handleFilter("original")}}>
                    <img src={CreatedByMeIcon} alt="Contracts Created by Me" className="mr-3"/>
                    <div className="leading-9">
                        <FormattedMessage id='CREATED_BY_ME' />
                    </div>
                </div>
                <div className={`w-full bg-active-hover px-7 py-1 flex cursor-pointer ${filter==="shared" && 'bg-active'}`} onClick={()=>{handleFilter("shared")}}>
                    <img src={SharedWithMeIcon} alt="Contracts Shared with Me" className="mr-3"/>
                    <div className="leading-9">
                        <FormattedMessage id='SHARED_WITH_ME'/>
                    </div>
                </div>
                <div className={`w-full bg-active-hover px-7 py-1 flex cursor-pointer ${filter==="archived" && 'bg-active'}`} onClick={()=>{handleFilter("archived")}}>
                    <img src={ArchivedIcon} alt="Recent Contracts" className="mr-3"/>
                    <div className="leading-9">
                        <FormattedMessage id='ARCHIVED' />
                    </div>
                </div>
                
            </div>
        </>
            
    
    return(
        <>
            {mobileSidebar == true? 
                <>
                    <div 
                        className="mobileSidebarBG" 
                        onClick={()=>{
                            handleActivePage("contracts");
                            handleMobileSidebar(false);
                        }}
                        ></div>
                    <div className="bg-white mobileSidebar">
                        <div className="relative md:flex flex-col md:w-64  border-r border-gray-200 ">
                            {Sidebar}
                        </div>
                    </div>
                </>
            :
                <div className="hidden lg:flex flex-col md:w-64 min-w-64 border-r border-gray-200 my-10">
                    {Sidebar}
                </div>
            }
        </>
    );
}
export default DocumentSidebar