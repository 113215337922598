import React, { useEffect, useState } from 'react';

export const useActiveChain = (provider) => {
    const [activeChain, handleActiveChain] = useState(null);

    useEffect(()=> {
        (async () => {
            handleActiveChain(await provider.getNetwork());
        })();
    }, [provider]);

    return [activeChain, handleActiveChain];
}