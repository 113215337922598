import React, {useCallback} from 'react';
import logoBG from '../../assets/logo-bg-light.png';
import PdfIcon from '../../assets/pdf.png';
import NewDocIcon from '../../assets/new_document.svg';
import {useDropzone} from 'react-dropzone'
const Create = (props) => {
    const {files, handleFiles} = props;
    const onDrop = useCallback(acceptedFiles => {
        handleFile(acceptedFiles)
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, noClick:true});
    return(
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 w-full my-8 text-gray-300 text-15">
            <div className="relative mx-5 pb-2 border-b border-gray-200">
                <div className=" px-3 font-bold text-30 ">
                    Revision
                </div>
                <img src={logoBG} className="absolute bottom-0 -left-5 -z-10"/>
            </div>
            <div className="mx-5 px-3 mt-5 mb-10">
                <div className="font-bold mb-4">
                    Uploaded Documents
                </div>
                <div className="shadow-md p-3 flex">
                    <img src={PdfIcon} className="mr-3"></img>
                    <div className="flex flex-col justify-center">
                        <div className="my-1">
                            Ethsign Sample Agreement.pdf
                        </div>
                        <div className="font-medium text-gray-70 my-1">
                            3 pages
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div {...getRootProps()} className={`bg-gray-30 mx-10 md:mx-32 rounded-md focus:outline-none flex-grow border-2 border-gray-200 border-dashed my-5 py-10 flex flex-col justify-center ${isDragActive && 'bg-gray-400'} `}>
                    <input {...getInputProps()} />
                    <img src={NewDocIcon}/>
                    <div className="mx-auto my-3 font-medium">
                        Drop Documents Here
                    </div>
                    <span className="mx-auto my-3 text-black-500"> 
                        <button id="start-btn" className="text-white px-3 sm:px-5 md:px-10 py-2 rounded-md bg-orange-500 hover:bg-orange-600 font-medium">
                            Upload
                        </button>
                            
                    </span>
                    
                </div>
            </div>
            <div className="flex justify-end">
                <button className="text-white w-28 py-2 font-medium bg-orange-500 hover:bg-orange-600 rounded-sm">
                    Next
                </button>
            </div>
        </div>
    );
}
export default Create;