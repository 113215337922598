import React, { useState, useEffect } from 'react'
import { useIntl  } from 'react-intl';
import ContentLoader from 'react-content-loader';
import ContractName from './ContractName';
import ContractActions from './ContractActions';
import Details from './Details';
import { getDocumentFormattedStatus } from '../../helpers/dashboard';
import { getSignersWithAlias } from '../../helpers/signer';
const DocumentRow = (props) => {
    const {idx, active, handleActive, update, doc, actualDate, selectAll, handleSelectAll, selectedHandler, contract, provider, filter, handleArchiveData, ethAccount, ensEnabled, reloadContractDetails} = props;
    const { formatMessage } = useIntl();
    const [signers, handleSigners] = useState(null);
    const [initiator, handleInitiator] = useState(null);
      
    useEffect(() => {
        let isSubscribed = true;
        if(!doc) {
            return;
        }
        if (ensEnabled && doc.signers?.length > 0){
            getSignersWithAlias(doc.signers, provider).then(data => {
                handleSigners(data)
                setInitiator(data)
            })
        }else{
            handleSigners(doc.signers)
            setInitiator(doc.signers)
        }
		return () => isSubscribed = false;
    }, [doc, doc?.loading, doc?.error])

    const setActive = () => {
        if(active == idx) {
            handleActive(null);
        } else {
            handleActive(idx);
        }
    }

    const setInitiator = (signerList) => {
        const index = _.findIndex(signerList, function(o) { return o?.address == doc.initiator });
        if(index>-1){
            handleInitiator(signerList[index]);
        }else{
            handleInitiator({
                address: doc.initiator,
                avatar: null,
                alias: null
            });
            // TODO: when initiator is not a signer
        }
    }

    if(!doc) {
        return null;
    }

    if(doc.loading) {
        return (
            <>
                <tr className="border-t border-b border-gray-25 sm:border-0 sm:border-transparent">
                    <td className="px-3">
                        <ContentLoader
                            speed={2}
                            width={250}
                            height={71}
                            viewBox="0 0 250 71"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                        >
                            <rect x="35" y="10" rx="3" ry="3" width="16rem" height="1.25rem" />
                            <rect x="0" y="10" rx="4" ry="4" width="1.25rem" height="1.25rem" />
                            <rect x="35" y="40" rx="3" ry="3" width="8rem" height=".625rem" />
                        </ContentLoader>
                    </td>
                    <td className="px-3 hidden xs:table-cell">
                        <ContentLoader
                            speed={2}
                            width={150}
                            height={71}
                            viewBox="0 0 150 71"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                        >
                            <rect x="0" y="10" rx="3" ry="3" width="12rem" height="1.25rem" />
                        </ContentLoader>
                    </td>
                    <td className="hidden sm:table-cell px-3 border-r border-transparent">
                        <ContentLoader
                            speed={2}
                            width={100}
                            height={71}
                            viewBox="0 0 100 71"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                        >
                            <rect x="0" y="10" rx="3" ry="3" width="12rem" height="1.25rem" />
                        </ContentLoader>
                    </td>
                </tr>
            </>
        );
    }
    
    if(doc.error) {
        return (
            <>
                <tr className={`text-red-500 hover:bg-gray-25 border-t border-b border-gray-25 sm:border-0 sm:border-transparent`} onClick={()=>{setActive()}}>
                    <td className="px-3">
                        <div className="pl-8">
                            {formatMessage({id: 'ERROR_LOADING_DOCUMENT'})}
                        </div>
                    </td>
                    <td className="px-3">
                        {formatMessage({id: 'LOADING_FAILED'})}
                    </td>
                    <td className="hidden sm:table-cell px-3 border-r border-transparent">
                        <div className="w-full py-2 flex">
                            <ContractActions error={true} reloadContractDetails={reloadContractDetails} doc={doc} contract={contract} provider={provider} idx={idx} filter={filter} />
                        </div>
                    </td>
                </tr>
            </>
        );
    }

    return(
        <>
            <tr className={`${active == idx ? 'bg-gray-25' : 'hover:bg-gray-25'}  border-t border-b border-gray-25 sm:border-0 sm:border-transparent`} onClick={()=>{setActive()}}>

                <ContractName
                    idx={idx}
                    update={update}
                    doc={doc}
                    signers={signers}
                    initiator={initiator}
                    actualDate={actualDate}
                    selectAll={selectAll}
                    handleSelectAll={handleSelectAll}
                    selectedHandler={(add) => { (selectedHandler(idx, add)) }}
                    contract={contract}
                    provider={provider}
                    filter={filter}
                    handleArchiveData={(documentKey)=>{handleArchiveData(documentKey, idx)}}
                    formatMessage={formatMessage}
                />
                <td className="hidden sm:table-cell px-3 cursor-pointer">
                    <div className={`w-full py-2 ${doc && doc.status == 0 ? 'text-red-500' : ''}`}>
                        {doc ? getDocumentFormattedStatus(doc.status, formatMessage) : ''}
                    </div>
                </td>

                <td className="hidden sm:table-cell px-3 border-r border-transparent">
                    <div className="w-full py-2 flex">
                        <ContractActions doc={doc} signers={signers} initiator={initiator} contract={contract} provider={provider} idx={idx}
                            handleArchiveData={(documentKey)=>{handleArchiveData(documentKey, idx)}}
                            filter={filter}
                        />
                    </div>
                </td>
            </tr>
            {active == idx &&
                <tr key={`$idx` - 1} className="bg-gray-25  border-t border-b border-gray-25 sm:border-0 sm:border-transparent" >
                    <td className="w-full px-5 pb-3" colSpan={3} >
                        <Details
                            signers={signers}
                            initiator={initiator}
                            ethAccount={ethAccount} contract={contract} doc={doc} provider={provider} ensEnabled={ensEnabled} />
                    </td>
                </tr>
            }
        </>
    );
}
export default DocumentRow;