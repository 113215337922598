import React, { useState, useEffect } from 'react';
import logoBG from '../../assets/logo-bg-light.png';
import eyeball_hide from '../../assets/eyeball_hide.svg';
import eyeball_show from '../../assets/eyeball_show.svg';
import SignerForm from './SignerForm';
import ToggleSwitch from '../Dashboard/ToggleSwitch';
import FileDisplay from './FileDisplay';
import {validateExpiration, getCurrentBlockNumber} from '../../helpers/dashboard';
import {changeEstimateExpiration} from '../../helpers/block';
import moment from 'moment';
import { useIntl, FormattedMessage } from 'react-intl';

const ReviewSend = (props) => {
    const {file, filename, handleFilename, signers, handleSigners, addedSigners, ethAccount, pageCount, handleProgress, web3, handleSending, expiryBlock, handleExpiryBlock, password, handlePassword, provider, ensEnabled, withPassword, handleWithPassword, withExpiry, handleWithExpiry, updateStoredData} = props;
	const [currentBlock, handleCurrentBlock] = useState(null);
    const [useBlock, handleUseBlock] = useState(expiryBlock ? true : false);
    const [estimatedExpirationBlock, handleEstimatedExpirationBlock] = useState(null);
    const [estimatedExpirationDate, handleEstimatedExpirationDate] = useState(null);
    const [viewPassword, handleViewPassword] = useState(false);
    const { formatMessage } = useIntl();

    useEffect (()=>{
		(async () => {
            if(password) {
                document.getElementById('password').value = password;
            }
            if(withExpiry && expiryBlock) {
                handleUseBlock(true);
                document.getElementById('expiry').value = expiryBlock;
            }
		    handleCurrentBlock(await getCurrentBlockNumber(web3));
		})();
	},[]);

    useEffect (()=>{
        handleEstimatedExpirationBlock(null)
        handleEstimatedExpirationDate(null)
	},[withExpiry]);

    const handleSubmit = async () => {
        if(withPassword){
            handlePassword(document.getElementById('password').value);
        } else {
            handlePassword("");
        }
        let block = null;
        if(withExpiry){
            if(useBlock) {
                block = document.getElementById('expiry').value;
            } else {
                // Grab the estimated block that we calculated for the user
                block = estimatedExpirationBlock;
            }
            if (validateExpiration(block, currentBlock) === false) {
                return;
            }
            handleExpiryBlock(block);
        } else {
            handleExpiryBlock(0);
        }
        handleSending(true);
    }
    
    const handleExpirationChange = () => {
        changeEstimateExpiration(
            useBlock,
            document.getElementById('expiry').value,
            currentBlock,
            provider,
            handleEstimatedExpirationDate,
            handleEstimatedExpirationBlock,
        );
    }
    const isAdded = (signer) => {
        const index = _.indexOf(addedSigners, signer);
        if(index>-1){
            return true;
        }else{
            return false;
        }
    }

    return(
        <div className="select-none max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 w-full my-8 text-gray-300 text-15">
            
            <div className="relative mx-5 pb-2 border-b border-gray-200">
                <div className=" px-3 font-bold text-30 ">
                    <FormattedMessage id='REVIEW_AND_SEND'/>
                </div>
                <img src={logoBG} className="absolute bottom-0  -left-5 -z-10"/>
            </div>
            <div className="mx-5 px-3 mt-5 mb-10">
                {file && <FileDisplay filename={filename} handleFilename={handleFilename} pageCount={pageCount} updateStoredData={updateStoredData} />}
            </div>
            <div className="mx-5 px-3 mt-5 mb-10">
                <div className=" font-bold mb-2">
                    {signers.length>0 && 'Signers'}
                </div>
                <div className="flex flex-col md:flex-row md:flex-wrap justify-between">
                    {signers.map((signer, index)=>{
                        return(
                            <SignerForm provider={provider} key={index} ensEnabled={ensEnabled} ethAccount={ethAccount} signers={signers} handleSigners={handleSigners} initialValue={signer} id={index} added={isAdded(signer)} inReview={true}/>
                        );
                    })}
                </div>
            </div>
            <div className="mx-5 px-3 mt-5 mb-10">
                <div className="flex w-full justify-between flex-col sm:flex-row">
                    <div className="w-45prs mb-3 sm:mb-0">
                        <div className="mb-5">
                            <ToggleSwitch value={withPassword} onChange={()=>{handleWithPassword(!withPassword)}} label={formatMessage({id: 'SET_CONTRACT_PASSWORD'})} note={formatMessage({id: 'SET_A_PASSWORD_ENCRYPTS'})}/>
                        </div>
                        { withPassword && 
                            <div className="mx-5 my-2">
                                <div className="font-medium flex flex-col xs:flex-row sm:flex-col lg:flex-row">
                                    <div className="mr-3 flex-shrink-0 mb-3 xs:my-auto sm:mb-3 lg:my-auto">
                                        <FormattedMessage id='PASSWORD' />:
                                    </div>
                                    <div className="flex flex-row cursor-pointer border border-gray-200 rounded-sm flex-grow lg-max-w-80">
                                        <input type={viewPassword ? "text" : "password"} id="password" autoComplete="new-password" className="w-full flex-grow focus:outline-none focus:outline-none  px-2 py-2" maxLength="32"/>
                                        <button className="focus:outline-none flex-shrink-0 mx-2" onClick={() => {handleViewPassword(!viewPassword)}}><img src={viewPassword ? eyeball_hide : eyeball_show} className="h-6"/></button>
                                    </div>
                                    
                                </div>
                            </div>
                        }
                    </div>
                    <div className="w-45prs">
                        <div className="mb-5">
                            <ToggleSwitch value={withExpiry} onChange={()=>{handleWithExpiry(!withExpiry)}} label={formatMessage({id: 'SET_EXPIRATION_OF_CONTRACT'})} note={formatMessage({id: 'THE_CONTRACT_WILL_BE_DELETE'})}/>
                        </div>
                        { withExpiry && 
                            <div className="mx-5 my-2">
                                <div className={`font-medium flex flex-col lg:flex-row`}>
                                    <div className="flex-shrink-0 mr-3 my-auto flex flex-col">
                                        <div><FormattedMessage id="CONTRACT_EXPIRATION" /> {useBlock? formatMessage({id: 'BLOCK'}) : formatMessage({id: 'DATE'})}:</div>
                                        <div onClick={()=>{handleUseBlock(!useBlock)}} className="cursor-pointer text-13 underline text-blue-200">{useBlock? formatMessage({id: "SET_BY_EXPIRATION_DATE"}):formatMessage({id: "SET_BY_EXPIRATION_BLOCK"})}</div>
                                    </div>
                                    <input id="expiry" onChange={() => handleExpirationChange()} type={useBlock? 'number':'date'} min={useBlock ? currentBlock : moment().format('YYYY-MM-DD')}  className={`${useBlock ? 'w-full py-2' : 'pt-2 pb-1'}  mt-2 sm:mt-2 lg:mt-0 focus:outline-none cursor-pointer border border-gray-200 rounded-sm px-2`}/>
                                </div>
                                <div className="text-13 text-gray-60 font-normal pt-3">
                                    {useBlock ?  
                                        (estimatedExpirationDate ? <FormattedMessage id='ESTIMATED_EXPIRATION_DATE' values={{date: estimatedExpirationDate.toLocaleString()}} /> : <FormattedMessage id = 'THE_EXPIRATION_BLOCK_SHOULD_BE_GREATER_THAN' values={{block: currentBlock}}/>) 
                                        : formatMessage({id: 'ESTIMATED_EXPIRATION_BLOCK'}) + (estimatedExpirationBlock ? estimatedExpirationBlock : formatMessage({id: "NA"}))}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="flex flex-col xs:flex-row xs:justify-end">
                
                <div className="flex justify-center xs:justify-end ">
                    <button className="focus:outline-none text-white mr-5 w-28 py-2 font-medium bg-gray-300 hover:bg-gray-600 rounded-sm" onClick={()=>{handleProgress(2)}}>
                        <FormattedMessage id='BACK' />
                    </button>
                    <button onClick={()=>{handleSubmit()}} className="focus:outline-none text-white w-28 py-2 font-medium bg-orange-500 hover:bg-orange-600 rounded-sm">
                        <FormattedMessage id='SEND'/>
                    </button>
                </div>
            </div>

            
        </div>

    );
}
export default ReviewSend;