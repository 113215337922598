import React, { useEffect, useState } from 'react';

import plus_icon from '../../assets/plus_border_dashed.svg';
import { truncate } from '../../helpers/dashboard';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
const PrepareSignaturesField = (props) => {

    const {instance, signers, activeSigFieldTool, handleActiveSigFieldTool, ethAccount} = props;
    const [dropPoint, setDropPoint] = useState(null);
    // enables drag and drop
    // useEffect(() => {
	// 	let isSubscribed = true;
	// 	if (instance != null){
    //         const { iframeWindow } = instance;
    //         const iframeDoc = iframeWindow.document.body;
    //         iframeDoc.addEventListener('dragover', dragOver);
    //         iframeDoc.addEventListener('drop', e => {
    //             drop(e, instance);
    //         }); 
    //     }
    //     return () => isSubscribed = false
	// }, [instance]);

    const dragOver = e => {
        e.preventDefault();
        return false;
    };

    const drop = (e, instance) => {
        const { docViewer } = instance;
        const scrollElement = docViewer.getScrollViewElement();
        const scrollLeft = scrollElement.scrollLeft || 0;
        const scrollTop = scrollElement.scrollTop || 0;
        setDropPoint({ x: e.pageX + scrollLeft, y: e.pageY + scrollTop });
        e.preventDefault();
        return false;
    };

    const setSignatureTool = (idx) => {
        instance.setToolMode('AnnotationCreateSigFieldTxt'+idx);
        handleActiveSigFieldTool(idx);
    }
    

    

    const dragStart = e => {
        e.target.style.opacity = 0.5;
        const copy = e.target.cloneNode(true);
        copy.id = 'form-build-drag-image-copy';
        copy.style.width = '250px';
        document.body.appendChild(copy);
        e.dataTransfer.setDragImage(copy, 125, 25);
        e.dataTransfer.setData('text', '');
    };

    const dragEnd = (e, type) => {
        addField(type, dropPoint);
        e.target.style.opacity = 1;
        document.body.removeChild(
            document.getElementById('form-build-drag-image-copy'),
        );
        e.preventDefault();
    };

    if(!instance){
      return null
    }    
    
    return (
      <div className="w-64 bg-gray-40 text-gray-80 text-15 flex flex-col py-5 ">
        <ReactTooltip className={`rounded-lg text-xs text-white px-2 py-0 bg-gray-300`} delayShow={500} delayHide={100} offset={{'left': -10}} arrowColor="rgba(0,0,0,0)" place="left" type="dark" effect="solid" />
        <div className="px-3 pb-4 border-b">
            <FormattedMessage id="ADD_SIGNATURES" />
        </div>
        <div className="py-2 px-3">
          {signers.map((signer, idx)=>{
            return(
              <div key={`signer-${idx}`} data-tip={signer.alias ? signer.alias : signer.address} className={`border ${activeSigFieldTool==idx? 'bg-orange-450 bg-opacity-75 border-orange-440 border-opacity-75':'bg-white border-gray-85'} flex my-5 bg-white my-3 py-3 px-3 rounded-md cursor-pointer`} onClick={()=>{setSignatureTool(idx)}}>
                
                <img src={plus_icon}  className="mr-5"/>
                <div>
                  {ethAccount == signer.address? 
                    <FormattedMessage id="ME" />
                  :
                    <>
                      {signer.alias? signer.alias.length > 20 ? truncate(signer.alias, 14, 3) : signer.alias : truncate(signer.address, 14, 3)}
                    </>
                  }
                  
                </div>
              </div>
            )
          })}
        </div>


          {/* <div
              draggable
              onDragStart={e => dragStart(e)}
              onDragEnd={e => dragEnd(e, 'SIGNATURE')}
          >

              <button onClick={()=>{addField('SIGNATURE')}}>
                  Add a signature 
              </button>
          </div> */}
      </div>
    )
}
export default PrepareSignaturesField;